<template>
  <v-container
    fluid
    style="
      background: rgba(255, 255, 255, 0.9);
      min-width: 100%;
      min-height: 95vh;
      padding: 10px 20px;
      position: relative;
    "
  >
    <h6
      @click="back"
      style="
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        position: absolute;
        width: 70px;
        cursor: pointer;
      "
    >
      <v-icon style="position: relative; top: -1px">mdi-chevron-left</v-icon
      >back
    </h6>
    <div>
      <h4 class="oee-title">
        PLANNING DETAIL
        <v-tooltip bottom color="grey">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              text
              icon
              color="grey darken-2"
              @click="loadPage"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>refresh page</span>
        </v-tooltip>
      </h4>
    </div>
    <div v-if="detail !== null" style="margin-bottom: 65px">
      <br />
      <v-row no-gutters>
        <v-col cols="12" md="4" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Date
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0; margin-top: 6px">
              <v-text-field dense readonly :value="convertDate(detail.date)" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Start
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0; margin-top: 6px">
              <v-text-field dense readonly v-model="detail.start_time" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                End
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field dense readonly v-model="detail.end_time" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Shift
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0; margin-top: 6px">
              <v-text-field dense readonly v-model="detail.schedule.shift" />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="8" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Machine
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0; margin-top: 6px">
              <v-text-field
                v-if="detail.machine != null"
                dense
                readonly
                v-model="detail.machine.name"
              />
              <v-text-field v-else dense readonly />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Note
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0; margin-top: 6px">
              <v-textarea
                readonly
                outlined
                auto-grow
                rows="3"
                :value="detail.note"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
      <br />
      <v-card style="margin-top: 10px">
        <v-tabs
          v-model="tab"
          background-color="rgb(240,240,240)"
          slider-color="primary"
          color="primary"
        >
          <v-tab v-for="x in tabs" :key="x.code">
            {{ x.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="x in tabs" :key="x.code" style="padding: 20px">
            <div style="min-height: 60vh">
              <div v-if="x.code == 'DNPN'">
                <v-data-table
                  height="60vh"
                  mobile-breakpoint="0"
                  v-model="selectedDnPlan"
                  :items="dnPlanningList"
                  :headers="dnPlanHeaders"
                  :items-per-page="10"
                  :loading="loading"
                  :dense="wWidth > 808 ? true : false"
                  show-select
                  item-key="id"
                  single-select
                  style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                >
                  <template v-slot:top>
                    <div
                      class="d-flex"
                      style="
                        border: thin solid rgba(0, 0, 0, 0.12);
                        cursor: auto;
                        padding: 0 15px;
                      "
                    >
                      <v-btn
                        @click="addPlanDialog"
                        outlined
                        elevation="1"
                        color="indigo"
                        class="indigo--text font-weight-bold"
                        style="font-size: 12px; margin-top: 15px; margin-right: 10px;"
                      >
                        + Tambah
                      </v-btn>
                      <div style="width: 120px">
                        <v-select
                          :disabled="selectedDnPlan.length === 0"
                          label="Action"
                          style="
                            position: relative;
                            top: 15px;
                            margin: 0 15px 0 5px;
                            font-size: 12px;
                          "
                          v-model="actionValue"
                          :items="itemsAction"
                          item-text="name"
                          item-value="id"
                          outlined
                          return-id
                          dense
                          @change="action"
                        ></v-select>
                      </div>
                    </div>
                  </template>
                  <template v-slot:[`item.start_time`]="{ item }">
                    <div
                      style="
                        font-size: 12px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ convertTime(item.start_time) }}
                    </div>
                  </template>
                  <template v-slot:[`item.end_time`]="{ item }">
                    <div
                      style="
                        font-size: 12px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ convertTime(item.end_time) }}
                    </div>
                  </template>
                  <template v-slot:[`item.date`]="{ item }">
                    <div
                      style="
                        width: 70px;
                        font-size: 12px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ convertDate(item.date) }}
                    </div>
                  </template>
                  <template v-slot:[`item.downtime_category_name`]="{ item }">
                    <div
                      style="
                        width: 120px;
                        font-size: 12px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ item.downtime_category_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.downtime_reason_name`]="{ item }">
                    <div
                      style="
                        width: 250px;
                        font-size: 12px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ item.downtime_reason_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.description`]="{ item }">
                    <div
                      style="
                        width: 250px;
                        font-size: 12px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ item.description }}
                    </div>
                  </template>
                  <template v-slot:[`item.is_break`]="{ item }">
                    <div
                      style="
                        font-size: 12px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ item.is_break ? 'Yes' : 'No' }}
                    </div>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <v-dialog
      v-if="form != null"
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="editDialog"
    >
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>ADD/EDIT FORM</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :loading="loading" dark text @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form
            :disabled="loading"
            ref="entryForm1"
            @submit.prevent="savePlanDialog"
            style="position: relative"
          >
            <v-row style="padding-top: 15px">
              <v-col cols="6" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Category *
                </p>
                <v-autocomplete
                  v-model="form.downtime_category"
                  :items="dropdown.category"
                  :rules="rules.category"
                  style="height: 45px"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Reason *
                </p>
                <v-autocomplete
                  v-model="form.downtime_reason"
                  :items="dropdown.reason"
                  :rules="rules.reason"
                  style="height: 45px"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Date *
                </p>
                <v-text-field
                  v-model="form.date"
                  outlined
                  :rules="rules.date"
                  style="height: 45px"
                  type="date"
                  dense
                />
              </v-col>
              <v-col cols="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Start Time *
                </p>
                <v-text-field
                  v-model="form.start_time"
                  outlined
                  :rules="rules.startTime"
                  style="height: 45px"
                  type="time"
                  step="1"
                  dense
                />
              </v-col>
              <v-col cols="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  End Time *
                </p>
                <v-text-field
                  v-model="form.end_time"
                  outlined
                  :rules="rules.endTime"
                  style="height: 45px"
                  type="time"
                  step="1"
                  dense
                />
              </v-col>
              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Description
                </p>
                <v-textarea
                  v-model="form.description"
                  outlined
                  rows="3"
                  auto-grow
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  depressed
                  color="primary"
                  style="width: 100%"
                  type="submit"
                  :disabled="loading"
                  :loading="loading"
                >
                  Submit Data
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.1);
      "
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        class="d-flex justify-center align-center"
      >
        <p
          class="d-flex justify-center align-center"
          style="font-size: 12px; margin: auto"
        >
          Please wait
        </p>
      </v-progress-circular>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters } from 'vuex'
// import moment from 'moment'
export default {
  data() {
    return {
      wWidth: window.innerWidth,
      oee: buildType.apiURL('oee'),
      mms: buildType.apiURL('mms'),
      pagePath: this.$route.params.path,
      changeStatusDialog: false,
      detail: null,
      itemsAction: [
        { id: 0, name: '' },
        { id: 1, name: 'Delete' }
      ],
      status: null,
      actionValue: 0,
      loading: false,

      snackbar: false,
      alertText: '',
      timeout: 2000,
      instance: null,
      tab: null,
      tabs: [{ tab: 'Downtime Planning', code: 'DNPN', content: '' }],
      dnPlanningList: [],
      dnPlanHeaders: [
        {
          text: 'Date',
          value: 'date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Category',
          value: 'downtime_category_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Reason',
          value: 'downtime_reason_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Start',
          value: 'start_time',
          align: 'left',
          sortable: false
        },
        {
          text: 'End',
          value: 'end_time',
          align: 'left',
          sortable: false
        },
        {
          text: 'Duration',
          value: 'duration',
          align: 'left',
          sortable: false
        },
        {
          text: 'Description',
          value: 'description',
          align: 'left',
          sortable: false
        }
      ],
      selectedDnPlan: [],
      form: null,
      editDialog: false,
      rules: {
        category: [],
        reason: [],
        date: [],
        startTime: [],
        endTime: []
      },
      dropdown: {
        category: [],
        reason: []
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getPlanId', 'getUserProfile'])
  },
  async mounted() {
    this.loadPage()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await this.initDropdown()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, pInputType, pInputAtt) {
      const title =
        pInputType === 'file'
          ? 'Upload bukti approvement pada kolom dibawah'
          : ''
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No',
            input: pInputType !== undefined ? pInputType : null,
            inputLabel: pInputType !== undefined ? title : null,
            inputAttributes: pInputAtt !== undefined ? pInputAtt : null
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    showMsgDialog2(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: 'Submit',
            cancelButtonText: 'No',
            input: 'textarea',
            inputLabel: 'Tolong isi alasan pada kolom dibawah sebelum submit',
            inputPlaceholder: 'Tulis sesuatu disini...',
            inputAttributes: {
              'aria-label': 'Tulis sesuatu disini'
            }
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async initDropdown() {
      this.dropdownCategory()
      this.dropdownReason()
    },
    async loadPage() {
      this.loading = true
      if (this.$route.params.id !== undefined) {
        this.$store.commit('setPlanId', this.$route.params.id)
      }
      setTimeout(async () => {
        if (this.getPlanId !== null) {
          this.actionValue = 0
          this.selectedDnPlan = []
          await axios
            .get(
              `${this.mms}dtplan/info?id=${this.getPlanId}`
            )
            .then((res) => {
              this.loading = false
              console.log('DETAIL HEADERS', res)
              this.loadDowntimePlan()
              this.detail = res.data.data
            })
            .catch((err) => {
              this.loading = false
              console.log(err)
            })
        } else {
          this.loading = false
          this.$router.push('/mms/downtimeplan/detail/' + this.$route.params.path)
        }
      }, 300)
    },
    async loadDowntimePlan() {
      this.loading = true
      await axios
        .get(
          `${this.mms}dtplanitem/list?keyword=&offset=0&filter=[]&start_time=&end_time=&limit=all&dtplan_id=${this.getPlanId}`
        )
        .then((res) => {
          this.loading = false
          console.log('downtime/list', res)
          this.dnPlanningList = res.data.data
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    back() {
      this.detail = null
      this.$store.commit('setPlanId', null)
      if (this.prevRoute !== undefined) {
        //     if (this.prevRoute.path !== '/oee') {
        this.$router.push(this.prevRoute.path)
        //     } else {
        //       this.$router.push('/oee/detail/' + this.$route.params.path)
        //     }
      } else {
        this.$router.push('/mms/downtimeplan')
      }
    },
    action(event) {
      switch (event) {
        case 1:
          this.deleteItem()
          break
      }
    },
    addPlanDialog() {
      this.form = {
        act: 'add',
        date: this.detail.date,
        downtime_category: null,
        start_time: this.detail.start_time,
        end_time: this.detail.end_time,
        downtime_reason: null
      }

      setTimeout(() => {
        this.editDialog = true
      }, 300)
    },
    savePlanDialog() {
      console.log('before save', this.form)
      this.rules.category = [(v) => !!v || 'Category is required']
      this.rules.reason = [(v) => !!v || 'Reason is required']
      this.rules.date = [(v) => !!v || 'Date time is required']
      this.rules.startTime = [(v) => !!v || 'Start time is required']
      this.rules.endTime = [(v) => !!v || 'End time is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm1.validate()) {
          // self.createNewForm()
          const form = {
            act: self.form.act,
            downtime_plan_id: self.getPlanId,
            downtime_category_id: self.form.downtime_category.id,
            downtime_category_name: self.form.downtime_category.name,
            downtime_reason_id: self.form.downtime_reason.id,
            date: self.form.date,
            start_time: self.form.start_time,
            end_time: self.form.end_time,
            description: self.form.description
          }
          if (self.form.act === 'update') {
            Object.assign(form, { id: self.form.id })
          }
          console.log('save data', form)
          self.saveData(form)
        }
      })
    },
    async saveData(form) {
      this.loading = true
      await axios
        .post(`${this.mms}dtplanitem/save`, form)
        .then(async (res) => {
          console.log(res.data)
          this.loading = false
          if (res.data.status_code == '00') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.closeDialog()
            this.loadPage()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    closeDialog() {
      this.form = null
      setTimeout(() => {
        this.editDialog = false
      }, 300)
    },
    deleteItem() {
      const pItem = this.selectedDnPlan[0]
      console.log(this.getUserProfile.level.find(
        ({ id }) => id == '1' || id == '40'
      ))
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(
              `${this.mms}dtplanitem/delete/${pItem.id}`
            )
            .then(async (res) => {
              this.loading = false
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.loadPage()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              this.loading = false
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    async dropdownCategory() {
      const url = `${this.mms}master/universal/downtimecategory/dropdown`
      // if (this.paramAPI.company_id !== null) {
      //   url =
      //     url +
      //     `?filter=[{"company_id":${
      //       this.paramAPI.company_id !== null ? this.paramAPI.company_id : ''
      //     }}]`
      // }
      await axios
        .get(url)
        .then((res) => {
          console.log('downtimecategory dropdown', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.category = res.data.data)
          }
          return (this.dropdown.category = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.category = [])
        })
    },
    async dropdownReason() {
      const url = `${this.mms}master/universal/downtimereason/dropdown`
      // if (this.paramAPI.company_id !== null) {
      //   url =
      //     url +
      //     `?filter=[{"company_id":${
      //       this.paramAPI.company_id !== null ? this.paramAPI.company_id : ''
      //     }}]`
      // }
      await axios
        .get(url)
        .then((res) => {
          console.log('downtimereason dropdown', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.reason = res.data.data)
          }
          return (this.dropdown.reason = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.reason = [])
        })
    },
    convertTime(raw) {
      if (raw !== null) {
        var time = raw.slice(0, raw.indexOf('+'))
        return time
      } else {
        return ''
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        var month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        return day + '-' + month + '-' + year
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.oee-title {
  margin: auto;
  width: 50%;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.items-total-div-1 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  min-height: 150px;
  width: 100%;
  justify-content: space-between;
  .items-total-div-2 {
    // padding: 10px 0;
    width: '40%';
    .items-total-box {
      border: 1px solid #e0e0e0;
      padding: 20px 10px;
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      font-size: 14px;
      div {
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        padding-top: 20px;
        display: flex;
        p {
          margin: 0;
          margin-right: 10px;
          min-width: 80px;
          position: relative;
          span {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  .items-total-div-3 {
    padding: 0 10px;
    width: 60%;
    font-size: 14px;
  }
}
.actionButton {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.fpb-status-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
@media (max-width: 808px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    padding: 10px 0;
  }
  .items-total-div-1 {
    flex-direction: column-reverse;
    .items-total-div-2 {
      width: '100%';
      .items-total-box {
        width: 100%;
      }
    }

    .items-total-div-3 {
      margin-top: 10px;
      width: 100%;
    }
  }
}

@media (max-width: 840px) {
  .fpb-status-section {
    // flex-direction: column;
    flex-wrap: wrap;
  }
}
@media (max-width: 780px) {
  .oee-title {
    font-size: 3vw;
  }
}
</style>
