<template>
  <div class="fluid" id="oeeList">
    <div>
      <div class="oee-list-div">
        <v-card elevation="0" class="px-3 py-2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h4 class="indigo--text" style="font-weight: bold; margin: auto">
                DOWNTIME PLANNING
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDataFromApi"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  flat
                  style="height: 60px; border-radius: 10px 10px 0 0"
                >
                  <div style="margin-right: auto">
                    <v-btn
                      @click="addPlanDialog"
                      outlined
                      elevation="1"
                      color="indigo"
                      class="indigo--text font-weight-bold"
                      style="font-size: 12px"
                    >
                      Tambah Data
                    </v-btn>
                  </div>
                  <div style="width: 210px; margin-right: 20px">
                    <v-select
                      @change="companyWatcher"
                      v-model="paramAPI.company_id"
                      :items="dropdown.company"
                      style="position: relative; top: 15px; font-size: 12px"
                      label="Company"
                      item-text="name"
                      item-value="id"
                      outlined
                      return-id
                      dense
                      :disabled="isDisableCompanyDropdown"
                    ></v-select>
                  </div>
                  <div style="width: 120px">
                    <v-select
                      label="Shift"
                      v-model="paramAPI.schedule_id"
                      :items="dropdown.schedule"
                      style="position: relative; top: 15px; font-size: 12px"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="scheduleWatcher"
                    ></v-select>
                  </div>
                  <div style="width: 180px; margin-left: 20px">
                    <v-select
                      label="Mesin"
                      v-model="paramAPI.machine_id"
                      :items="dropdown.machine"
                      style="position: relative; top: 15px; font-size: 12px"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="machineWatcher"
                    ></v-select>
                  </div>
                  <div class="d-flex" style="margin-left: 20px">
                    <div>
                      <v-text-field
                        outlined
                        class="attendance-start-date ml-1 mr-2"
                        dense
                        label="Start Date"
                        type="date"
                        name="startdate"
                        style="height: 38px"
                        step="1"
                        v-model="start_date"
                        @change="startDateWatcher"
                        clearable
                      />
                    </div>
                    <div>
                      <v-text-field
                        style="height: 38px"
                        outlined
                        class="attendance-start-date ml-1 mr-2"
                        dense
                        label="End Date"
                        type="date"
                        name="enddate"
                        step="1"
                        v-model="end_date"
                        @change="endDateWatcher"
                        clearable
                      />
                    </div>
                  </div>
                </v-toolbar>
              </div>
              <v-toolbar class="activity-list-toolbar-2" flat>
                <v-text-field
                  class="attendance-start-date"
                  dense
                  outlined
                  label="Start Date"
                  type="date"
                  name="startdate"
                  style="height: 30px"
                  step="1"
                  v-model="start_date"
                  @change="startDateWatcher"
                  clearable
                />
              </v-toolbar>
              <v-toolbar
                class="activity-list-toolbar-2"
                flat
                style="
                  height: 60px;
                  border-radius: 10px 10px 0 0;
                  border-bottom: 1px solid #e0e0e0;
                "
              >
                <v-text-field
                  class="attendance-start-date"
                  dense
                  outlined
                  label="End Date"
                  type="date"
                  name="enddate"
                  style="height: 30px"
                  step="1"
                  v-model="end_date"
                  @change="endDateWatcher"
                  clearable
                />
              </v-toolbar>
            </v-col>

            <v-col cols="12">
              <v-data-table
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                @update:page="updatePage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.schedule`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.schedule.shift }}
                  </div>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.date) }}
                  </div>
                </template>
                <!-- <template v-slot:[`item.status`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    <v-chip
                      small
                      :color="coloredStatus(item.status)"
                      style="width: 60px"
                      class="d-flex justify-center align-center"
                      text-color="white"
                    >
                      {{ status(item.status) }}
                    </v-chip>
                  </div>
                </template> -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>

    <v-dialog
      v-if="form != null"
      transition="dialog-bottom-transition"
      max-width="800"
      v-model="editDialog"
    >
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>ADD NEW PLANNING</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="enableEdit">
              {{ !isEdit ? 'Edit' : 'Cancel' }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form
            :disabled="!isEdit"
            ref="entryForm1"
            @submit.prevent="savePlanDialog"
            style="position: relative"
          >
            <v-row style="padding-top: 15px">
              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Company *
                </p>
                <v-autocomplete
                  v-model="form.company_id"
                  :items="dropdown.company"
                  :outlined="isEdit"
                  :clearable="isEdit"
                  :rules="rules.company"
                  style="height: 45px"
                  item-text="name"
                  item-value="id"
                  return-id
                  disabled
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Machine *
                </p>
                <v-autocomplete
                  v-model="form.machine"
                  :items="dropdown.machine"
                  :rules="rules.machine"
                  style="height: 45px"
                  item-text="name"
                  item-value="id"
                  return-object
                  clearable
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Shift *
                </p>
                <v-autocomplete
                  v-model="form.schedule_id"
                  :items="dropdown.schedule"
                  :rules="rules.schedule"
                  style="height: 45px"
                  item-text="name"
                  item-value="id"
                  return-id
                  clearable
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Date *
                </p>
                <v-text-field
                  v-model="form.date"
                  :outlined="isEdit"
                  :rules="rules.date"
                  style="height: 45px"
                  type="date"
                  dense
                />
              </v-col>
              <v-col cols="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Start Time *
                </p>
                <v-text-field
                  v-model="form.start_time"
                  :outlined="isEdit"
                  :rules="rules.startTime"
                  style="height: 45px"
                  type="datetime-local"
                  step="1"
                  dense
                />
              </v-col>
              <v-col cols="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  End Time *
                </p>
                <v-text-field
                  v-model="form.end_time"
                  :outlined="isEdit"
                  :rules="rules.endTime"
                  style="height: 45px"
                  type="datetime-local"
                  step="1"
                  dense
                />
              </v-col>
              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Notes
                </p>
                <v-textarea
                  v-model="form.note"
                  :outlined="isEdit"
                  rows="3"
                  auto-grow
                  dense
                />
              </v-col>
              <v-col cols="12" v-if="isEdit">
                <v-btn
                  depressed
                  color="primary"
                  style="width: 100%"
                  type="submit"
                >
                  Submit Data
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters /* mapActions */ } from 'vuex'
import buildType from '../../../services/buildType'
import moment from 'moment'
export default {
  name: 'oeemonitoring',
  data: () => ({
    dialog: false,
    oee: buildType.apiURL('oee'),
    mms: buildType.apiURL('mms'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'date',
      sortType: 'desc',
      company_id: null,
      department_id: null,
      date: '',
      schedule_id: null,
      machine_id: null
    },
    totalData: 0,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'Date',
        value: 'date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Start',
        value: 'start_time',
        align: 'left',
        sortable: false
      },
      {
        text: 'End',
        value: 'end_time',
        align: 'left',
        sortable: false
      },
      {
        text: 'Shift',
        value: 'schedule',
        align: 'left',
        sortable: false
      },
      {
        text: 'Machine',
        value: 'machine_code',
        align: 'left',
        sortable: false
      }
      // {
      //   text: 'Status',
      //   value: 'status',
      //   align: 'left',
      //   sortable: false
      // }
    ],
    result: [],
    loading: false,
    dropdown: {
      machine: [],
      department: [],
      company: [],
      schedule: [
        { name: 'Shift 1', id: 1 },
        { name: 'Shift 2', id: 2 },
        { name: 'Shift 3', id: 3 }
      ]
    },
    start_date: '',
    end_date: '',
    instance: null,
    isDisableCompanyDropdown: false,
    form: null,
    editDialog: false,
    isEdit: false,
    rules: {
      machine: [],
      schedule: [],
      date: [],
      startTime: [],
      endTime: []
    }
  }),

  async mounted() {
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.paramAPI.department_id = this.getUserProfile.employee.department_id
    this.setOptionDate()
    await this.getDataFromApi()
    await this.initDropdown()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  watch: {},
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    setOptionDate() {
      const d = new Date()
      d.setDate(d.getDate())
      const n = d.toLocaleDateString('id').split('/').reverse()
      if (n[1].length === 1 || n[2].length === 1) {
        if (n[1].length === 1) {
          n[1] = `0${n[1]}`
        }
        if (n[2].length === 1) {
          n[2] = `0${n[2]}`
        }
      }
      const joinedDate = n.join('-')
      const sDate = joinedDate
      const sDateSplit = sDate.split('-')
      sDateSplit[2] = '01'
      // this.start_date = sDateSplit.join('-')
      // this.end_date = joinedDate
    },
    async companyWatcher(p) {
      this.dropdownMachine()
      await this.getDataFromApi()
    },
    machineWatcher() {
      this.getDataFromApi()
    },
    scheduleWatcher() {
      this.getDataFromApi()
    },
    startDateWatcher() {
      if (this.start_date === null) {
        this.start_date = ''
        this.end_date = ''
      }
      this.getDataFromApi()
    },
    endDateWatcher() {
      if (this.end_date === null) {
        this.start_date = ''
        this.end_date = ''
      }
      this.getDataFromApi()
    },
    async initDropdown() {
      this.dropdownMachine()
      this.getDropdownCompany()
    },
    searchEnter() {
      this.getDataFromApi()
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then((data) => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      let startDate = ''
      let endDate = ''
      if (this.start_date !== '') {
        startDate = this.start_date + ' 00:00:00'
      }
      if (this.end_date !== '') {
        endDate = this.end_date + ' 23:59:59'
      }
      return await new Promise((resolve) => {
        axios
          .get(
            `${this.mms}dtplan/list?keyword=${this.paramAPI.keyword}&offset=${
              this.paramAPI.offset * this.paramAPI.limit
            }&limit=${this.paramAPI.limit}&order_by=${
              this.paramAPI.sortBy
            }&order_type=${
              this.paramAPI.sortType
            }&start_date=${startDate}&end_date=${endDate}&machine_id=${
              this.paramAPI.machine_id !== null ? this.paramAPI.machine_id : ''
            }&schedule_id=${
              this.paramAPI.schedule_id !== null
                ? this.paramAPI.schedule_id
                : ''
            }`
          )
          .then((res) => {
            console.log('Planned Downtime', res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      this.$store.commit('setPlanId', pItem.id)
      setTimeout(() => {
        this.$router.push(`/mms/downtimeplan/detail/${pItem.id}`)
      }, 100)
    },
    addPlanDialog() {
      this.form = {
        act: 'add',
        machine: null,
        company_id: this.paramAPI.company_id,
        schedule_id: null,
        note: null,
        date: null,
        start_time: null,
        end_time: null
      }
      this.isEdit = true
      setTimeout(() => {
        this.editDialog = true
      }, 300)
    },
    closeDialog() {
      this.form = null
      this.isEdit = false
      setTimeout(() => {
        this.editDialog = false
      }, 300)
    },
    enableEdit() {
      var oldForm = this.form
      this.isEdit = !this.isEdit
      if (!this.isEdit) {
        this.form = oldForm
        if (this.form.act === 'add') {
          this.closeDialog()
        }
      }
    },
    savePlanDialog() {
      this.rules.machine = [(v) => !!v || 'Machine is required']
      this.rules.schedule = [(v) => !!v || 'Shift is required']
      this.rules.company = [(v) => !!v || 'Company is required']
      this.rules.date = [(v) => !!v || 'Date time is required']
      this.rules.startTime = [(v) => !!v || 'Start time is required']
      this.rules.endTime = [(v) => !!v || 'End time is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm1.validate()) {
          // self.createNewForm()
          const form = {
            act: self.form.act,
            machine_id: self.form.machine.id,
            machine_code: self.form.machine.code,
            date: self.form.date,
            start_time: moment(self.form.start_time).format('HH:mm:ss'),
            end_time: moment(self.form.end_time).format('HH:mm:ss'),
            schedule_id: self.form.schedule_id,
            company_id: self.form.company_id,
            note: self.form.note
          }
          if (self.form.act === 'update') {
            Object.assign(form, { id: self.form.id })
          }
          console.log('save data', form)

          self.saveData(form)
        }
      })
    },
    async saveData(form) {
      await axios
        .post(`${this.mms}dtplan/save`, form)
        .then(async (res) => {
          console.log(res.data)
          if (res.data.status_code == '00') {
            this.isEdit = false
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async dropdownMachine() {
      let url = `${this.mms}master/universal/machine/dropdown`
      if (this.paramAPI.company_id !== null) {
        url =
          url +
          `?filter=[{"company_id":${
            this.paramAPI.company_id !== null ? this.paramAPI.company_id : ''
          }}]`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('machine dropdown', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.machine = res.data.data)
          }
          return (this.dropdown.machine = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.machine = [])
        })
    },
    async getDropdownCompany() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }

      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // status(val) {
    //   switch (val) {
    //     case 0:
    //       return 'Down'
    //     case 1:
    //       return 'Run'
    //     case 2:
    //       return 'Break'
    //   }
    // },
    // coloredStatus(val) {
    //   switch (val) {
    //     case 0:
    //       return 'red'
    //     case 1:
    //       return 'green'
    //     case 2:
    //       return 'orange'
    //   }
    // },
    // convertDate(raw) {
    //   if (raw !== null) {
    //     const date = new Date(raw)
    //     const local = date.toLocaleString('id')
    //     return local
    //   } else {
    //     return '-'
    //   }
    // },

    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        var month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        // switch (month) {
        //   case '01':
        //     month = 'Jan'
        //     break
        //   case '02':
        //     month = 'Feb'
        //     break
        //   case '03':
        //     month = 'Mar'
        //     break
        //   case '04':
        //     month = 'Apr'
        //     break
        //   case '05':
        //     month = 'May'
        //     break
        //   case '06':
        //     month = 'Jun'
        //     break
        //   case '07':
        //     month = 'Jul'
        //     break
        //   case '08':
        //     month = 'Aug'
        //     break
        //   case '09':
        //     month = 'Sep'
        //     break
        //   case '10':
        //     month = 'Oct'
        //     break
        //   case '11':
        //     month = 'Nov'
        //     break
        //   case '12':
        //     month = 'Des'
        //     break
        // }
        return day + '-' + month + '-' + year
      }
    }
  }
}
</script>
<style lang="scss">
#oeeList {
  position: relative;
  .oee-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .oee-list-toolbar-1 {
      .oee-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #oeeList {
    .oee-list-div {
      .oee-list-toolbar-1 {
        .oee-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
