<template>
  <div v-if="permission.read_perm == 1">
    <v-form
      v-if="result !== null"
      ref="entryForm"
      @submit.prevent="submit"
      lazy-validation
      :disabled="!isEdit"
    >
      <v-row style="padding: 15px" no-gutters>
        <v-col
          v-if="permission.update_perm == 1"
          cols="12"
          style="padding: 0 10px; text-align: right"
        >
          <a
            v-if="!isEdit"
            href=""
            @click.prevent="editForm"
            style="font-size: 14px"
          >
            <v-icon small color="#1976d2">
              mdi-application-edit-outline
            </v-icon>
            Edit info
          </a>
          <p
            v-else
            @click.prevent="editForm"
            style="font-size: 14px; margin: 0; color: red; cursor: pointer"
          >
            Batal
            <v-icon small color="red"> mdi-close </v-icon>
          </p>
        </v-col>

        <v-col cols="6" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Perusahaan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              v-if="result.company !== null"
              :items="dropdown.company"
              item-text="name"
              item-value="id"
              return-object
              dense
              style="margin: 0"
              v-model="result.company"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="dropdown.company"
              item-text="name"
              item-value="id"
              return-object
              dense
              style="margin: 0"
              v-model="result.company"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="6" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Area Tugas
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              v-if="isEdit"
              :items="dropdown.company"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.working_on_company"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="dropdown.company"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.working_on_company"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              {{ !isEdit ? 'Department' : 'Dept/Section/Unit' }}
            </p>
          </v-col>
          <v-col v-if="!isEdit" cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              :value="result.department !== null ? result.department.name : ''"
            />
          </v-col>
          <v-col v-if="isEdit" cols="12" style="padding: 0">
            <v-autocomplete
              v-if="result.department !== null"
              :items="dropdown.department"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.department_id"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="dropdown.department"
              item-text="name"
              item-value="id"
              return-object
              dense
              style="margin: 0"
              v-model="result.department"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>

        <v-col v-if="!isEdit" cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Section
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              :value="result.section !== null ? result.section.name : ''"
            />
          </v-col>
        </v-col>
        <v-col v-if="!isEdit" cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Unit
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              :value="result.unit !== null ? result.unit.name : ''"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Jabatan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              v-if="result.level !== null"
              :items="dropdown.position"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.level.id"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="dropdown.position"
              item-text="name"
              item-value="id"
              return-object
              dense
              style="margin: 0"
              v-model="result.level"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>

        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Tgl. Masuk
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.join_date"
              :type="isEdit ? 'date' : ''"
              :name="isEdit ? 'date' : ''"
              :step="isEdit ? '1' : ''"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Golongan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              :items="dropdown.employee_class"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.gol"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="4" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              NIK
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.nik"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="4" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              No. Kartu
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.card_no"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Finger Print ID
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.fingerprint_id"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px; margin-top: 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Hari Libur
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              v-if="result.weekend_day !== null"
              :items="daysDropdown"
              v-model="result.weekend_day"
              item-text="name"
              item-value="id"
              return-id
              outlined
              clearable
              small-chips
              deletable-chips
              multiple
              cache-items
              dense
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="daysDropdown"
              v-model="result.weekend_day"
              item-text="name"
              item-value="id"
              return-id
              outlined
              clearable
              small-chips
              deletable-chips
              multiple
              cache-items
              dense
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px; margin-top: 10px">
          <div flat class="d-flex justify-start align-center">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Shift
            </p>
            <v-switch
              v-model="result.is_shift"
              inset
              color="success"
              style="margin-left: 15px"
            ></v-switch>
          </div>
        </v-col>
        <v-col v-if="!isEdit" cols="12" md="4"></v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Kategori Jadwal
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              v-if="result.shift_category !== null"
              :items="dropdown.shift_category"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.shift_category.id"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="dropdown.shift_category"
              item-text="name"
              item-value="id"
              return-object
              dense
              style="margin: 0"
              v-model="result.shift_category"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Status Keaktifan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              :items="dropdown.activation_status"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.status"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Status Karyawan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              v-if="result.employee_status !== null"
              :items="dropdown.employee_status"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.employee_status.id"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="dropdown.employee_status"
              item-text="name"
              item-value="id"
              return-object
              dense
              style="margin: 0"
              v-model="result.employee_status"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col
          v-if="result.employee_status !== null"
          cols="12"
          md="4"
          style="padding: 0 10px"
        >
          <v-col
            v-if="result.employee_status.id == 5"
            cols="12"
            style="padding: 0"
          >
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Tgl. Resign
            </p>
          </v-col>
          <v-col
            v-if="result.employee_status.id == 5"
            cols="12"
            style="padding: 0"
          >
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              clearable
              v-model="result.resign_date"
              :type="isEdit ? 'date' : ''"
              :name="isEdit ? 'date' : ''"
              :step="isEdit ? '1' : ''"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          style="padding: 0 10px; margin-bottom: 10px; margin-top: 20px"
          class="d-flex justify-space-between"
        >
          <v-btn
            v-if="isEdit"
            @click.prevent="changeTab"
            type="button"
            rounded
            elevation="0"
            color="error"
            class="white--text font-weight-bold"
            style="font-size: 12px; width: 120px"
            ><v-icon>mdi-chevron-left</v-icon>
            Kembali
          </v-btn>
          <!-- <v-btn
            v-if="isEdit"
            type="nextTab"
            rounded
            elevation="0"
            color="primary"
            class="white--text font-weight-bold"
            style="font-size: 12px; width: 130px"
            :loading="loading"
          >
            Berikutnya <v-icon>mdi-chevron-right</v-icon>
          </v-btn> -->
          <v-btn
            v-if="isEdit"
            type="submit"
            rounded
            elevation="0"
            color="success"
            class="white--text font-weight-bold"
            style="font-size: 12px; width: 160px"
            :loading="loading"
          >
            Simpan Perubahan
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters style="margin-bottom: 30px">
        <v-col v-if="!isEdit" cols="12" style="padding: 0; margin-bottom: 0">
          <v-divider></v-divider>
          <v-toolbar-title
            class="overline"
            style="padding-left: 10px; text-align: center"
          >
            <p
              style="
                font-size: 16px;
                margin: 0;
                font-weight: bold;
                color: indigo;
                position: relative;
              "
            >
              Riwayat Kontrak
            </p>
          </v-toolbar-title>
        </v-col>
        <v-col
          v-if="!isEdit"
          cols="12"
          style="padding: 0 10px; margin: 15px 0 10px 0"
        >
          <div v-if="result.employee_status !== null">
            <v-row no-gutters v-if="result.employee_status.id === 1">
              <v-col v-if="!isEdit" cols="12" md="4" style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: blue;
                    "
                  >
                    Tgl Kontrak
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    v-model="result.contract_date"
                    :type="isEdit ? 'date' : ''"
                    :name="isEdit ? 'date' : ''"
                    :step="isEdit ? '1' : ''"
                    disabled
                  />
                </v-col>
              </v-col>
              <v-col v-if="!isEdit" cols="12" md="4" style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Masa Kontrak
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    v-model="result.contract_month"
                    disabled
                  />
                </v-col>
              </v-col>
              <v-col v-if="!isEdit" cols="12" md="4" style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: red;
                    "
                  >
                    Selesai Kontrak
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    v-model="result.expire_contract_date"
                    :type="isEdit ? 'date' : ''"
                    :name="isEdit ? 'date' : ''"
                    :step="isEdit ? '1' : ''"
                    disabled
                  />
                </v-col>
              </v-col>
              <v-col
                v-if="!isEdit"
                cols="12"
                md="5"
                style="padding: 0 10px; margin-bottom: 10px"
              >
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Reminder
                  </p>
                </v-col>
                <v-col cols="6" style="padding: 0; display: flex">
                  <v-text-field dense disabled :value="reminder()" />
                  <p style="margin: 0; color: rgba(0, 0, 0, 0.3)">Hari</p>
                </v-col>
                <v-col cols="12" style="padding: 0; display: flex">
                  <p
                    style="
                      margin: 0;
                      color: rgba(0, 0, 0, 0.4);
                      font-size: 14px;
                      font-weight: bold;
                    "
                  >
                    Ket: Segera buat kontrak baru H-7
                  </p>
                </v-col>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col v-if="!isEdit" cols="12" style="padding: 0 10px; margin: auto">
          <v-btn
            v-if="permission.create_perm == 1 && !userLevel.is_support"
            type="button"
            rounded
            elevation="0"
            color="primary"
            class="white--text font-weight-bold"
            style="font-size: 12px"
            @click.prevent="addContract"
          >
            Buat kontrak baru
          </v-btn>
        </v-col>
        <v-col v-if="!isEdit" cols="12" style="padding: 0 10px">
          <v-data-table
            mobile-breakpoint="0"
            fixed-header
            min-height="20vh"
            :headers="headers"
            style="cursor: pointer"
            :items="response"
            :loading="loading"
            @click:row="rowClick"
            item-key="id"
            :options.sync="options"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            :server-items-length="totalData"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
          >
            <template v-slot:[`item.contract_date`]="{ item }">
              <div
                style="
                  font-size: 12px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ convertLocalDate(item.contract_date) }}
              </div>
            </template>
            <template v-slot:[`item.expire_contract_date`]="{ item }">
              <div
                style="
                  font-size: 12px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ convertLocalDate(item.expire_contract_date) }}
              </div>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              <div
                style="
                  font-size: 12px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                {{ convertLocalDate(item.updated_at) }}
              </div>
            </template>
            <template v-slot:[`item.file`]="{ item }">
              <div
                style="
                  font-size: 12px;
                  padding: 0;
                  display: flex;
                  flex-direction: row;
                "
              >
                <v-btn
                  v-if="
                    getUserProfile.level.find(
                      ({ id }) => id == appLevel.adminMaster
                    ) !== undefined ||
                    getUserProfile.employee.email === result.email
                  "
                  @click.stop="viewPDF(item)"
                  text
                  icon
                  color="primary"
                  small
                >
                  <v-icon small>mdi-magnify</v-icon>
                </v-btn>
                <v-btn
                  v-if="permission.delete_perm == 1"
                  @click.stop="deleteData(item)"
                  text
                  icon
                  color="red"
                  small
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="width: 80vw; height: 50vh; margin: auto"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="100"
      ></v-progress-circular>
    </div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-form
        ref="contractForm"
        @submit.prevent="submitContract"
        lazy-validation
        :disabled="loading"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Formulir Update Kontrak
          </v-card-title>
          <div style="padding: 10px">
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Nomor Kontrak
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    outlined
                    v-model="form.contract_no"
                    :rules="rules.contract_no"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Tanggal Kontrak
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    type="date"
                    name="date"
                    step="1"
                    outlined
                    v-model="form.contract_date"
                    :rules="rules.contract_date"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Masa Kontrak
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    outlined
                    type="number"
                    v-model="form.contract_month"
                    :rules="rules.contract_month"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Selesai Kontrak
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    type="date"
                    name="date"
                    step="1"
                    outlined
                    v-model="form.expire_contract_date"
                    :rules="rules.expire_contract_date"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Upload Kontrak File
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <!-- :append-outer-icon="upload_result !== '' ? 'mdi-close' : ''"
                    @click:append-outer="clearFile()" -->
                  <v-file-input
                    ref="contractFile"
                    :placeholder="
                      upload_result !== '' ? upload_result : 'klik untuk upload'
                    "
                    append-icon="mdi-attachment"
                    prepend-icon=""
                    outlined
                    dense
                    @change="uploadFile"
                    style="height: 40px"
                  ></v-file-input>
                  <p style="margin: 0; width: 100%; font-size: 12px">
                    {{ upload_result }}
                  </p>
                </v-col>
              </div>
            </v-col>
          </div>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              @click.prevent="closeContractDialog"
              type="button"
              rounded
              elevation="0"
              color="error"
              class="white--text font-weight-bold"
              style="font-size: 12px; width: 100px"
              :loading="loading"
            >
              Batal
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              rounded
              elevation="0"
              color="success"
              class="white--text font-weight-bold"
              style="font-size: 12px; width: 100px"
              :loading="loading"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="contractDialog" width="90%" @click:outside="closePdf">
        <v-card style="position: relative; width: 100%; height: 90vh">
          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                @click.prevent="closePdf"
                v-bind="attrs"
                v-on="on"
                text
                icon
                style="
                  position: fixed;
                  top: 10px;
                  right: 10px;
                  z-index: 3;
                  background: white;
                "
              >
                <v-icon color="black" x-large>mdi-close-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
          <div
            id="loader"
            class="d-flex justify-center align-center"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          >
            <v-progress-circular
              v-if="isPdfOpen && fileLink !== ''"
              :rotate="-90"
              :size="150"
              :width="15"
              :value="value"
              color="black"
            >
              {{ value / 10 }}%
            </v-progress-circular>
          </div>
          <div v-if="showPdf" style="height: 100%; padding: 5px 0">
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  @click.prevent="closePdf"
                  v-bind="attrs"
                  v-on="on"
                  text
                  icon
                  color="white"
                  style="
                    position: fixed;
                    top: 10px;
                    right: 10px;
                    z-index: 3;
                    background: white;
                  "
                >
                  <v-icon color="black" x-large
                    >mdi-close-circle-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
            <vue-pdf-app
              v-if="fileLink !== ''"
              @pages-rendered="pagesRendered"
              @open="openHandler"
              :config="config"
              :pdf="fileLink"
            >
              <template #toolbar-sidebar-prepend="{ toggleTheme }">
                <button @click="toggleTheme = null" type="button">
                  Toggle theme
                </button>
              </template>
            </vue-pdf-app>

            <div
              v-else
              class="d-flex justify-center align-center"
              style="
                height: 100%;
                width: 100%;
                margin-left: auto;
                position: relative;
                z-index: 1;
                background: #eaeaea;
              "
            >
              <h3 style="margin: 0">File Not Found</h3>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
import { mapGetters } from 'vuex'
import 'vue-pdf-app/dist/icons/main.css'
export default {
  props: [
    'result',
    'dropdown',
    'isEdit',
    'permission',
    'appLevel',
    'userLevel'
  ],
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      upload: buildType.apiURL('upload2'),
      build: process.env.VUE_APP_BUILD_TYPE,
      // isEdit: false,
      loading: false,
      dialog: false,
      contractDialog: false,
      isPdfOpen: false,
      showPdf: false,
      fileLink: '',
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: '',
        sortBy: 'contract_date',
        sortType: 'asc',
        company_id: 0,
        department_id: '',
        itemsPerPage: 10,
        date: ''
      },
      totalData: 0,

      options: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: []
      },
      headers: [
        {
          text: 'Nomor Kontrak',
          value: 'contract_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tanggal Kontrak',
          value: 'contract_date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Masa Kontrak',
          value: 'contract_month',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kontrak Selesai',
          value: 'expire_contract_date',
          align: 'left',
          sortable: false
        },
        // {
        //   text: 'Diupdate Tgl',
        //   value: 'updated_at',
        //   align: 'left',
        //   sortable: false
        // },
        // {
        //   text: 'Diupdate Oleh',
        //   value: 'updated_by',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Action',
          value: 'file',
          align: 'left',
          sortable: false
        }
      ],
      response: [],
      form: {
        id: '',
        contract_no: '',
        contract_date: '',
        expire_contract_date: '',
        file: ''
      },
      upload_result: '',
      rules: {
        expire_contract_date: [],
        contract_no: [],
        contract_date: [],
        contract_month: [],
        contract_file: []
      },
      value: 0,
      counter: 0,
      timer: 100,
      info: [],
      config: {
        secondaryToolbar: false,
        toolbar: {
          toolbarViewerRight: false
        }
      },
      daysDropdown: [
        { id: 0, name: 'Minggu' },
        { id: 1, name: 'Senin' },
        { id: 2, name: 'Selasa' },
        { id: 3, name: 'Rabu' },
        { id: 4, name: 'Kamis' },
        { id: 5, name: 'Jumat' },
        { id: 6, name: 'Sabtu' }
      ]
    }
  },
  watch: {
    isEdit() {
      if (this.isEdit) {
        this.result.join_date = this.convertDate(this.result.join_date)
        this.result.contract_date = this.convertDate(this.result.contract_date)
        this.result.expire_contract_date = this.convertDate(
          this.result.expire_contract_date
        )
      }
      if (!this.isEdit) {
        this.$emit('cancel')
      }
    }
    // 'result.department'() {
    //   this.result.department_id = this.result.department.id
    // },
    // 'result.company'() {
    //   this.$emit('findDepartmentDropdown')
    // }
  },
  async mounted() {
    // this.paramAPI.company_id = this.getUserProfile.employee.company_id
    await this.getDataFromApi()
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    editForm() {
      this.$emit('editForm')
    },
    changeTab() {
      this.$emit('changeTab', 0)
    },
    submit() {
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      let xPhoto = ''
      let department = null
      if (
        process.env.VUE_APP_BUILD_TYPE === 'development' ||
        process.env.VUE_APP_BUILD_TYPE === 'staging'
      ) {
        if (
          this.result.photo.includes(
            'https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/'
          )
        ) {
          xPhoto = this.result.photo.replace(
            'https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/',
            ''
          )
        } else {
          xPhoto = this.result.photo.replace(
            'http://10.10.20.8:8020/hris/photo/employee/',
            ''
          )
        }
      } else {
        xPhoto = this.result.photo.replace(
          'https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/',
          ''
        )
      }
      if (this.result.department !== null) {
        department = this.result.department_id
      } else {
        department = this.result.department.id
      }
      const newForm = {
        act: 'update',
        id: this.$route.params.id,
        nik: this.result.nik,
        name: this.result.name,
        company_id: this.result.company !== null ? this.result.company.id : 0,
        company_name:
          this.result.company !== null ? this.result.company.name : '',
        department_id: department,
        level_id: this.result.level !== null ? this.result.level.id : 0,
        card_no: this.result.card_no,
        email: this.result.email,
        gender: this.result.gender !== null ? this.result.gender.id : 0,
        religion: this.result.religion !== null ? this.result.religion.id : 0,
        birth_place: this.result.birth_place,
        birth_date: this.result.birth_date,
        no_identitas: this.result.no_identitas,
        address: this.result.address,
        mobile_phone_no: this.result.mobile_phone_no,
        education_level: this.result.education_level,
        education_major: this.result.education_major,
        mariage_status:
          this.result.mariage_status !== null
            ? this.result.mariage_status.id
            : 0,
        ptkp_status_id:
          this.result.ptkp_status !== null ? this.result.ptkp_status.id : 0,
        npwp_no: this.result.npwp_no,
        gol: this.result.gol,
        employee_status_id:
          this.result.employee_status !== null
            ? this.result.employee_status.id
            : 0,
        status: this.result.status,
        join_date: this.convertDate(this.result.join_date),
        contract_date: this.convertDate(this.result.contract_date),
        contract_month: this.result.contract_month,
        expire_contract_date: this.convertDate(
          this.result.expire_contract_date
        ),
        bpjs_no: this.result.bpjs_no,
        bpjs_tk_no: this.result.bpjs_tk_no,
        blood_type: this.result.blood_type,
        domicile_address: this.result.domicile_address,
        weekend_day: this.result.weekend_day,
        fingerprint_id: this.result.fingerprint_id,
        is_shift: this.result.is_shift,
        photo: xPhoto,
        resign_date: this.result.resign_date,
        clothing_size: this.result.clothing_size,
        account_number: this.result.account_number,
        working_on_company: this.result.working_on_company,
        shift_category_id:
          this.result.shift_category !== null
            ? this.result.shift_category.id
            : null,

        height: this.result.height,
        weight: this.result.weight,
        nationality: this.result.nationality,
        house_status:
          this.result.house_status != null
            ? Number(this.result.house_status)
            : null,
        family_structure:
          this.result.family_structure.length > 0
            ? this.result.family_structure
            : null,
        formal_education:
          this.result.formal_education.length > 0
            ? this.result.formal_education
            : null,
        file: this.result.file,
        kk_no: this.result.kk_no,
        language_skill:
          this.result.language_skill.length > 0
            ? this.result.language_skill
            : null,
        sim: this.result.sim.length > 0 ? this.result.sim : null,
        social_media: this.result.social_media,
        vehicle: this.result.vehicle.length > 0 ? this.result.vehicle : null
      }

      this.save(newForm)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            setTimeout(() => {
              this.$emit('cancel')
              return this.$emit('editForm')
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then((data) => {
        this.response = data.data
        this.totalData = data.total_record
        this.loading = false
      })
    },
    async initDataTable() {
      return await new Promise((resolve) => {
        axios
          .get(
            `${this.hrsApi}employee_contract/list?employee_id=${
              this.$route.params.id
            }&keyword=${this.paramAPI.keyword}&order_by=${
              this.paramAPI.sortBy
            }&order_type=${this.paramAPI.sortType}&offset=${
              this.paramAPI.offset * this.paramAPI.limit
            }&limit=${this.paramAPI.limit}`
          )
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      if (this.permission.update_perm == 1) {
        this.form.act = 'update'
        this.form.id = pItem.id
        this.form.contract_no = pItem.contract_no
        this.form.contract_date = pItem.contract_date
        this.form.contract_month = pItem.contract_month
        this.form.expire_contract_date = pItem.expire_contract_date
        if (pItem.file !== null) {
          this.form.file = pItem.file.slice(pItem.file.lastIndexOf('/') + 1)
          this.upload_result = pItem.file.slice(pItem.file.lastIndexOf('/') + 1)
        }
        setTimeout(() => {
          this.dialog = true
        }, 300)
      }
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    convertDate(val) {
      if (val !== null || val !== '') {
        const rawDate = new Date(val).toLocaleDateString('id').split('/')
        const y = rawDate[2]
        const m = rawDate[1].length === 2 ? rawDate[1] : '0' + rawDate[1]
        const d = rawDate[0].length === 2 ? rawDate[0] : '0' + rawDate[0]
        const newDate = `${y}-${m}-${d}`
        return newDate
      }
      return ''
    },
    convertLocalDate(raw) {
      if (raw !== null) {
        // const date = new Date(raw)
        // const local = date.toLocaleDateString('id')
        // return local
        return raw.split('-').reverse().join('-')
      }
    },
    addContract() {
      this.form.act = 'add'
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    reminder() {
      const dateNow = new Date().getTime()
      const contractExpired = new Date(
        this.result.expire_contract_date
      ).getTime()
      const difference = contractExpired - dateNow
      const TotalDays = Math.floor(difference / (1000 * 3600 * 24))
      return TotalDays
    },
    submitContract() {
      this.rules.contract_no = [(v) => !!v || 'Contract number is required']
      this.rules.contract_date = [(v) => !!v || 'Contract date is required']
      this.rules.contract_month = [(v) => !!v || 'Contract month is required']
      this.rules.expire_contract_date = [
        (v) => !!v || 'Contract expired is required'
      ]
      this.rules.contract_file = [
        (v) => !!v || 'Please upload contract document'
      ]
      const self = this
      setTimeout(function () {
        if (self.$refs.contractForm.validate()) {
          self.createNewFormContract()
        }
      })
    },

    createNewFormContract() {
      const newForm = {
        act: this.form.act,
        employee_id: this.$route.params.id,
        contract_no: this.form.contract_no,
        contract_date: this.form.contract_date,
        contract_month: this.form.contract_month,
        expire_contract_date: this.form.expire_contract_date,
        file: this.form.file
      }
      if (newForm.act === 'update') {
        Object.assign(newForm, { id: this.form.id })
      }
      this.saveContract(newForm)
    },

    async saveContract(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee_contract/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.$emit('cancel')
            this.closeContractDialog()
            this.getDataFromApi()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    uploadFile(event) {
      if (event !== null) {
        this.loading = true
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'contract')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then((res) => {
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.form.file = res.data.data.name
              this.upload_result = res.data.data.name
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      } else {
        this.form.file = ''
        this.upload_result = ''
      }
    },
    // clearFile() {
    //   this.form.file = ''
    //   this.upload_result = ''
    // },
    // downloadFile(pValue) {
    //   this.fileLink = pValue.file
    // axios({
    //   url: `${this.download}esanqua/archive_document/edocument/${pValue.edoc_token}`,
    //   method: 'GET',
    //   responseType: 'blob'
    // })
    //   .then(res => {
    //     const url = window.URL.createObjectURL(new Blob([res.data]))
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.setAttribute('download', `${pValue.doc.doc_no}.pdf`)
    //     document.body.appendChild(link)
    //     link.click()
    //   })
    //   .catch(error => {
    //   })
    // },
    deleteData(pValue) {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          await axios
            .delete(`${this.hrsApi}employee_contract/delete/${pValue.id}`)
            .then(async (res) => {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.getDataFromApi()
            })
            .catch((err) => {
              console.log(err)
            })
          this.loading = false
        }
      })
    },
    closeContractDialog() {
      this.form = {
        id: '',
        contract_no: '',
        contract_date: '',
        expire_contract_date: '',
        file: ''
      }
      this.upload_result = ''

      this.rules.contract_no = []
      this.rules.contract_date = []
      this.rules.contract_month = []
      this.rules.expire_contract_date = []
      this.rules.contract_file = []
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    closePdf() {
      this.contractDialog = false
      this.isPdfOpen = false
      this.showPdf = false
    },

    viewPDF(pItem) {
      if (!this.isPdfOpen) {
        this.fileLink = pItem.file
        this.contractDialog = true
        this.isPdfOpen = true
        // this.selectedPdf = pItem.file
        this.countDownTimer()
      }
    },
    countDownTimer() {
      if (this.counter < this.timer) {
        setTimeout(() => {
          this.counter += 1
          this.value += 10
          this.countDownTimer()
        }, 1)
      } else {
        this.showPdf = true
        this.value = 0
        this.counter = 0
      }
    },
    async openHandler(pdfApp) {
      this.info = []
      const info = await pdfApp.pdfDocument
        .getMetadata()
        .catch(console.error.bind(console))

      if (!info) return
      const props = Object.keys(info.info)
      props.forEach((prop) => {
        const obj = {
          name: prop,
          value: info.info[prop]
        }
        this.info.push(obj)
      })
    },
    pagesRendered(pdfApp) {
      setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = 'page-fit'))
    }
  }
}
</script>
