<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <div class="fluid" id="overtimeLIst">
          <div>
            <div class="overtime-list-dev">
              <v-card elevation="0" class="px-3 py-2" style="width: 100%">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex"
                    style="
                      position: relative;
                      padding: 0 20px;
                      text-align: center;
                      margin-top: 20px;
                    "
                  >
                    <h4
                      class="indigo--text"
                      style="font-weight: bold; margin: auto"
                    >
                      Pengajuan Lembur
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            x-small
                            text
                            icon
                            color="grey darken-2"
                            @click="getDataFromApi"
                          >
                            <v-icon small>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>refresh page</span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <v-toolbar
                        class="overtime-list-toolbar-1"
                        flat
                        style="
                          height: 60px;
                          border-radius: 10px 10px 0 0;
                          margin-top: 15px;
                        "
                      >
                        <div
                          class="d-flex align-content-start justify-between"
                          style="width: 100%"
                        >
                          <div
                            style="margin-right: 15px; height: 45px"
                            class="d-flex justify-center align-center"
                          >
                            <v-btn
                              rounded
                              small
                              elevation="1"
                              color="indigo"
                              class="white--text"
                              style="font-size: 12px"
                              @click.stop="importData"
                            >
                              Import
                              <v-icon size="15" style="margin-left: 5px">
                                mdi-file-import-outline
                              </v-icon>
                            </v-btn>
                            <v-btn
                              @click="addOvertime"
                              outlined
                              elevation="1"
                              color="indigo"
                              class="indigo--text font-weight-bold"
                              style="font-size: 12px; margin-left: 15px"
                            >
                              Ajukan Lembur
                            </v-btn>
                          </div>
                          <div
                            style="
                              width: 300px;
                              height: 45px;
                              margin-left: auto;
                            "
                          >
                            <v-text-field
                              v-model="paramAPI.keyword"
                              label="cari name"
                              type="search"
                              outlined
                              dense
                              append-icon="mdi-magnify"
                              style="margin-left: 10px; font-size: 12px"
                              @keyup.enter="searchEnter"
                              :disabled="loading"
                            ></v-text-field>
                          </div>
                        </div>
                      </v-toolbar>
                      <v-toolbar
                        class="overtime-list-toolbar-1"
                        flat
                        style="
                          height: 60px;
                          border-radius: 10px 10px 0 0;
                          margin-top: 10px;
                        "
                      >
                        <div
                          class="d-flex align-content-start"
                          style="width: 100%"
                        >
                          <div style="width: 160px; margin-right: 10px">
                            <v-select
                              label="Jenis Dokumen"
                              v-model="paramAPI.documentType"
                              :items="[
                                {
                                  id: 1,
                                  name: 'List Header'
                                },
                                {
                                  id: 2,
                                  name: 'List Detail'
                                }
                              ]"
                              style="font-size: 12px"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              outlined
                              @change="documentWatcher"
                            ></v-select>
                          </div>
                          <div style="width: 160px">
                            <v-select
                              :item-disabled="modeDisable"
                              label="Jenis Pengajuan"
                              v-model="paramAPI.mode"
                              :items="dropdown.user_type"
                              style="font-size: 12px"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              outlined
                              @change="modeWatcher"
                            ></v-select>
                          </div>
                          <div style="width: 210px; margin-left: auto">
                            <v-autocomplete
                              v-model="paramAPI.company_id"
                              label="Company"
                              item-text="name"
                              item-value="id"
                              style="font-size: 12px"
                              return-object
                              dense
                              outlined
                              clearable
                              @change="companyWatcher"
                              :items="dropdown.company"
                              :disabled="isDisableCompanyDropdown"
                            ></v-autocomplete>
                          </div>
                          <div style="width: 210px; margin-left: 15px">
                            <v-autocomplete
                              v-model="paramAPI.department_id"
                              label="Department"
                              item-text="name"
                              item-value="id"
                              style="font-size: 12px"
                              return-object
                              dense
                              outlined
                              clearable
                              @change="departmentWatcher"
                              :items="dropdown.department"
                            ></v-autocomplete>
                          </div>

                          <div style="width: 150px; margin-left: 15px">
                            <v-select
                              label="Status"
                              v-model="paramAPI.status_id"
                              :items="dropdown.status"
                              style="font-size: 12px"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              clearable
                              outlined
                              @change="statusWatcher"
                            ></v-select>
                          </div>
                        </div>
                      </v-toolbar>
                    </div>
                  </v-col>

                  <v-col
                    v-if="paramAPI.documentType == 1"
                    cols="12"
                    style="width: 100%"
                  >
                    <v-data-table
                      v-if="result.length > 0 || !skeleton_loading"
                      mobile-breakpoint="0"
                      fixed-header
                      height="50vh"
                      :headers="headers"
                      style="cursor: pointer"
                      :items="result"
                      :loading="loading"
                      @click:row="rowClickHeader"
                      item-key="id"
                      :options.sync="options"
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                      }"
                      :server-items-length="totalData"
                      :page="paramAPI.page"
                      @update:page="updatePage"
                      :items-per-page="paramAPI.itemsPerPage"
                      @update:items-per-page="updateItemPerPage"
                    >
                      <template v-slot:[`item.no`]="{ index }">
                        <div
                          style="
                            font-size: 14px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                        </div>
                      </template>
                      <template v-slot:[`item.document_no`]="{ item }">
                        <div
                          :style="`min-width:150px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.document_no }}
                        </div>
                      </template>
                      <template v-slot:[`item.company_name`]="{ item }">
                        <div
                          :style="`width:150px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.company_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.department.name`]="{ item }">
                        <div
                          :style="`width:150px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.department.name }}
                        </div>
                      </template>
                      <template v-slot:[`item.created_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.created_at) }}
                        </div>
                      </template>
                      <template v-slot:[`item.updated_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.updated_at) }}
                        </div>
                      </template>
                      <template v-slot:[`item.status`]="{ item }">
                        <div
                          :style="`text-transform:uppercase; font-size:12px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; color:${statusColor(
                            item.status
                          )};`"
                        >
                          {{ statusName(item.status) }}
                        </div>
                      </template>
                      <template v-slot:[`item.description`]="{ item }">
                        <div
                          :style="`min-width:150px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.description }}
                        </div>
                      </template>
                      <template v-slot:[`item.created_by_name`]="{ item }">
                        <div
                          style="
                            width: 100px;
                            font-size: 11px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.created_by_name }}
                        </div>
                      </template>
                    </v-data-table>
                    <v-skeleton-loader
                      v-else
                      style="width: 100%"
                      type="table-heading, table-tbody, table-tfoot"
                    ></v-skeleton-loader>
                  </v-col>
                  <v-col v-else cols="12" style="width: 100%">
                    <v-data-table
                      v-if="result.length > 0 || !skeleton_loading"
                      mobile-breakpoint="0"
                      fixed-header
                      height="50vh"
                      :headers="detailHeaders"
                      style="cursor: pointer"
                      :items="result"
                      :loading="loading"
                      @click:row="rowClickDetail"
                      item-key="id"
                      :options.sync="options"
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                      }"
                      :server-items-length="totalData"
                      :page="paramAPI.page"
                      @update:page="updatePage"
                      :items-per-page="paramAPI.itemsPerPage"
                      @update:items-per-page="updateItemPerPage"
                    >
                      <template v-slot:[`item.no`]="{ index }">
                        <div
                          style="
                            font-size: 14px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                        </div>
                      </template>
                      <template v-slot:[`item.employee.nik`]="{ item }">
                        <div
                          :style="`width:100px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.employee.nik }}
                        </div>
                      </template>
                      <template v-slot:[`item.employee.name`]="{ item }">
                        <div
                          :style="`width:120px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.employee.name }}
                        </div>
                      </template>
                      <template v-slot:[`item.company.name`]="{ item }">
                        <div
                          :style="`width:150px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.company.name }}
                        </div>
                      </template>
                      <template v-slot:[`item.shift`]="{ item }">
                        <div
                          :style="`width:75px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.shift != null ? item.shift.name : '-' }}
                        </div>
                      </template>
                      <template v-slot:[`item.is_break`]="{ item }">
                        <div
                          :style="`color:${
                            item.is_break ? 'green' : 'red'
                          }; width:75px; font-size:12px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.is_break ? 'Yes' : 'No' }}
                        </div>
                      </template>
                      <template v-slot:[`item.total_hour`]="{ item }">
                        <div
                          :style="`width:100px; font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.total_hour }}
                        </div>
                      </template>
                      <template v-slot:[`item.request_start_time`]="{ item }">
                        <div
                          style="
                            width: 100px;
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.request_start_time) }}
                        </div>
                      </template>
                      <template v-slot:[`item.request_end_time`]="{ item }">
                        <div
                          style="
                            width: 100px;
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.request_end_time) }}
                          <span v-if="item.shift != null">
                            ({{ item.shift.name }})
                          </span>
                        </div>
                      </template>
                      <template v-slot:[`item.created_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.created_at) }}
                        </div>
                      </template>
                      <template v-slot:[`item.updated_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.updated_at) }}
                        </div>
                      </template>
                      <template v-slot:[`item.status_request`]="{ item }">
                        <div
                          :style="`text-transform:uppercase; font-size:12px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; color:${statusColor(
                            item.status_request
                          )};`"
                        >
                          {{ statusName(item.status_request) }}
                        </div> </template
                      ><template v-slot:[`item.created_by_name`]="{ item }">
                        <div
                          style="
                            width: 100px;
                            font-size: 11px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.created_by_name }}
                        </div>
                      </template>
                    </v-data-table>
                    <v-skeleton-loader
                      v-else
                      style="width: 100%"
                      type="table-heading, table-tbody, table-tfoot"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
          <v-dialog v-model="settingDialog" persistent max-width="700px">
            <v-card v-if="form !== null">
              <v-card-title>
                <span class="text-h5">AJUKAN LEMBUR</span>
              </v-card-title>
              <v-form ref="entryForm2" @submit.prevent="saveSetting">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        style="
                          padding: 0 0 0 10px;
                          display: flex;
                          justify-content: start;
                          align-items: center;
                          margin-top: 20px;
                        "
                      >
                        <v-col
                          cols="6"
                          style="
                            padding: 0;
                            justify-content: flex-start;
                            align-items: center;
                            display: flex;
                          "
                        >
                          <p
                            style="
                              margin: 0 0 0 5px;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Ajukan untuk karyawan lain
                          </p>
                          <v-checkbox
                            v-model="form.for_other_user"
                            @change="usesWatcher"
                            :false-value="false"
                            :true-value="true"
                            style="margin: 0 0 0 10px; height: 25px; padding: 0"
                          ></v-checkbox>
                        </v-col>
                      </v-col>
                      <v-col
                        v-if="
                          form.for_other_user &&
                          getUserProfile.level.find(
                            ({ id }) =>
                              id == appLevel.administrator ||
                              id == appLevel.adminMaster
                          ) != undefined
                        "
                        cols="12"
                        class="d-flex flex-column"
                      >
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Perusahaan
                        </p>

                        <v-autocomplete
                          v-model="form.company"
                          item-text="name"
                          item-value="id"
                          style="font-size: 12px; height: 45px"
                          return-object
                          dense
                          outlined
                          clearable
                          @change="addCompanyWatcher"
                          :items="dropdown.company"
                          :disabled="isDisableCompanyDropdown"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        v-if="
                          form.for_other_user &&
                          getUserProfile.level.find(
                            ({ id }) =>
                              id == appLevel.administrator ||
                              id == appLevel.adminMaster
                          ) != undefined
                        "
                        cols="12"
                        class="d-flex flex-column"
                      >
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Department
                        </p>

                        <v-autocomplete
                          v-model="form.department"
                          @change="addDepartmentWatcher"
                          :items="dropdown.department"
                          :disabled="form.company == null"
                          style="font-size: 12px; height: 45px"
                          item-text="name"
                          item-value="id"
                          return-object
                          dense
                          outlined
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        v-if="form.for_other_user"
                        cols="12"
                        class="d-flex flex-column"
                      >
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Karyawan
                        </p>

                        <v-autocomplete
                          v-model="form.employee"
                          :items="dropdown.employee"
                          :search-input.sync="searchEmployee"
                          :rules="rules.employeeRules"
                          item-text="name"
                          item-value="id"
                          return-id
                          outlined
                          style="margin: 0"
                          clearable
                          :disabled="form.company == null"
                          multiple
                          cache-items
                          small-chips
                          deletable-chips
                        >
                          <template v-slot:item="data">
                            <template>
                              <div style="width: 50px">
                                <v-list-item-content>
                                  <v-checkbox
                                    readonly
                                    :value="
                                      form.employee != null
                                        ? form.employee.includes(data.item.id)
                                        : null
                                    "
                                    :false-value="false"
                                    :true-value="true"
                                    style="
                                      margin: 0 0 0 10px;
                                      height: 25px;
                                      padding: 0;
                                    "
                                  ></v-checkbox>
                                </v-list-item-content>
                              </div>
                              <v-list-item-avatar>
                                <img :src="`${data.item.photo}`" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ data.item.company_name }}-{{
                                    data.item.nik
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        v-if="form.use_shift"
                        cols="12"
                        md="6"
                        class="d-flex flex-column"
                      >
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Tanggal lembur
                        </p>
                        <v-text-field
                          v-model="form.date"
                          :rules="rules.dateRules"
                          clearable
                          dense
                          outlined
                          type="date"
                          name="date"
                          step="1"
                          style="height: 45px"
                        />
                      </v-col>
                      <v-col
                        v-if="form.use_shift"
                        cols="12"
                        md="6"
                        class="d-flex flex-column"
                      >
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Pilih Shift
                        </p>

                        <v-autocomplete
                          v-model="form.shift"
                          :items="dropdown.shift"
                          :rules="rules.shiftRules"
                          @change="shiftWatcher"
                          item-text="name"
                          item-value="id"
                          return-object
                          outlined
                          dense
                          style="margin: 0; height: 45px"
                          clearable
                          :disabled="form.date == null"
                        >
                        </v-autocomplete>
                      </v-col>
                      <!-- <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Company
                        </p>
                        <v-autocomplete
                          v-model="form.company"
                          :items="dropdown.company"
                          :rules="rules.companyRules"
                          style="height: 40px"
                          item-text="name"
                          item-value="id"
                          return-object
                          dense
                          outlined
                          clearable
                        ></v-autocomplete>
                      </v-col> -->

                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Waktu mulai
                        </p>
                        <v-text-field
                          v-model="form.start_time"
                          :rules="rules.startRules"
                          @change="startWatcher"
                          clearable
                          dense
                          outlined
                          type="datetime-local"
                          name="datetime-local"
                          step="1"
                          style="height: 45px"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Waktu berakhir
                        </p>
                        <v-text-field
                          v-model="form.end_time"
                          :rules="rules.endRules"
                          @change="endWatcher"
                          clearable
                          dense
                          outlined
                          type="datetime-local"
                          name="datetime-local"
                          step="1"
                          style="height: 45px"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Total waktu
                        </p>
                        <v-text-field
                          v-model="form.total_hour"
                          disabled
                          dense
                          outlined
                          type="number"
                          min="0"
                          style="height: 45px"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Pembulatan
                        </p>
                        <v-text-field
                          v-model="form.total_hour_rounding"
                          dense
                          disabled
                          outlined
                          type="number"
                          min="0"
                          style="height: 45px"
                        />
                      </v-col>
                      <!-- <v-col cols="12" md="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Jumlah pengali
                  </p>
                  <v-text-field
                    v-model="form.multiplier"
                    type="number"
                    min="0"
                    dense
                    outlined
                    style="height:45px;"
                  />
                </v-col> -->
                      <v-col
                        cols="12"
                        style="
                          padding: 0 0 0 10px;
                          display: flex;
                          justify-content: start;
                          align-items: center;
                          margin-top: 20px;
                        "
                      >
                        <v-col
                          cols="6"
                          style="
                            padding: 0;
                            justify-content: flex-start;
                            align-items: center;
                            display: flex;
                          "
                        >
                          <p
                            style="
                              margin: 0 0 0 5px;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Dipotong istirahat ?
                          </p>
                          <v-checkbox
                            v-model="form.is_break"
                            :false-value="false"
                            :true-value="true"
                            style="margin: 0 0 0 10px; height: 25px; padding: 0"
                          ></v-checkbox>
                        </v-col>
                      </v-col>
                      <v-col cols="12" class="d-flex flex-column">
                        <p
                          class="text-left"
                          style="
                            margin: 0;
                            font-size: 12px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.5);
                          "
                        >
                          Alasan
                        </p>
                        <v-textarea
                          dense
                          auto-grow
                          outlined
                          v-model="form.reason"
                          counter="255"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeSettingDialog">
                    Close
                  </v-btn>
                  <v-btn
                    v-if="permission.create_perm == 1"
                    color="blue darken-1"
                    text
                    type="submit"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>

        <ScheduleImport @loadPage="getDataFromApi" />
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      YOU CANNOT ACCESS THIS PAGE
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
import moment from 'moment'
import ScheduleImport from './Import.vue'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'msovertime',
  components: { ScheduleImport },
  data: () => ({
    editDialog: false,
    settingDialog: false,
    config: require('../../../services/config.json')[env],
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    oAuthApi: buildType.apiURL('oauth'),
    build: process.env.VUE_APP_BUILD_TYPE,
    permission: null,
    appLevel: null,
    userLevel: null,
    isLoadingPage: true,
    isDisableCompanyDropdown: false,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'id',
      sortType: 'ASC',
      company_id: null,
      department_id: null,
      employee_id: null,
      mode: 1,
      status_id: null,
      documentType: 1
    },
    totalData: 0,
    form: {
      act: 'add',
      for_other_user: false,
      use_shift: true,
      employee: [],
      company: null,
      department: null,
      start_time: null,
      end_time: null,
      date: null,
      reason: '',
      total_hour: 0,
      total_hour_rounding: 0,
      multiplier: 0,
      is_break: true
    },
    searchEmployee: null,
    selectedEmployee: null,
    detail: null,
    isEdit: false,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false
      },
      {
        text: 'No. Pengajuan',
        value: 'document_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'created_by_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company_name',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department.name',
        sortable: false
      },
      {
        text: 'Keterangan',
        value: 'description',
        sortable: false
      },
      {
        text: 'Dibuat pada',
        value: 'created_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Diupdate pada',
        value: 'updated_at',
        align: 'left',
        sortable: false
      }
    ],
    detailHeaders: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status_request',
        sortable: false
      },
      {
        text: 'No. Pengajuan',
        value: 'request_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Karyawan',
        value: 'employee.name',
        sortable: false
      },
      {
        text: 'NIP',
        value: 'employee.nik',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company.name',
        sortable: false
      },
      {
        text: 'Shift',
        value: 'shift',
        sortable: false
      },
      {
        text: 'Waktu mulai',
        value: 'request_start_time',
        sortable: false
      },
      {
        text: 'Waktu berakhir',
        value: 'request_end_time',
        sortable: false
      },
      {
        text: 'Total waktu lembur',
        value: 'total_hour',
        sortable: false
      },
      {
        text: 'Pembulatan',
        value: 'total_hour_rounding',
        sortable: false
      },
      {
        text: 'Istirahat ?',
        value: 'is_break',
        sortable: false
      },
      {
        text: 'Dibuat pada',
        value: 'created_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Dibuat Oleh',
        value: 'created_by_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Diupdate pada',
        value: 'updated_at',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    rules: {
      employeeRules: [],
      companyRules: [],
      startRules: [],
      endRules: [],
      dateRules: [],
      shiftRules: []
    },
    dropdown: {
      company: [],
      employee: [],
      department: [],
      user_type: [],
      status: [],
      shift: []
    },
    skeleton_loading: true
  }),

  async mounted() {
    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile', 'getOvertimePageQuery'])
  },
  watch: {
    searchEmployee(val) {
      if (val !== '') {
        val && val !== this.selectedEmployee && this.querySelections(val)
      } else {
        this.selectedEmployee = null
        this.dropdown.employee = []
      }
    },
    'form.employee'() {
      if (this.form.employee == null) {
        this.searchEmployee = null
        this.selectedEmployee = null
        this.dropdown.employee = []
      }
    }
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['setOvertimePageQuery']),
    importData() {
      setTimeout(() => {
        document.getElementById('overtimeImport').click()
      }, 200)
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: this.paramAPI.keyword,
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDataForLayout() {
      if (this.userLevel.is_support) {
        this.paramAPI.company_id = {
          id: 0,
          name: ''
        }
      } else {
        this.paramAPI.company_id = {
          id: Number(this.getUserProfile.employee.company.plant_id),
          name: this.getUserProfile.employee.company.name
        }
      }

      if (
        this.getUserProfile.level.find(
          ({ id }) => id == this.appLevel.adminMaster
        ) !== undefined
      ) {
        this.paramAPI.mode = ''
      }
      // this.paramAPI.department_id = this.getUserProfile.employee.department_id

      if (this.getOvertimePageQuery !== null) {
        this.paramAPI = this.getOvertimePageQuery
      }
      setTimeout(async () => {
        this.initDropdown()
        await this.getDataFromApi()
      }, 200)
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    searchEnter() {
      // this.$router.replace({
      //   ...this.$route,
      //   query: {
      //     keyword: this.paramAPI.keyword
      //     // TODO: Convert `filter` to params
      //   }
      // })
      // this.$route.fullPath + url.slice(url.indexOf('?')))
      this.resetSomeParam()
      this.getDataFromApi()
    },
    async shiftWatcher(val) {
      // console.log(this.form.date)
      // console.log(
      //   moment(this.form.date).subtract(1, 'days').format('YYYY-MM-YY')
      // )
      if (this.form != null && this.form.shift != null) {
        var start = ''
        var end = ''
        const diffHours = await this.getDiffHourByTime(val)
        console.log('perbedaan jam>>>', diffHours)
        // cek if difference hours define its shift security or not
        if (Math.abs(diffHours) < 12.5) {
          // shift secyrity then add 1 day from end time
          start = this.form.date + 'T' + val.start_time
          if (val.start_time >= '20:00:00' && val.start_time <= '23:59:59') {
            const newDate = moment(this.form.date)
              .add(1, 'days')
              .format('YYYY-MM-DD')
            end = newDate + 'T' + val.end_time
          } else {
            end = this.form.date + 'T' + val.end_time
          }
        } else {
          // shift normal then substract 1 day from start time
          end = this.form.date + 'T' + val.end_time
          if (val.start_time >= '20:00:00' && val.start_time <= '23:59:59') {
            const xNewDate = moment(this.form.date)
              .subtract(1, 'days')
              .format('YYYY-MM-DD')
            start = xNewDate + 'T' + val.start_time
          } else {
            start = this.form.date + 'T' + val.start_time
          }
        }

        this.form.start_time = start
        this.form.end_time = end

        if (this.form.start_time !== null && this.form.end_time !== null) {
          this.getDifferencies()
        }
      } else {
        this.form.start_time = null
        this.form.end_time = null
      }
    },
    usesWatcher(val) {
      if (this.form !== null) {
        if (this.form.for_other_user) {
          this.form.employee = []
        } else {
          // this.form.employee = {
          //   id: Number(this.getUserProfile.employee.id),
          //   name: this.getUserProfile.employee.name
          // }
          this.initAddForm()
        }
      }
    },
    pickWatcher(val) {
      if (this.form != null) {
        this.form.date = null
        this.form.shift = null
        this.form.start_time = null
        this.form.end_time = null
      }
    },
    startWatcher(val) {
      if (this.form !== null) {
        if (this.form.start_time !== null && this.form.end_time !== null) {
          this.getDifferencies()
        }
      }
    },
    endWatcher(val) {
      if (this.form !== null) {
        if (this.form.start_time !== null && this.form.end_time !== null) {
          this.getDifferencies()
        }
      }
    },
    async documentWatcher(v) {
      // this.paramAPI.mode = v
      this.result = []
      this.totalData = 0
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    async modeWatcher(v) {
      this.paramAPI.mode = v
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    async statusWatcher(v) {
      this.paramAPI.status_id = v
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    querySelections(v) {
      let url = `${this.hrsApi}employee/dropdown?keyword=${v}&status=1`
      if (this.form.company !== null) {
        url = url + `&company_id=${this.form.company.id}`
      }
      // if (this.form.department !== null) {
      //   url = url + `&department_parent_id=${this.form.department.id}`
      // }
      axios
        .get(url)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: res.data.data[i].id,
                nik: res.data.data[i].nik,
                name: res.data.data[i].name,
                company_id: res.data.data[i].company_id,
                company_name: this.getCompanyAlias(res.data.data[i].company_id),
                photo: res.data.data[i].photo
              })
            }
            return (this.dropdown.employee = resArr)
          }
          return (this.dropdown.employee = [])
        })
        .catch((err) => {
          this.dropdown.employee = []
          return console.log(err)
        })
    },
    async initDropdown() {
      this.getDropdownCompany()
      this.dropdownUserType()
      this.dropdownStatus()
      this.dropdownDepartment()
    },
    companyWatcher(v) {
      this.resetSomeParam()
      this.getDataFromApi()

      if (v != null) {
        this.dropdownDepartment()
      } else {
        this.dropdown.department = []
      }
    },
    addCompanyWatcher(v) {
      this.dropdown.employee = []
      this.searchEmployee = null
      this.selectedEmployee = null
      this.form.department = null

      if (v != null) {
        this.dropdownDepartment()
        this.dropdownShift()
      } else {
        this.dropdown.department = []
      }
    },
    addDepartmentWatcher() {
      this.dropdown.employee = []
      this.searchEmployee = null
      this.selectedEmployee = null
    },
    departmentWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    dropdownStatus() {
      this.dropdown.status = [
        {
          id: '-2',
          name: 'Canceled'
        },
        {
          id: '-1',
          name: 'Rejected'
        },
        {
          id: '0',
          name: 'Draft'
        },
        {
          id: '1',
          name: 'Waiting Approval'
        },
        {
          id: '2',
          name: 'Approved'
        }
      ]
    },
    dropdownUserType() {
      if (
        this.getUserProfile.level.find(
          ({ id }) =>
            id == this.appLevel.adminMaster || id == this.appLevel.administrator
        ) !== undefined
      ) {
        this.dropdown.user_type = [
          {
            id: 3,
            name: 'Semuanya'
          },
          {
            id: 1,
            name: 'Pribadi'
          },
          {
            id: 2,
            name: 'Butuh Approval Saya'
          },
          {
            id: 4,
            name: 'Department'
          }
        ]
      } else {
        this.dropdown.user_type = [
          {
            id: 1,
            name: 'Pribadi'
          },
          {
            id: 2,
            name: 'Butuh Approval Saya'
          },
          {
            id: 4,
            name: 'Department'
          }
        ]
      }
    },
    async getDropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
      // else {
      //   this.dropdownCompanyApi()
      // }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async dropdownDepartment() {
      let url = `${this.hrsApi}master/universal/department/dropdown`
      if (this.form.company != null) {
        url = url + `?filter=[{"company_id":${this.form.company.id}}]`
      } else {
        if (this.paramAPI.company_id != null) {
          url = url + `?filter=[{"company_id":${this.paramAPI.company_id.id}}]`
        }
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('department>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async dropdownShift() {
      let url = `${this.hrsApi}master/universal/shift/dropdown`
      if (this.form.company != null) {
        url = url + `?filter=[{"company_id":${this.form.company.id}}]`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('shift>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.shift = res.data.data)
          }
          return (this.dropdown.shift = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.shift = [])
        })
    },

    async getDataFromApi() {
      this.loading = true
      if (this.paramAPI.documentType == 1) {
        await this.initHeaderDataTable()
          .then((data) => {
            if (data.status_code === '00') {
              this.totalData = Number(data.total_record)
              return (this.result = data.data)
            }
            return (this.result = [])
          })
          .catch((err) => {
            console.log(err)
            return (this.result = [])
          })
      } else {
        await this.initDetailDataTable()
          .then((data) => {
            if (data.status_code === '00') {
              this.totalData = Number(data.total_record)
              return (this.result = data.data)
            }
            return (this.result = [])
          })
          .catch((err) => {
            console.log(err)
            return (this.result = [])
          })
      }
      this.skeleton_loading = false
      this.loading = false
    },
    async initHeaderDataTable() {
      console.log(this.paramAPI)

      let url = `${this.hrsApi}employee/overtime_document/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.sortBy}&order_type=${
        this.paramAPI.sortType
      }&mode=${this.paramAPI.mode}`

      if (this.paramAPI.status_id !== null) {
        url = url + `&status=${this.paramAPI.status_id}`
        // Object.assign(querySetting, { st: `${this.paramAPI.status_id}` })
      }
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id.id}`
        // Object.assign(querySetting, { cp: `${this.paramAPI.company_id.id}` })
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id.id}`
      }
      this.setOvertimePageQuery(this.paramAPI)

      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log('list header overtime >>>>', res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    async initDetailDataTable() {
      let url = `${this.hrsApi}employee/overtime_request/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.sortBy}&order_type=${
        this.paramAPI.sortType
      }&mode=${this.paramAPI.mode}`

      if (this.paramAPI.status_id !== null) {
        url = url + `&status=${this.paramAPI.status_id}`
        // Object.assign(querySetting, { st: `${this.paramAPI.status_id}` })
      }
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id.id}`
        // Object.assign(querySetting, { cp: `${this.paramAPI.company_id.id}` })
      }
      if (this.paramAPI.employee_id !== null) {
        url = url + `&employee_id=${this.paramAPI.employee_id.id}`
      }
      this.setOvertimePageQuery(this.paramAPI)

      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log('list detail overtime >>>>', res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    // enableEdit() {
    //   var oldForm = this.form
    //   this.isEdit = !this.isEdit
    //   if (!this.isEdit) {
    //     this.form = oldForm
    //     if (this.form.act === 'add') {
    //       this.closeDialog()
    //     }
    //   }
    // },

    rowClickHeader(pItem) {
      console.log(pItem)
      setTimeout(() => {
        this.$router.push(`/hr/overtime/document/detail/${pItem.id}`)
      }, 100)
    },
    rowClickDetail(pItem) {
      console.log(pItem)
      setTimeout(() => {
        this.$router.push(`/hr/overtime/detail/${pItem.id}`)
      }, 100)
    },
    closeDialog() {
      this.resetForm()
      this.detail = null
      this.isEdit = false
      setTimeout(() => {
        this.editDialog = false
      }, 300)
    },
    resetForm() {
      this.form = {
        act: 'add',
        for_other_user: false,
        use_shift: true,
        employee: [],
        company: null,
        department: null,
        start_time: null,
        end_time: null,
        reason: '',
        total_hour: 0,
        total_hour_rounding: 0,
        multiplier: 0,
        is_break: true
      }
      this.dropdown.department = []
    },
    initAddForm() {
      console.log(this.getUserProfile.employee)
      this.form.company = {
        id: Number(this.getUserProfile.employee.company.plant_id),
        name: this.getUserProfile.employee.company.name
      }

      // this.form.department = {
      //   id: Number(this.getUserProfile.employee.department.id),
      //   name: this.getUserProfile.employee.department.name
      // }

      if (
        this.getUserProfile.employee.department.section != null &&
        this.getUserProfile.employee.department.section.id != null &&
        this.getUserProfile.employee.department.section.id != ''
      ) {
        this.form.department = {
          id: Number(this.getUserProfile.employee.department.section.id),
          name: this.getUserProfile.employee.department.section.name
        }
      } else {
        this.form.department = {
          id: Number(this.getUserProfile.employee.department.id),
          name: this.getUserProfile.employee.department.name
        }
      }

      // this.form.employee = {
      //   id: Number(this.getUserProfile.employee.id),
      //   name: this.getUserProfile.employee.name
      // }
      console.log('user>>', this.form)
      this.form.employee.push(Number(this.getUserProfile.employee.id))
    },
    addOvertime() {
      this.resetForm()
      setTimeout(() => {
        this.initAddForm()
        this.dropdownShift()
        this.dropdownDepartment()

        this.settingDialog = !this.settingDialog
      }, 300)
    },
    saveSetting() {
      if (this.permission.create_perm == 1) {
        console.log('Before submit>>>>', this.form)
        // this.rules.companyRules = [v => !!v || 'Company is required']
        this.rules.employeeRules = [(v) => !!v || 'Employee is required']
        this.rules.startRules = [(v) => !!v || 'Start is required']
        this.rules.endRules = [(v) => !!v || 'End is required']
        if (this.form.use_shift) {
          this.rules.shiftRules = [(v) => !!v || 'Shift is required']
          this.rules.dateRules = [(v) => !!v || 'Date is required']
        }
        const self = this
        setTimeout(function () {
          if (self.$refs.entryForm2.validate()) {
            // self.createNewForm()
            const form = {
              act: self.form.act,
              employee_id: self.form.employee,
              company_id: Number(self.form.company.id),
              company_name: self.form.company.name,
              department_id:
                self.form.department != null
                  ? Number(self.form.department.id)
                  : null,

              request_start_time: self.form.start_time,
              request_end_time: self.form.end_time,
              total_hour: self.form.total_hour,
              total_hour_rounding: self.form.total_hour_rounding,
              multiplier: self.form.multiplier,
              reason: self.form.reason,
              is_break: self.form.is_break,
              shift_id: self.form.shift != null ? self.form.shift.id : null,
              date: self.form.date
            }
            if (form.act === 'update') {
              Object.assign(form, { id: self.form.id })
            }

            console.log('Formulir>>>>', form)
            self.submitSetting(form)
          }
        })
      }
    },
    async submitSetting(form) {
      this.isLoadingPage = true
      await axios
        .post(`${this.hrsApi}employee/overtime_document/save`, form)
        .then(async (res) => {
          console.log(res.data)
          this.isLoadingPage = false
          if (res.data.status_code !== '-99') {
            // this.showMsgDialog('success', res.data.status_msg, false)
            // this.getDataFromApi()
            this.closeSettingDialog()

            this.showMsgDialog('success', res.data.status_msg, false).then(
              (response) => {
                setTimeout(() => {
                  this.$router.push(
                    `/hr/overtime/document/detail/${res.data.created_id}`
                  )
                }, 500)
              }
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.isLoadingPage = false
        })
    },
    async deleteItem(pItem) {
      // this.showMsgDialog(
      //   'question',
      //   'Are you sure, This data will be deleted permanently',
      //   true
      // ).then(async res => {
      //   if (res.isConfirmed) {
      //     await axios
      //       .delete(
      //         `${this.hrsApi}master/universal/overtimesetting/delete/${pItem.id}`
      //       )
      //       .then(async res => {
      //         if (res.data.status_code !== '-99') {
      //           this.showMsgDialog('success', res.data.status_msg, false)
      //           this.getDataFromApi()
      //         } else {
      //           this.showMsgDialog('warning', res.data.status_msg, false)
      //         }
      //       })
      //       .catch(err => {
      //         console.log(err)
      //         this.showMsgDialog(
      //           'error',
      //           err
      //             ? 'Something went wrong, Please contact an admin!'
      //             : 'Something went wrong, Please contact an admin!',
      //           false
      //         )
      //       })
      //   }
      // })
    },
    closeSettingDialog() {
      this.resetForm()
      setTimeout(() => {
        this.settingDialog = false
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    modeDisable(item) {
      if (
        this.getUserProfile.level.find(
          ({ id }) => id == this.appLevel.adminMaster
        ) !== undefined
      ) {
        return false
      } else {
        return item.id === ''
      }
    },
    getDifferencies() {
      const timeStart = moment(this.form.start_time)
      const timeEnd = moment(this.form.end_time)

      var diff_s = timeEnd.diff(timeStart)
      var x = moment.duration(diff_s)
      var result = x / 60 / 60 / 1000

      this.form.total_hour = result.toFixed(2)

      // const rounding = Math.round(result * 100) / 100
      this.form.total_hour_rounding = Math.round(result)

      console.log(
        'perbedaan waktu >>>',
        result,
        result.toFixed(2),
        Math.round(result)
      )
    },
    getDiffHourByTime(val) {
      const moment1 = moment(val.start_time, 'HH:mm')
      const moment2 = moment(val.end_time, 'HH:mm')
      const diffInHours = moment2.diff(moment1, 'hours', true)
      // time compared behaviour
      // exp. start 23:00:00 end 07:00:00 then 8 - 23 = 16 not 8
      return diffInHours
    },
    statusName(val) {
      switch (val) {
        case -2:
          return 'CANCELED'
        case -1:
          return 'REJECTED'
        case 0:
          return 'DRAFT'
        case 1:
          return 'WAITING APPROVAL'
        case 2:
          return 'APPROVED'
        default:
          break
      }
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'red'
        case -1:
          return 'red'
        case 0:
          return 'blue'
        case 1:
          return 'orange'
        case 2:
          return 'green'
        default:
          break
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    },
    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJ2'
      }
    }
  }
}
</script>
<style lang="scss">
#overtimeLIst {
  position: relative;
  .overtime-list-dev {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .overtime-list-toolbar-1 {
      .overtime-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #overtimeLIst {
    .overtime-list-dev {
      .overtime-list-toolbar-1 {
        .overtime-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
