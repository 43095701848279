<template>
  <div v-if="permission.read_perm == 1">
    <v-form
      v-if="result !== null"
      ref="entryForm"
      @submit.prevent="submit"
      lazy-validation
      :disabled="!isEdit"
    >
      <v-row style="padding: 15px" no-gutters>
        <v-col
          v-if="permission.update_perm == 1"
          cols="12"
          style="padding: 0 10px; text-align: right"
        >
          <a
            v-if="!isEdit"
            href=""
            @click.prevent="editForm"
            style="font-size: 14px"
          >
            <v-icon small color="#1976d2">
              mdi-application-edit-outline
            </v-icon>
            Edit info
          </a>
          <p
            v-else
            @click.prevent="editForm"
            style="font-size: 14px; margin: 0; color: red; cursor: pointer"
          >
            Batal
            <v-icon small color="red"> mdi-close </v-icon>
          </p>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px 10px 10px">
          <v-img
            v-if="!isEdit"
            :src="result.photo"
            lazy-src="https://cdn-icons-png.flaticon.com/512/61/61173.png"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <!-- <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template> -->
          </v-img>

          <div
            v-else
            style="
              padding: 10px;
              border: 2px dashed grey;
              position: absolute;
              top: 0;
              left: 10px;
              right: 10px;
              bottom: 10px;
            "
            class="d-flex flex-column justify-center align-center"
          >
            <div style="height: 45px; color: red; width: 100%">
              <v-file-input
                ref="fotoUpload"
                label="Pilih foto"
                dense
                outlined
                prepend-icon=""
                @change="uploadFoto"
              ></v-file-input>
            </div>
            <p style="margin: 0; width: 100%">
              {{ result.photo }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <v-row no-gutters>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  Nama
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.name" />
              </v-col>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  Email
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.email" />
              </v-col>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  No. KTP
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.no_identitas" />
              </v-col>
            </v-col>
            <!-- <v-col cols="12" md="6" style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                No. SMI
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field dense value="09874" />
            </v-col>
          </v-col>
          <v-col cols="12" md="6" style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                VIP. SMI
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field dense value="09874-100001" />
            </v-col>
          </v-col> -->
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  No. Telepon
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.mobile_phone_no" />
              </v-col>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  Jenis Kelamin
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-autocomplete
                  :items="[
                    {
                      name: 'Laki-laki',
                      id: 1
                    },
                    {
                      name: 'Perempuan',
                      id: 2
                    }
                  ]"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  style="margin: 0"
                  v-model="result.gender.id"
                  :append-icon="!isEdit ? '' : '$dropdown'"
                ></v-autocomplete>
              </v-col>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  Agama
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-autocomplete
                  v-if="result.religion !== null"
                  :items="dropdown.religion"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  style="margin: 0"
                  v-model="result.religion.id"
                  :append-icon="!isEdit ? '' : '$dropdown'"
                ></v-autocomplete>
                <v-autocomplete
                  v-else
                  :items="dropdown.religion"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  style="margin: 0"
                  v-model="result.religion"
                  :append-icon="!isEdit ? '' : '$dropdown'"
                ></v-autocomplete>
              </v-col>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  Tempat
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.birth_place" />
              </v-col>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  Tgl. Lahir
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  dense
                  v-model="result.birth_date"
                  type="date"
                  name="date"
                  step="1"
                />
              </v-col>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 13px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.6);
                  "
                >
                  Usia
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense :value="getAge()" />
              </v-col>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" style="padding: 0 10px"><br /></v-col>

        <v-col cols="12" md="6" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Alamat Sesuai KTP
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-textarea v-model="result.address" dense rows="1" auto-grow />
          </v-col>
        </v-col>
        <v-col cols="12" md="6" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Alamat Domisili
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-textarea
              v-model="result.domicile_address"
              dense
              rows="1"
              auto-grow
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Kebangsaan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              @keydown.enter.prevent
              v-if="dropdown.nationality.length > 0"
              v-model="result.nationality"
              :items="dropdown.nationality"
              dense
              style="margin: 0"
            >
            </v-autocomplete>

            <v-text-field
              v-else
              @keydown.enter.prevent
              dense
              v-model="result.nationality"
            />
          </v-col>
        </v-col>
        <!-- <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Pendidikan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              :items="dropdown.education"
              dense
              style="margin: 0"
              v-model="result.education_level"
              clearable
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Jurusan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.education_major"
            />
          </v-col>
        </v-col> -->
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Status Pernikahan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              :items="[
                {
                  name: 'Belum Menikah',
                  id: 1
                },
                {
                  name: 'Menikah',
                  id: 2
                },
                {
                  name: 'Cerai',
                  id: 3
                }
              ]"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.mariage_status.id"
              clearable
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>

        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Ukuran Baju
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.clothing_size"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Tinggi Badan (cm)
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              @keydown.enter.prevent
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.height"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Berat Badan (kg)
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              @keydown.enter.prevent
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.weight"
            />
          </v-col> </v-col
        ><v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Golongan darah
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              :items="dropdown.blood_type"
              dense
              style="margin: 0"
              v-model="result.blood_type"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Status Tempat Tinggal
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              @keydown.enter.prevent
              v-model="result.house_status"
              :items="dropdown.house_status"
              item-text="name"
              item-value="id"
              return-name
              dense
              style="margin: 0"
            ></v-autocomplete>
          </v-col>
        </v-col>
      </v-row>
      <v-row style="padding: 15px" no-gutters>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Status PTKP
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              v-if="result.ptkp_status !== null"
              :items="dropdown.ptkp_status"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.ptkp_status.id"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="dropdown.ptkp_status"
              item-text="name"
              item-value="id"
              return-object
              dense
              style="margin: 0"
              v-model="result.ptkp_status"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              NPWP
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              @keydown.enter.prevent
              dense
              v-model="result.npwp_no"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              No. Rekening
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              @keydown.enter.prevent
              dense
              v-model="result.account_number"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              No. BPJS Kesehatan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              @keydown.enter.prevent
              dense
              v-model="result.bpjs_no"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              No. BPJS Ketenagakerjaan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              @keydown.enter.prevent
              dense
              v-model="result.bpjs_tk_no"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Status BPJS
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              @keydown.enter.prevent
              v-model="result.bpjs_status"
              :items="dropdown.bpjs_status"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
            >
            </v-autocomplete>
          </v-col>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row style="padding: 15px" no-gutters>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Nomor KK
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              @keydown.enter.prevent
              dense
              v-model="result.kk_no"
            />
          </v-col>
        </v-col>
        <v-col cols="12" style="padding: 0 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 13px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.6);
                "
              >
                Data Keluarga
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-data-table
                :headers="familyHeaders"
                :items="result.family_structure"
                hide-default-footer
                class="elevation-1"
                dense
              >
                <template v-slot:[`item.hubungan_keluarga`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['Ayah', 'Ibu', 'Saudara', 'Anak']"
                      dense
                      style="margin: 0"
                      v-model="item.hubungan_keluarga"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.name"
                    />
                  </div>
                </template>
                <template v-slot:[`item.gender`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['Laki-laki', 'Perempuan']"
                      dense
                      style="margin: 0"
                      v-model="item.gender"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.birth_date`]="{ item }">
                  <div style="width: 165px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      type="date"
                      dense
                      v-model="item.birth_date"
                    />
                  </div>
                </template>
                <template v-slot:[`item.education`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="dropdown.education"
                      dense
                      style="margin: 0"
                      v-model="item.education"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.job_type`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.job_type"
                    />
                  </div>
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.company_name"
                    />
                  </div>
                </template>
                <template v-slot:[`item.position`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.position"
                    />
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }">
                  <div style="width: 180px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.description"
                    />
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item, index }">
                  <div
                    class="d-flex justify-center align-center"
                    style="width: 50px; height: 60px"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="error"
                          @click.stop="deleteFamiliy(item, index)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </div>

          <v-btn
            v-if="isEdit"
            style="font-size: 10px; margin: 5px 0 0 10px"
            @click="addFamily"
            elevation="2"
            tilerounded="5px"
            x-small
            color="primary"
            class="white--text"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="">
                  mdi-plus
                </v-icon>
              </template>
              <span>Tambah</span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" style="padding: 0; margin-top: 25px">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 13px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.6);
                "
              >
                Riwayat Pendidikan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-data-table
                :headers="educationHeaders"
                :items="result.formal_education"
                hide-default-footer
                class="elevation-1"
                dense
              >
                <template v-slot:[`item.name`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.name"
                    />
                  </div>
                </template>
                <template v-slot:[`item.educational_level`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="dropdown.education"
                      dense
                      style="margin: 0"
                      v-model="item.educational_level"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.major`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.major"
                    />
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['Lulus', 'Tidak Lulus']"
                      dense
                      style="margin: 0"
                      v-model="item.status"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.start_year`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.start_year"
                    />
                  </div>
                </template>
                <template v-slot:[`item.end_year`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.end_year"
                    />
                  </div>
                </template>
                <template v-slot:[`item.jurusan`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.jurusan"
                    />
                  </div>
                </template>
                <template v-slot:[`item.address`]="{ item }">
                  <div style="width: 180px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.address"
                    />
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item, index }">
                  <div
                    class="d-flex justify-center align-center"
                    style="width: 50px; height: 60px"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="error"
                          @click.stop="deleteEducation(item, index)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </div>

          <v-btn
            v-if="isEdit"
            style="font-size: 10px; margin: 5px 0 0 10px"
            @click="addEducation"
            elevation="2"
            tilerounded="5px"
            x-small
            color="primary"
            class="white--text"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="">
                  mdi-plus
                </v-icon>
              </template>
              <span>Tambah</span>
            </v-tooltip>
          </v-btn>
        </v-col>

        <v-col cols="12" style="padding: 0; margin-top: 25px">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 13px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.6);
                "
              >
                Keahlian Bahasa
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-data-table
                :headers="languageSkillHeaders"
                :items="result.language_skill"
                hide-default-footer
                class="elevation-1"
                dense
              >
                <template v-slot:[`item.language`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.language"
                    />
                  </div>
                </template>
                <template v-slot:[`item.reading_level`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['Baik', 'Cukup', 'Kurang']"
                      dense
                      style="margin: 0"
                      v-model="item.reading_level"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.writing_level`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['Baik', 'Cukup', 'Kurang']"
                      dense
                      style="margin: 0"
                      v-model="item.writing_level"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.listening_level`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['Baik', 'Cukup', 'Kurang']"
                      dense
                      style="margin: 0"
                      v-model="item.listening_level"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.speaking_level`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['Baik', 'Cukup', 'Kurang']"
                      dense
                      style="margin: 0"
                      v-model="item.speaking_level"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item, index }">
                  <div
                    class="d-flex justify-center align-center"
                    style="width: 50px; height: 60px"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="error"
                          @click.stop="deleteLanguangeSkill(item, index)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </div>

          <v-btn
            v-if="isEdit"
            style="font-size: 10px; margin: 5px 0 0 10px"
            @click="addLanguageSkill"
            elevation="2"
            tilerounded="5px"
            x-small
            color="primary"
            class="white--text"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="">
                  mdi-plus
                </v-icon>
              </template>
              <span>Tambah</span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" style="padding: 0; margin-top: 25px">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 13px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.6);
                "
              >
                SIM
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-data-table
                :headers="simHeaders"
                :items="result.sim"
                hide-default-footer
                class="elevation-1"
                dense
              >
                <template v-slot:[`item.no`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.no"
                    />
                  </div>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <div style="width: 120px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['SIM A', 'SIM B1', 'SIM B2', 'SIM C', 'SIM D']"
                      dense
                      style="margin: 0"
                      v-model="item.type"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.effective_date`]="{ item }">
                  <div style="width: 210px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      type="date"
                      dense
                      v-model="item.effective_date"
                    />
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item, index }">
                  <div
                    class="d-flex justify-center align-center"
                    style="width: 50px; height: 60px"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="error"
                          @click.stop="deleteSim(item, index)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </div>

          <v-btn
            v-if="isEdit"
            style="font-size: 10px; margin: 5px 0 0 10px"
            @click="addSim"
            elevation="2"
            tilerounded="5px"
            x-small
            color="primary"
            class="white--text"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="">
                  mdi-plus
                </v-icon>
              </template>
              <span>Tambah</span>
            </v-tooltip>
          </v-btn>
        </v-col>
        <v-col cols="12" style="padding: 0; margin: 25px 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 13px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.6);
                "
              >
                Kendaraan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-data-table
                :headers="vehicleHeaders"
                :items="result.vehicle"
                hide-default-footer
                class="elevation-1"
                dense
              >
                <template v-slot:[`item.type`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.type"
                    />
                  </div>
                </template>
                <template v-slot:[`item.merk`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.merk"
                    />
                  </div>
                </template>
                <template v-slot:[`item.year`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-text-field
                      @keydown.enter.prevent
                      dense
                      v-model="item.year"
                    />
                  </div>
                </template>
                <template v-slot:[`item.owner`]="{ item }">
                  <div style="width: 160px; height: 45px; font-size: 12px">
                    <v-autocomplete
                      @keydown.enter.prevent
                      :items="['Milik Sendiri', 'Milik Orang Tua', 'Sewa']"
                      dense
                      style="margin: 0"
                      v-model="item.owner"
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item, index }">
                  <div
                    class="d-flex justify-center align-center"
                    style="width: 50px; height: 60px"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="error"
                          @click.stop="deleteVehicle(item, index)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </div>

          <v-btn
            v-if="isEdit"
            style="font-size: 10px; margin: 5px 0 0 10px"
            @click="addVehicle"
            elevation="2"
            tilerounded="5px"
            x-small
            color="primary"
            class="white--text"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="">
                  mdi-plus
                </v-icon>
              </template>
              <span>Tambah</span>
            </v-tooltip>
          </v-btn>
        </v-col>

        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Instagram
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              @keydown.enter.prevent
              dense
              style="height: 30px; position: relative; top: -3px"
              v-model="result.social_media.instagram"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Facebook
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              @keydown.enter.prevent
              dense
              style="height: 30px; position: relative; top: -3px"
              v-model="result.social_media.facebook"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Twitter/X
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              @keydown.enter.prevent
              dense
              v-model="result.social_media.x"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 13px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.6);
              "
            >
              Tiktok
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              @keydown.enter.prevent
              dense
              v-model="result.social_media.tiktok"
            />
          </v-col>
        </v-col>
        <v-col cols="12" style="padding: 0; text-align: right; margin: auto">
          <v-btn
            v-if="isEdit"
            type="submit"
            rounded
            elevation="0"
            color="primary"
            class="white--text font-weight-bold"
            style="font-size: 12px; width: 130px"
            :loading="loading"
          >
            Berikutnya <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="width: 80vw; height: 50vh; margin: auto"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="100"
      ></v-progress-circular>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
// import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  props: ['result', 'dropdown', 'isEdit', 'permission'],
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      upload: buildType.apiURL('upload2'),
      build: process.env.VUE_APP_BUILD_TYPE,
      // isEdit: false,
      loading: false,

      rules: {
        birthDateRules: [],
        birthPlaceRules: [],
        phoneRules: [],
        addressRules: [],
        nameRules: [],
        ktpRules: [],
        emailRules: [],
        genderRules: [],
        mariageRules: [],
        nikRules: [],
        contractDateRules: [],
        contractMonthRules: [],
        contractExpRules: [],
        companyRules: [],
        departmentRules: [],
        positionRules: [],
        joinDateRules: [],
        employeeStatusRules: []
      }
    }
  },
  watch: {
    isEdit() {
      if (!this.isEdit) {
        this.$emit('cancel')
      }
    }
  },
  computed: {
    familyHeaders() {
      const arr = [
        {
          text: 'Hubungan',
          value: 'hubungan_keluarga',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nama',
          value: 'name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jenis Kelamin',
          value: 'gender',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl. Lahir',
          value: 'birth_date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Pendidikan',
          value: 'education',
          align: 'left',
          sortable: false
        },
        {
          text: 'Pekerjaan',
          value: 'job_type',
          align: 'left',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'company_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jabatan',
          value: 'position',
          align: 'left',
          sortable: false
        },
        {
          text: 'Keterangan',
          value: 'description',
          align: 'left',
          sortable: false
        }
      ]

      if (this.isEdit) {
        arr.unshift({
          text: '',
          value: 'action',
          align: 'left',
          sortable: false
        })
      }
      return arr
    },
    educationHeaders() {
      const arr = [
        {
          text: 'Jenjang',
          value: 'educational_level',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nama Sekolah',
          value: 'name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Kota',
          value: 'address',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jurusan',
          value: 'major',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tahun Mulai',
          value: 'start_year',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tahun Berakhir',
          value: 'end_year',
          align: 'left',
          sortable: false
        },
        {
          text: 'Status Kelulusan',
          value: 'status',
          align: 'left',
          sortable: false
        }
        // {
        //   text: 'Transkrip Nilai',
        //   value: 'transcript',
        //   align: 'left',
        //   sortable: false
        // }
      ]

      if (this.isEdit) {
        arr.unshift({
          text: '',
          value: 'action',
          align: 'left',
          sortable: false
        })
      }
      return arr
    },
    languageSkillHeaders() {
      const arr = [
        {
          text: 'Bahasa',
          value: 'language',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tingkat Membaca',
          value: 'reading_level',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tingkat Menulis',
          value: 'writing_level',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tingkat Mendengar',
          value: 'listening_level',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tingkat Berbicara',
          value: 'speaking_level',
          align: 'left',
          sortable: false
        }
      ]
      if (this.isEdit) {
        arr.unshift({
          text: '',
          value: 'action',
          align: 'left',
          sortable: false
        })
      }
      return arr
    },
    vehicleHeaders() {
      const arr = [
        {
          text: 'Jenis',
          value: 'type',
          align: 'left',
          sortable: false
        },
        {
          text: 'Merk',
          value: 'merk',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tahun',
          value: 'year',
          align: 'left',
          sortable: false
        },
        {
          text: 'Pemilik',
          value: 'owner',
          align: 'left',
          sortable: false
        }
      ]
      if (this.isEdit) {
        arr.unshift({
          text: '',
          value: 'action',
          align: 'left',
          sortable: false
        })
      }
      return arr
    },
    simHeaders() {
      const arr = [
        {
          text: 'Tipe SIM',
          value: 'type',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nomor',
          value: 'no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tgl. Berlaku',
          value: 'effective_date',
          align: 'left',
          sortable: false
        }
      ]
      if (this.isEdit) {
        arr.unshift({
          text: '',
          value: 'action',
          align: 'left',
          sortable: false
        })
      }
      return arr
    }
  },
  methods: {
    addFamily() {
      this.result.family_structure.push({
        hubungan_keluarga: null,
        name: null,
        gender: null,
        birth_date: null,
        education: null,
        job_type: null,
        company_name: null,
        position: null,
        description: null
        // action: null
      })
    },
    deleteFamiliy(item, index) {
      // console.log(item, index)
      this.result.family_structure.splice(index, 1)
    },
    addEducation() {
      this.result.formal_education.push({
        educational_level: null,
        name: null,
        address: null,
        major: null,
        start_year: null,
        end_year: null,
        status: null,
        transcript: null
      })
    },
    deleteEducation(item, index) {
      // console.log(item, index)
      this.result.formal_education.splice(index, 1)
    },
    addLanguageSkill() {
      this.result.language_skill.push({
        language: null,
        reading_level: null,
        writing_level: null,
        listening_level: null,
        speaking_level: null
      })
    },
    deleteLanguageSkill(item, index) {
      // console.log(item, index)
      this.result.language_skill.splice(index, 1)
    },
    addVehicle() {
      this.result.vehicle.push({
        type: null,
        merk: null,
        year: null,
        owner: null
      })
    },
    deleteVehicle(item, index) {
      // console.log(item, index)
      this.result.vehicle.splice(index, 1)
    },
    addSim() {
      this.result.sim.push({
        type: null,
        no: null,
        effective_date: null
      })
    },
    deleteSim(item, index) {
      // console.log(item, index)
      this.result.sim.splice(index, 1)
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    uploadFoto(event) {
      if (event !== null) {
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'photo.employee')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then((res) => {
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.result.photo = res.data.data.name
            }
          })
          .catch((err) => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      } else {
        this.result.photo = ''
      }
    },
    editForm() {
      this.$emit('editForm')
    },
    submit() {
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      // console.log(this.result)
      // let department = null
      // let xPhoto = ''
      // if (process.env.VUE_APP_BUILD_TYPE === 'development') {
      //   xPhoto = this.result.photo.replace(
      //     'https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/',
      //     ''
      //   )
      // } else {
      //   xPhoto = this.result.photo.replace(
      //     'https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/',
      //     ''
      //   )
      // }
      // if (this.result.department !== null) {
      //   department = this.result.department_id
      // } else {
      //   department = this.result.department
      // }
      // const newForm = {
      //   act: 'update',
      //   id: this.$route.params.id,
      //   nik: this.result.nik,
      //   name: this.result.name,
      //   company_id:
      //     this.result.company !== null ? this.result.company.id : null,
      //   department_id: department,
      //   level_id: this.result.level !== null ? this.result.level.id : null,
      //   card_no: this.result.card_no,
      //   email: this.result.email,
      //   gender: this.result.gender !== null ? this.result.gender.id : null,
      //   religion:
      //     this.result.religion !== null ? this.result.religion.id : null,
      //   birth_place: this.result.birth_place,
      //   birth_date: this.result.birth_date,
      //   no_identitas: this.result.no_identitas,
      //   address: this.result.address,
      //   mobile_phone_no: this.result.mobile_phone_no,
      //   education_level: this.result.education_level,
      //   education_major: this.result.education_major,
      //   mariage_status:
      //     this.result.mariage_status !== null
      //       ? this.result.mariage_status.id
      //       : null,
      //   ptkp_status_id:
      //     this.result.ptkp_status !== null ? this.result.ptkp_status.id : null,
      //   npwp_no: this.result.npwp_no,
      //   gol: this.result.gol,
      //   employee_status_id:
      //     this.result.employee_status !== null
      //       ? this.result.employee_status.id
      //       : null,
      //   status: this.result.status,
      //   join_date: this.convertDate(this.result.join_date),
      //   contract_date: this.convertDate(this.result.contract_date),
      //   contract_month: this.result.contract_month,
      //   expire_contract_date: this.convertDate(
      //     this.result.expire_contract_date
      //   ),
      //   bpjs_no: this.result.bpjs_no,
      //   bpjs_tk_no: this.result.bpjs_tk_no,
      //   blood_type: this.result.blood_type,
      //   domicile_address: this.result.domicile_address,
      //   photo: xPhoto,
      //   fingerprint_id: this.result.fingerprint_id,
      //   // is_shift:
      //   //   this.result.employee_working_hour !== null
      //   //     ? this.result.employee_working_hour.is_shift
      //   //     : false
      //   is_shift: this.result.is_shift
      //   // last_attendance_status: this.result.last_attendance_status
      // }
      // console.log(newForm)
      this.$emit('changeTab', 1)
      // this.save(newForm)
    },

    // async save(form) {
    //   this.loading = true
    //   await axios
    //     .post(`${this.hrsApi}employee/save`, form)
    //     .then(res => {
    //       this.loading = false
    //       if (res.data.status_code !== '-99') {
    //         setTimeout(() => {
    //           this.$emit('cancel')
    //           return this.$emit('editForm')
    //         }, this.showMsgDialog('success', res.data.status_msg, false))
    //       } else {
    //         if (res.data.status_msg === 'Parameter value has problem') {
    //           return this.showMsgDialog(
    //             'error',
    //             'Invalid input, Parameter value has problem',
    //             false
    //           )
    //         } else {
    //           return this.showMsgDialog('error', res.data.status_msg, false)
    //         }
    //       }
    //     })
    //     .catch(err => {
    //       this.showMsgDialog(
    //         'error',
    //         err
    //           ? 'Something went wrong, Please contact an admin!'
    //           : 'Something went wrong, Please contact an admin!',
    //         false
    //       )
    //       return (this.loading = false)
    //     })
    // },
    getAge() {
      let str = ''
      // const now = new Date().toLocaleDateString('id')
      if (this.result.birth_date !== null || this.result.birth_date !== '') {
        // const birth = new Date(this.result.birth_date).toLocaleDateString('id')

        var diff_s = moment(this.result.birth_date).diff(
          moment(),
          'milliseconds'
        )
        var duration = moment.duration(diff_s)
        var year = duration._data.years.toString()
        str = year.replace('-', '')
      }
      return str
    },
    convertDate(val) {
      if (val !== null || val !== '') {
        const rawDate = new Date(val).toLocaleDateString('id').split('/')
        const y = rawDate[2]
        const m = rawDate[1].length === 2 ? rawDate[1] : '0' + rawDate[1]
        const d = rawDate[0].length === 2 ? rawDate[0] : '0' + rawDate[0]
        const newDate = `${y}-${m}-${d}`
        return newDate
      }
      return ''
    }
  }
}
</script>
