<template>
  <v-container
    :key="pageKey"
    fluid
    style="
      background: rgba(255, 255, 255, 0.9);
      min-width: 100%;
      min-height: 95vh;
      padding: 10px 20px;
      position: relative;
    "
  >
    <!-- <h6
      @click="back"
      style="border:1px solid rgba(0,0,0,0.3); border-radius:5px; position:absolute; width:90px; cursor:pointer;"
    >
      <v-icon style="position:relative; top:-1px;">mdi-chevron-left</v-icon
      >kembali
    </h6> -->
    <div v-if="detail !== null" style="margin-bottom: 65px">
      <h4 class="fpb-title">FORMULIR PERMINTAAN BARANG DAN JASA</h4>

      <p style="margin: auto 0; text-align: center">
        Doc.No.: {{ detail.request_no }}
        <v-tooltip bottom color="grey">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              text
              icon
              color="grey darken-2"
              @click="loadPage"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>refresh page</span>
        </v-tooltip>
      </p>
      <div style="width: 100%; margin-top: 40px">
        <div>
          <div
            class="d-flex justify-start align-start"
            style="position: relative; top: -10px"
          >
            <!-- <v-card elevation="1" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="mb-2" style="position: relative; width: 120px">
                    <span class="text-overline"> Total Item</span>
                    <div
                      style="
                        font-size: 12px;
                        position: absolute;
                        top: 25px;
                        left: 0;
                      "
                    >
                      (dengan budget terisi)
                    </div>
                  </div>
                  <v-list-item-title class="text-h5">
                    {{ detail.total_item_with_budget }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card elevation="1" outlined style="margin-left: 15px">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-2">Total Budget</div>
                  <v-list-item-title class="text-h5">
                    {{ price(detail.total_price) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card elevation="1" outlined style="margin-left: 15px">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-2">Total Realisasi</div>
                  <v-list-item-title
                    class="text-h5 d-flex justify-start align-end"
                    style="position: relative"
                  >
                    <div>{{ percentConvert(detail) }}%</div>
                    <div style="font-size: 12px; margin-left: 5px">
                      ({{ price(detail.total_realization) }}/{{
                        price(detail.total_price)
                      }})
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card> -->
            <v-spacer></v-spacer>
            <div class="actionButton">
              <!-- Admin -->

              <div
                v-if="
                  getUserProfile.level.find(
                    ({ id }) =>
                      id === '1' || id === '3' || id === '26' || id === '30'
                  ) !== undefined
                "
              >
                <!-- <v-btn
                  v-if="detail.status.id === 2"
                  type="button"
                  elevation="0"
                  color="primary"
                  class="white--text font-weight-bold ma-1"
                  style="font-size: 12px"
                  :rounded="wWidth < 809"
                  @click.prevent="openFormGr"
                  :loading="loading"
                  :disabled="loading"
                  small
                >
                  Buat Penerimaan
                </v-btn> -->
                <div v-if="getUserProfile.employee.id === detail.employee.id">
                  <v-btn
                    v-if="detail.status.id === 0 && isEdit"
                    type="button"
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold ma-1"
                    style="font-size: 12px"
                    @click.prevent="saveChange"
                    :loading="loading"
                    small
                    :rounded="wWidth < 809"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    v-if="detail.status.id === 0 && !isEdit"
                    type="button"
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold ma-1"
                    style="font-size: 12px"
                    @click.prevent="editDetailFPB"
                    :loading="loading"
                    small
                    :rounded="wWidth < 809"
                  >
                    Edit
                  </v-btn>
                  <v-btn
                    v-if="detail.status.id === 0 && !isEdit"
                    type="button"
                    elevation="0"
                    color="green"
                    class="white--text font-weight-bold ma-1"
                    style="font-size: 12px"
                    @click.prevent="checkBeforeSubmitFpb"
                    :loading="loading"
                    :disabled="loading"
                    small
                    :rounded="wWidth < 809"
                  >
                    Submit FPB
                  </v-btn>
                  <v-btn
                    v-if="detail.status.id === -1 || detail.status.id === 4"
                    type="button"
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold ma-1"
                    style="font-size: 12px"
                    @click.prevent="setToDraft"
                    :loading="loading"
                    :disabled="loading"
                    small
                    :rounded="wWidth < 809"
                  >
                    Set to Draft
                  </v-btn>

                  <v-btn
                    v-if="detail.status.id === 0 && !isEdit"
                    type="button"
                    elevation="0"
                    color="pink"
                    class="white--text font-weight-bold ma-1"
                    style="font-size: 12px"
                    @click.prevent="cancelFpb"
                    :loading="loading"
                    :disabled="loading"
                    small
                    :rounded="wWidth < 809"
                  >
                    Cancel FPB
                  </v-btn>
                  <v-btn
                    v-if="isEdit"
                    type="button"
                    elevation="0"
                    color="pink"
                    class="white--text font-weight-bold ma-1"
                    style="font-size: 12px"
                    @click.prevent="isEdit = !isEdit"
                    :loading="loading"
                    :disabled="loading"
                    small
                    :rounded="wWidth < 809"
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                    v-if="detail.status.id === 2"
                    type="button"
                    elevation="0"
                    color="primary"
                    class="white--text font-weight-bold ma-1"
                    style="font-size: 12px"
                    @click.prevent="closeFpb"
                    :loading="loading"
                    small
                    :rounded="wWidth < 809"
                  >
                    Close FPB
                  </v-btn>
                  <v-btn
                    v-if="detail.status.id === 2"
                    type="button"
                    elevation="0"
                    color="pink"
                    class="white--text font-weight-bold ma-1"
                    style="font-size: 12px"
                    @click.prevent="cancelFpb"
                    :loading="loading"
                    :disabled="loading"
                    small
                    :rounded="wWidth < 809"
                  >
                    Cancel FPB
                  </v-btn>
                </div>
                <div v-if="isAllow">
                  <div v-if="detail.status.id === 1">
                    <div
                      v-if="
                        detail.approver_users.find(
                          (e) => e === getUserProfile.employee.email
                        ) === undefined
                      "
                    >
                      <v-btn
                        type="button"
                        elevation="0"
                        color="green"
                        class="white--text font-weight-bold ma-1"
                        style="font-size: 12px"
                        @click.prevent="confirmFPB"
                        :loading="loading"
                        :disabled="loading"
                        small
                        :rounded="wWidth < 809"
                      >
                        Confirm
                      </v-btn>
                      <v-btn
                        type="button"
                        elevation="0"
                        color="pink"
                        class="white--text font-weight-bold ma-1"
                        style="font-size: 12px"
                        @click.prevent="rejectFPB"
                        :loading="loading"
                        :disabled="loading"
                        small
                        :rounded="wWidth < 809"
                      >
                        Reject
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    getUserProfile.level.find(({ id }) => id === '3') !==
                    undefined
                  "
                  class="d-flex"
                >
                  <div v-if="detail.status.id === 5" class="d-flex">
                    <v-btn
                      type="button"
                      elevation="0"
                      color="grey"
                      class="white--text font-weight-bold ma-1"
                      style="font-size: 12px"
                      @click.prevent="setToDraft"
                      :loading="loading"
                      :disabled="loading"
                      small
                      :rounded="wWidth < 809"
                    >
                      Set Draft
                    </v-btn>
                    <v-btn
                      type="button"
                      elevation="0"
                      color="green"
                      class="white--text font-weight-bold ma-1"
                      style="font-size: 12px"
                      @click.prevent="takeFPB"
                      :loading="loading"
                      :disabled="loading"
                      small
                      :rounded="wWidth < 809"
                    >
                      Take FPB
                    </v-btn>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-end">
                <p
                  :style="`background-color: ${statusColor(detail.status.id)};
                color: white;
                font-weight: bold;
                margin: 5px 0 0 0;
                padding: 0 10px;
                border: 1px solid rgba(0, 0, 0, 0.6);
                width: 180px;
                text-align: center;`"
                >
                  {{ statusName(detail.status.id) }}
                </p>
              </div>
            </div>
          </div>
          <v-row no-gutters class="category-line">
            <v-col cols="6" md="3" style="padding: 5px">
              <div style="height: 45px">
                <v-select
                  label="Project"
                  style="
                    position: relative;
                    top: 15px;
                    margin: 0 15px 0 0;
                    font-size: 14px;
                    width: 100%;
                    height: 40px;
                  "
                  v-model="odoo_project"
                  :items="dropdownProject"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  :readonly="!isEdit"
                  :outlined="isEdit"
                  :clearable="isEdit"
                  @change="projectWatcher"
                ></v-select>
              </div>
            </v-col>
            <!-- <div
              class="category-line-item"
              :style="`width:${
                wWidth < 400 ? '120px' : '160px'
              };margin-right:20px;`"
            >
              <v-select
                label="RAB"
                style="
                  position: relative;
                  top: 15px;
                  margin: 0 15px 0 0;
                  font-size: 14px;
                  width: 220px;
                  height: 40px;
                "
                v-model="budget_plan_id"
                :items="dropdownRAB"
                item-text="name"
                item-value="id"
                :outlined="isEdit"
                return-id
                dense
                readonly
              ></v-select>
            </div> -->

            <v-col cols="6" md="3" style="padding: 5px">
              <div style="height: 45px">
                <v-select
                  label="Kategori Barang *"
                  style="
                    position: relative;
                    top: 15px;
                    margin: 0 15px 0 0;
                    font-size: 14px;
                    width: 260px;
                    height: 40px;
                  "
                  v-model="category_item"
                  :items="dropdownCategoryItem"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  :outlined="isEdit"
                  :readonly="!isEdit"
                ></v-select>
              </div>
            </v-col>

            <v-col cols="6" md="3" style="padding: 5px">
              <div style="height: 45px">
                <v-select
                  label="Kategori PR *"
                  style="
                    position: relative;
                    top: 15px;
                    margin: 0;
                    font-size: 14px;
                    height: 40px;
                  "
                  v-model="category_pr"
                  :items="[
                    { id: 'bahan_baku', name: 'Bahan Baku' },
                    { id: 'bahan_pendukung', name: 'Bahan Pendukung' },
                    { id: 'asset', name: 'Asset' },
                    { id: 'barang_khusus', name: 'Barang Khusus' },
                    { id: 'operasional', name: 'Operasional' },
                    { id: 'project', name: 'Project' },
                    { id: 'lain', name: 'Lain-lain' }
                  ]"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  :outlined="isEdit"
                  :readonly="!isEdit"
                ></v-select>
              </div>
            </v-col>

            <v-col cols="6" md="3" style="padding: 5px">
              <div style="height: 45px">
                <v-select
                  label="Tipe proses barang *"
                  style="
                    position: relative;
                    top: 15px;
                    margin: 0;
                    font-size: 14px;
                    height: 40px;
                  "
                  v-model="detail.fpb_type"
                  :items="[
                    { id: 1, name: 'CA' },
                    { id: 3, name: 'MIX' }
                  ]"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  :clearable="isEdit"
                  :outlined="isEdit"
                  :readonly="!isEdit"
                ></v-select>
              </div>
            </v-col>
          </v-row>
          <div class="fpb-status-section">
            <!-- <div class="d-flex "> -->
            <v-row no-gutters>
              <v-col cols="6" md="3" style="padding: 5px">
                <div style="height: 45px">
                  <v-text-field
                    :value="detail.employee.name"
                    label="User"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </div>
              </v-col>
              <v-col cols="6" md="3" style="padding: 5px">
                <div style="height: 45px">
                  <v-text-field
                    :value="detail.company.code"
                    label="Company"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </div>
              </v-col>
              <v-col cols="6" md="3" style="padding: 5px">
                <div style="height: 45px">
                  <v-text-field
                    :value="detail.department.name"
                    label="Department"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </div>
              </v-col>
              <v-col cols="6" md="3" style="padding: 5px">
                <div style="height: 45px">
                  <v-text-field
                    :value="detail.created_at"
                    label="Tgl. Buat"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </div>
              </v-col>
              <!-- <v-col
                v-if="
                  (detail.status.id == 0 || detail.status.id == 1) &&
                  getUserProfile != null &&
                  getUserProfile.employee != null &&
                  getUserProfile.employee.department != null &&
                  (getUserProfile.employee.department.id == 3 ||
                    getUserProfile.employee.department.id == 9 ||
                    getUserProfile.employee.department.id == 59)
                "
                cols="12"
                style="padding: 5px"
              >
                <div style="height: 45px">
                  <v-autocomplete
                    v-model="approval_matrix_id"
                    :items="dropdownApprovalMatrix"
                    :search-input.sync="approvalSearch"
                    style="margin: 0"
                    label="Pilih Approval"
                    placeholder="kosongkan jika tidak ingin merubah approval"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    hide-details
                    hide-no-data
                    clearable
                  ></v-autocomplete>
                </div>
              </v-col> -->
              <!-- <v-col
                cols="6"
                md="1"
                style="padding: 5px"
                class="d-flex justify-center align-center"
              >
                <div style="height: 45px">
                  <div
                    style="color: #1976d2; font-weight: bold"
                    v-if="detail.status.id === 0"
                  >
                    DRAFT
                  </div>
                  <div
                    style="color: #1976d2; font-weight: bold"
                    v-if="detail.status.id === 1"
                  >
                    WAITING APPROVAL
                  </div>
                  <div
                    style="color: #1976d2; font-weight: bold"
                    v-if="detail.status.id === 2"
                  >
                    IN PROGRESS
                  </div>
                  <div
                    style="color: #4caf50; font-weight: bold"
                    v-if="detail.status.id === 3"
                  >
                    CLOSE
                  </div>
                  <div
                    style="color: red; font-weight: bold"
                    v-if="detail.status.id === 4"
                  >
                    CANCELED
                  </div>
                  <div
                    style="color: red; font-weight: bold"
                    v-if="detail.status.id === -1"
                  >
                    REJECTED
                  </div>
                  <div
                    style="color: #1976d2; font-weight: bold"
                    v-if="detail.status.id === 5"
                  >
                    PENDING
                  </div>
                </div>
              </v-col> -->
            </v-row>
          </div>
          <div class="d-flex flex-wrap mt-1">
            <div
              class="d-flex"
              v-if="
                detail.took_by_name !== undefined &&
                detail.took_at !== undefined
              "
            >
              <div
                class="d-flex mt-1"
                v-if="detail.took_by_name !== null && detail.took_at !== null"
              >
                <div class="d-flex">
                  <p
                    style="
                      width: 110px;
                      margin: auto 0;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>Diproses Oleh</span> <span>:</span>
                  </p>
                  <p
                    style="
                      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
                      padding: 0 10px;
                      margin: auto 0;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>{{ detail.took_by_name }}</span>
                  </p>
                </div>
                <div class="d-flex" style="margin: 0 50px 0 50px">
                  <p
                    style="
                      width: 90px;
                      margin: auto 0;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span> Tgl. Proses</span>
                    <span style="font-weight: bolder; margin-left: 5px">:</span>
                  </p>
                  <p
                    style="
                      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
                      padding: 0 10px;
                      margin: auto 0;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>{{ detail.took_at }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="tipe-proses d-flex" v-if="detail.status.id != 0">
              <p
                style="
                  width: 100px;
                  margin: auto 0;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Tipe Proses</span>
                <span style="font-weight: bolder; margin-left: 5px">:</span>
              </p>

              <p
                v-if="detail !== null"
                style="
                  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
                  padding: 0 10px;
                  margin: auto 0;
                  display: flex;
                  justify-content: space-between;

                  font-weight: bold;
                "
              >
                <span v-if="detail.fpb_type == 1">CA</span>
                <span v-if="detail.fpb_type == 2">PO</span>
                <span v-if="detail.fpb_type == 3">MIX</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <v-card v-if="detail != null" style="margin-top: 40px">
        <v-tabs
          v-model="tab"
          background-color="rgb(240,240,240)"
          slider-color="primary"
          color="primary"
        >
          <v-tab v-for="item in tabItems" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in tabItems"
            :key="item.tab"
            style="padding: 20px"
          >
            <div style="min-height: 60vh">
              <div v-if="item.tab == 'Detail Barang'">
                <!-- DETAIL BARANG -->
                <div style="margin-top: 5px; position: relative">
                  <v-data-table
                    v-model="selectedData"
                    height="50vh"
                    mobile-breakpoint="0"
                    class="elevation-1"
                    style="
                      cursor: pointer;
                      border: 1px solid rgba(0, 0, 0, 0.2);
                    "
                    :headers="headers"
                    :items="detail.purchase_request_detail"
                    :items-per-page="10"
                    :loading="loading"
                    @click:row="rowClick"
                    :show-select="
                      getUserProfile.level.find(({ id }) => id === '41') ===
                        undefined &&
                      (detail.status.id === 0 || detail.status.id === 2)
                    "
                    :search="searchItem"
                    item-key="id"
                    :dense="wWidth > 808 ? true : false"
                    :custom-filter="customSearch"
                    :options.sync="options"
                  >
                    <template v-slot:top>
                      <div
                        style="
                          border: thin solid rgba(0, 0, 0, 0.12);
                          cursor: auto;
                          padding: 0 15px;
                          height: 75px;
                          display: flex;
                          overflow: hidden;
                        "
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            margin: 0 15px 0 5px;
                          "
                        >
                          <v-btn
                            v-if="
                              getUserProfile.level.find(
                                ({ id }) => id === '41'
                              ) === undefined
                            "
                            style="font-size: 10px"
                            @click="addItem"
                            elevation="2"
                            tilerounded="5px"
                            x-small
                            color="primary"
                            class="white--text"
                            :disabled="detail.status.id !== 0"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small class="">
                                  mdi-plus
                                </v-icon>
                              </template>
                              <span>Tambah item</span>
                            </v-tooltip>
                          </v-btn>
                        </div>
                        <div style="width: 120px">
                          <v-select
                            :disabled="selectedData.length === 0"
                            label="Action"
                            style="
                              position: relative;
                              top: 15px;
                              margin: 0 15px 0 5px;
                              font-size: 12px;
                            "
                            v-model="actionValue"
                            :items="itemsAction"
                            item-text="name"
                            item-value="id"
                            outlined
                            return-id
                            dense
                            @change="actionSelect"
                          ></v-select>
                        </div>
                        <div
                          style="
                            width: 260px;
                            position: relative;
                            top: 15px;
                            margin: 0 15px 0 5px;
                            font-size: 12px;
                            height: 45px;
                          "
                        >
                          <v-text-field
                            v-model="searchItem"
                            label="Cari barang"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                        <v-spacer></v-spacer>
                        <div style="display: flex; align-items: center">
                          <v-btn
                            v-if="
                              getUserProfile.level.find(
                                ({ id }) => id === '41'
                              ) === undefined
                            "
                            elevation="0"
                            color="transparent"
                            small
                            style="margin-right: 10px"
                            :loading="loading"
                            @click="sendItemEqualizationNotification(null)"
                          >
                            <v-icon
                              color="primary"
                              small
                              style="margin-right: 5px"
                              >mdi-invoice-text-send-outline</v-icon
                            >
                            <a href="#" style="font-weight: bold"
                              >Lapor Penyesuaian</a
                            >
                          </v-btn>
                          <v-btn
                            v-if="
                              getUserProfile.level.find(
                                ({ id }) => id === '41'
                              ) === undefined
                            "
                            elevation="0"
                            color="transparent"
                            small
                            outlined
                            style="margin-right: 10px"
                            :loading="loading"
                            @click="resyncFpbItem"
                          >
                            <v-icon
                              color="primary"
                              small
                              style="margin-right: 5px"
                              >mdi-sync</v-icon
                            >
                            <a href="#" style="font-weight: bold"
                              >Refresh Item</a
                            >
                          </v-btn>
                          <v-btn
                            elevation="0"
                            color="transparent"
                            small
                            outlined
                            style="margin-right: 10px"
                            :loading="loading"
                            @click="downloadDokumen"
                          >
                            <v-icon
                              color="primary"
                              small
                              style="margin-right: 5px"
                              >mdi-file-outline</v-icon
                            >
                            <a href="#" style="font-weight: bold"
                              >Lihat Dokumen</a
                            >
                          </v-btn>
                          <v-btn
                            elevation="0"
                            color="transparent"
                            small
                            outlined
                            style="margin-right: 10px"
                            :loading="loading"
                            @click="generatePdfFPB"
                          >
                            <v-icon
                              color="primary"
                              small
                              style="margin-right: 5px"
                              >mdi-magnify
                            </v-icon>
                            <a href="#" style="font-weight: bold">Lihat FPB</a>
                          </v-btn>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.no`]="{ index }">
                      <div style="width: 35px; font-size: 12px">
                        {{ index + 1 }}
                      </div>
                    </template>
                    <template
                      v-slot:[`item.is_item_match_with_odoo`]="{ item }"
                    >
                      <!-- <div v-if="detail.status.id === 0" style="width: 45px"> -->
                      <div style="width: 65px">
                        <div v-if="item.is_item_match_with_odoo == 1">
                          <v-tooltip bottom color="green">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="checkItem(item)"
                                small
                                color="success"
                              >
                                mdi-check-circle-outline
                              </v-icon>
                            </template>
                            <span>{{ 'Item Match' }}</span>
                          </v-tooltip>
                        </div>
                        <v-tooltip v-else bottom color="warning">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              small
                              color="warning"
                              @click.stop="checkItem(item)"
                              >mdi-close-circle-outline
                            </v-icon>
                          </template>
                          <span>Item not match</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <template v-slot:[`item.check_result`]="{ item }">
                      <div style="width: 45px">
                        <div v-if="item.check_result != undefined">
                          <v-tooltip
                            v-if="
                              item.check_result.status == '-99' ||
                              item.is_item_match_with_odoo == 0
                            "
                            bottom
                            color="warning"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="checkItem(item)"
                                small
                                color="warning"
                                >mdi-close-circle-outline
                              </v-icon>
                            </template>
                            <span
                              v-if="
                                detail.project == null &&
                                item.product.code == null &&
                                item.is_item_match_with_odoo == 0
                              "
                              >Code not found</span
                            >
                            <span v-else>{{ item.check_result.message }}</span>
                          </v-tooltip>

                          <v-tooltip
                            v-if="
                              item.check_result.status == '00' &&
                              item.is_item_match_with_odoo == 1
                            "
                            bottom
                            color="green"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click.stop="checkItem(item)"
                                small
                                color="success"
                              >
                                mdi-check-circle-outline
                              </v-icon>
                            </template>
                            <span>{{ item.check_result.message }}</span>
                          </v-tooltip>
                        </div>

                        <div v-else>
                          <div v-if="item.is_item_match_with_odoo == 1">
                            <v-tooltip bottom color="green">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop="checkItem(item)"
                                  small
                                  color="success"
                                >
                                  mdi-check-circle-outline
                                </v-icon>
                              </template>
                              <span>{{ 'Item Match' }}</span>
                            </v-tooltip>
                          </div>
                          <v-tooltip v-else bottom color="warning">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="warning"
                                >mdi-close-circle-outline
                              </v-icon>
                            </template>
                            <span>Item not match</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.product.name`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            style="
                              text-align: left;
                              width: 250px;
                              font-size: 12px;
                              padding: 0;
                            "
                          >
                            {{
                              `${
                                item.product.code !== null
                                  ? '(' + item.product.code + ')'
                                  : ''
                              } ${item.product.name}`
                            }}
                          </div>
                        </template>
                        <span>
                          {{
                            `${
                              item.product.code !== null
                                ? '(' + item.product.code + ')'
                                : ''
                            } ${item.product.name}`
                          }}</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.vendor`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            style="
                              text-align: left;
                              width: 200px;
                              font-size: 12px;
                              padding: 0;
                            "
                          >
                            {{
                              item.vendor !== null
                                ? `${
                                    item.vendor.code !== null
                                      ? '(' + item.vendor.code + ')'
                                      : ''
                                  } ${
                                    item.vendor.name !== null
                                      ? '(' + item.vendor.name + ')'
                                      : ''
                                  }`
                                : ''
                            }}
                          </div>
                        </template>
                        <span>{{
                          item.vendor !== null
                            ? `${
                                item.vendor.code !== null
                                  ? '(' + item.vendor.code + ')'
                                  : ''
                              } ${
                                item.vendor.name !== null
                                  ? '(' + item.vendor.name + ')'
                                  : ''
                              }`
                            : ''
                        }}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:[`item.uom`]="{ item }">
                      <div style="font-size: 12px">
                        {{ item.uom }}
                      </div>
                    </template>
                    <template v-slot:[`item.budget_price_per_unit`]="{ item }">
                      <div style="width: 95px; font-size: 12px">
                        {{ price(item.budget_price_per_unit) }}
                      </div>
                    </template>
                    <template v-slot:[`item.budget_price_total`]="{ item }">
                      <div style="width: 135px; font-size: 12px">
                        {{ price(item.budget_price_total) }}
                      </div>
                    </template>
                    <template
                      v-slot:[`item.quotation_price_per_unit`]="{ item }"
                    >
                      <div style="width: 95px; font-size: 12px">
                        {{ price(item.quotation_price_per_unit) }}
                      </div>
                    </template>
                    <template v-slot:[`item.last_price`]="{ item }">
                      <div style="width: 120px; font-size: 12px">
                        {{ price(item.last_price) }}
                      </div>
                    </template>
                    <template v-slot:[`item.qty`]="{ item }">
                      <div style="font-size: 12px">
                        {{ item.qty || 0 }}
                      </div>
                    </template>
                    <template v-slot:[`item.qty_done`]="{ item }">
                      <div style="font-size: 12px">
                        {{ item.qty_done || 0 }}
                      </div>
                    </template>
                    <template v-slot:[`item.qty_paid`]="{ item }">
                      <div style="font-size: 12px">
                        {{ item.qty_paid || 0 }}
                      </div>
                    </template>
                    <!-- <template v-slot:[`item.realization`]="{ item }">
                      <div style="width: 120px">
                        {{ price(item.realization) }}
                      </div>
                    </template> -->
                    <template v-slot:[`item.pr_no`]="{ item }">
                      <div style="width: 125px; font-size: 12px">
                        {{ item.pr_no !== null ? item.pr_no : '-' }}
                      </div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div style="width: 75px; font-size: 12px">
                        <v-tooltip
                          bottom
                          v-if="item.status.id === 0"
                          @click.prevent
                        >
                          <template v-slot:activator="{ attrs, on }">
                            <td
                              v-bind="attrs"
                              v-on="on"
                              style="
                                border: 0.5px solid rga(0, 0, 0, 0.8);
                                font-size: 12px;
                                border-radius: 50%;
                                height: 22px;
                                width: 22px;
                                color: white;
                                font-weight: bold;
                                background-color: rgba(0, 0, 0, 0.8);
                              "
                              class="d-flex justify-center align-center"
                            >
                              D
                            </td>
                          </template>
                          <span>{{ item.status.name }}</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          v-if="item.status.id === 1"
                          @click.prevent
                        >
                          <template v-slot:activator="{ attrs, on }">
                            <td
                              v-bind="attrs"
                              v-on="on"
                              style="
                                border: 0.5px solid orange;
                                font-size: 12px;
                                border-radius: 50%;
                                height: 22px;
                                width: 22px;
                                color: white;
                                font-weight: bold;
                                background-color: orange;
                              "
                              class="d-flex justify-center align-center"
                            >
                              W
                            </td>
                          </template>
                          <span>{{ item.status.name }}</span>
                        </v-tooltip>
                        <div
                          class="d-flex"
                          v-if="item.status.id === 2"
                          @click.prevent
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                              <td
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  border: 0.5px solid blue;
                                  font-size: 12px;
                                  border-radius: 50%;
                                  height: 22px;
                                  width: 22px;
                                  color: white;
                                  font-weight: bold;
                                  background-color: blue;
                                "
                                class="d-flex justify-center align-center"
                              >
                                PR
                              </td>
                            </template>
                            <span>{{ item.status.name }}</span>
                          </v-tooltip>
                          <td
                            v-if="item.is_po_created"
                            style="
                              margin-left: 5px;
                              border: 0.5px solid blue;
                              font-size: 12px;
                              border-radius: 50%;
                              height: 22px;
                              width: 22px;
                              color: white;
                              font-weight: bold;
                              background-color: blue;
                            "
                            class="d-flex justify-center align-center"
                          >
                            PO
                          </td>
                        </div>
                        <v-tooltip bottom v-if="item.status.id === 3">
                          <template v-slot:activator="{ attrs, on }">
                            <td
                              v-bind="attrs"
                              v-on="on"
                              style="
                                border: 0.5px solid blue;
                                font-size: 12px;
                                border-radius: 50%;
                                height: 22px;
                                width: 22px;
                                color: white;
                                font-weight: bold;
                                background-color: blue;
                              "
                              class="d-flex justify-center align-center"
                            >
                              CA
                            </td>
                          </template>
                          <span>{{ item.status.name }}</span>
                        </v-tooltip>
                        <div class="d-flex" v-if="item.status.id === 4">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                              <td
                                v-bind="attrs"
                                v-on="on"
                                style="
                                  border: 0.5px solid green;
                                  font-size: 12px;
                                  border-radius: 50%;
                                  height: 22px;
                                  width: 22px;
                                  color: white;
                                  font-weight: bold;
                                  background-color: green;
                                "
                                class="d-flex justify-center align-center"
                              >
                                CL
                              </td>
                            </template>
                            <span>{{ item.status.name }}</span>
                          </v-tooltip>
                          <!-- <td
                v-if="item.is_po_created"
                style="margin-left:5px; border:0.5px solid blue; font-size:12px; border-radius:50%; height:22px; width:22px; color:white; font-weight:bold; background-color:blue;"
                class="d-flex justify-center align-center"
              >
                PO
              </td> -->
                        </div>

                        <td
                          v-if="item.status.id === -1"
                          style="
                            border: 0.5px solid red;
                            font-size: 12px;
                            border-radius: 50%;
                            height: 22px;
                            width: 22px;
                            color: white;
                            font-weight: bold;
                            background-color: red;
                          "
                          class="d-flex justify-center align-center"
                        >
                          R
                        </td>

                        <v-tooltip bottom v-if="item.status.id === 5">
                          <template v-slot:activator="{ attrs, on }">
                            <td
                              v-bind="attrs"
                              v-on="on"
                              style="
                                border: 0.5px solid red;
                                font-size: 12px;
                                border-radius: 50%;
                                height: 22px;
                                width: 22px;
                                color: white;
                                font-weight: bold;
                                background-color: red;
                              "
                              class="d-flex justify-center align-center"
                            >
                              C
                            </td>
                          </template>
                          <span>{{ item.status.name }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                </div>
                <div class="items-total-div-1">
                  <div class="items-total-div-3">
                    <p>Harap mengisi keterangan dengan lengkap dan jelas</p>
                    <p>Data pendukung:</p>

                    <div style="display: flex">
                      <p
                        :style="`color:${
                          detail.reference_from_ecommerce === 1
                            ? 'green'
                            : 'red'
                        };`"
                      >
                        <span style="margin-right: 10px">(1)</span> Referensi
                        penawaran vendor atau referensi harga barang dari
                        e-commerce
                      </p>
                      <v-checkbox
                        v-model="detail.reference_from_ecommerce"
                        :true-value="1"
                        :false-value="0"
                        :color="
                          detail.reference_from_ecommerce === 1
                            ? 'green'
                            : 'red'
                        "
                        :readonly="!isEdit"
                        style="margin: 0 0 0 10px; height: 25px; padding: 0"
                        :disabled="
                          getUserProfile.level.find(({ id }) => id === '41') !==
                          undefined
                        "
                      ></v-checkbox>
                    </div>

                    <div style="display: flex">
                      <p
                        :style="`color:${
                          detail.budget_is_approved === 1 ? 'green' : 'red'
                        };`"
                      >
                        <span style="margin-right: 10px">(2)</span> Budget yang
                        sudah di approve
                      </p>
                      <v-checkbox
                        v-model="detail.budget_is_approved"
                        :true-value="1"
                        :false-value="0"
                        :color="
                          detail.budget_is_approved === 1 ? 'green' : 'red'
                        "
                        :readonly="!isEdit"
                        style="margin: 0 0 0 10px; height: 25px; padding: 0"
                        :disabled="
                          getUserProfile.level.find(({ id }) => id === '41') !==
                          undefined
                        "
                      ></v-checkbox>
                    </div>
                    <div style="display: flex">
                      <p
                        :style="`color:${
                          detail.memo_special_request === 1 ? 'green' : 'red'
                        };`"
                      >
                        <span style="margin-right: 10px">(3)</span> Memo atau
                        Berita Acara dengan permintaan kondisi khusus
                      </p>
                      <v-checkbox
                        v-model="detail.memo_special_request"
                        :true-value="1"
                        :false-value="0"
                        :color="
                          detail.memo_special_request === 1 ? 'green' : 'red'
                        "
                        :readonly="!isEdit"
                        style="margin: 0 0 0 10px; height: 25px; padding: 0"
                        :disabled="
                          getUserProfile.level.find(({ id }) => id === '41') !==
                          undefined
                        "
                      ></v-checkbox>
                    </div>
                    <p>
                      <span style="margin-right: 10px">*</span>
                      Pembelian kategori barang
                      <span style="font-weight: bold"
                        >"Investment / Asset"</span
                      >
                      wajib diketahui oleh
                      <span style="font-weight: bold"
                        >Finance & Accounting</span
                      >
                      dan Approval
                      <span style="font-weight: bold">Direktur</span>
                    </p>
                    <p></p>
                  </div>
                  <div class="items-total-div-2 mr-10">
                    <v-card elevation="1" class="items-total-box">
                      <p
                        style="margin-right: auto; font-weight: bold; margin: 0"
                      >
                        SUMMARY ITEM & HARGA BUDGET
                      </p>
                      <div>
                        <p>Total Qty<span>:</span></p>
                        <p style="text-align: right; width: 100%">
                          {{ Math.round(detail.total_qty * 1000) / 1000 }}
                        </p>
                      </div>
                      <div>
                        <p>Total Harga <span>:</span></p>
                        <p style="text-align: right; width: 100%">
                          {{ price(detail.total_price) }}
                        </p>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
              <div v-if="item.tab == 'Approval'">
                <!-- APPROVAL -->
                <div v-if="detail !== null" style="margin-top: 5px">
                  <v-row
                    no-gutters
                    style="padding: 0"
                    v-if="detail.approval_matrix !== null"
                  >
                    <v-col md="12" style="padding: 0">
                      <!-- :loading="matrix.length === 0" -->
                      <!-- <div
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          margin-bottom: 10px;
                          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
                        "
                      >
                        APPROVAL MATRIX
                      </div> -->
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="approvalHeaders"
                        :items="detail.approval_matrix"
                        :items-per-page="10"
                        class="elevation-1 mx-auto"
                        hide-default-footer
                      >
                        <template v-slot:[`item.document_no`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.document_no }}
                          </div>
                        </template>
                        <template v-slot:[`item.table_name`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.table_name }}
                          </div>
                        </template>
                        <template v-slot:[`item.approver_user`]="{ item }">
                          <div style="cursor: pointer" v-if="item">
                            <v-chip
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                              v-for="(item, index) in item.approver_user"
                              :key="index"
                            >
                              {{ item.user !== null ? item.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.approved`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px"
                          >
                            <v-chip
                              v-if="items.status === 1"
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                            >
                              {{ item.user !== null ? items.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.rejected`]="{ item }">
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px"
                          >
                            <v-chip
                              v-if="items.status === -1"
                              label
                              outlined
                              small
                              style="
                                margin-right: 5px;
                                padding: 0 3px;
                                cursor: pointer;
                                border: 1px solid rgba(0, 0, 0, 0.5);
                              "
                            >
                              {{ item.user !== null ? items.user.name : '-' }}
                            </v-chip>
                          </div>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                          <div style="width: 140px; font-size: 12px">
                            {{ item.created_at }}
                          </div>
                        </template>
                        <template v-slot:[`item.updated_at`]="{ item }">
                          <!-- <div style="width:140px; fontSize:12px;">
                  {{ item.approver_user[item.approver_user.length -1]. }}
                </div> -->
                          <div
                            v-for="(items, index) in item.approver_user"
                            :key="index"
                            style="width: 140px; font-size: 12px"
                          >
                            <span v-if="items.status === 1">
                              {{
                                items.updated_at !== null
                                  ? items.updated_at
                                  : '-'
                              }}
                            </span>
                            <br v-if="index > 0" />
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <div
                    v-if="
                      getUserProfile.level.find(({ id }) => id === '41') ===
                      undefined
                    "
                  >
                    <div
                      style="text-align: left; padding-left: 5px"
                      v-if="detail.status.id === 1"
                    >
                      <v-btn
                        v-if="
                          getUserProfile.level.find(
                            ({ id }) => id === '1' || id === '26' || id === '30'
                          ) !== undefined
                        "
                        color="grey"
                        tile
                        dark
                        small
                        dense
                        style="font-size: 10px; width: 100px"
                        @click="checkBeforeFetch"
                        :loading="loading"
                      >
                        <span>Fetch Matrix</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div v-if="detail !== null" style="margin-top: 20px">
                  <v-row
                    no-gutters
                    style="padding: 0"
                    v-if="detail.status.id === -1"
                  >
                    <v-col md="12" style="padding: 0">
                      <!-- :loading="matrix.length === 0" -->
                      <div
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          margin-bottom: 5px;
                          font-size: 12px;
                        "
                      >
                        REJECT REASON
                      </div>
                      <div style="padding: 0 3px 0 5px; margin: 0">
                        <v-textarea
                          :value="detail.reject_reason"
                          dense
                          :auto-grow="true"
                          rows="1"
                          clear-icon="mdi-close-circle"
                        ></v-textarea>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    style="padding: 0"
                    v-if="detail.status.id === 3"
                  >
                    <v-col md="12" style="padding: 0">
                      <!-- :loading="matrix.length === 0" -->
                      <div
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          margin-bottom: 5px;
                          font-size: 12px;
                        "
                      >
                        CLOSE NOTE
                      </div>
                      <div style="padding: 0 3px 0 5px; margin: 0">
                        <v-textarea
                          :value="detail.closed_reason"
                          dense
                          :auto-grow="true"
                          rows="1"
                          clear-icon="mdi-close-circle"
                        ></v-textarea>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    style="padding: 0"
                    v-if="detail.status.id === 4"
                  >
                    <v-col md="12" style="padding: 0">
                      <!-- :loading="matrix.length === 0" -->
                      <div
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          margin-bottom: 5px;
                          font-size: 12px;
                        "
                      >
                        CANCEL REASON
                      </div>
                      <div style="padding: 0 3px 0 5px; margin: 0">
                        <v-textarea
                          :value="detail.cancel_reason"
                          dense
                          :auto-grow="true"
                          rows="1"
                          clear-icon="mdi-close-circle"
                        ></v-textarea>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="item.tab == 'Payment Request'">
                <div style="margin-top: 5px; position: relative">
                  <v-data-table
                    height="50vh"
                    mobile-breakpoint="0"
                    class="elevation-1"
                    fixed-header
                    :headers="payreqHeaders"
                    style="cursor: pointer"
                    :items="payreqResult"
                    :loading="loading"
                    @click:row="rowClickPayreq"
                  >
                    <template v-slot:[`item.document_no`]="{ item }">
                      <div
                        :style="`min-width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.document_no }}
                      </div>
                    </template>
                    <template v-slot:[`item.company_name`]="{ item }">
                      <div
                        :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.company_name }}
                      </div>
                    </template>
                    <template v-slot:[`item.department_name`]="{ item }">
                      <div
                        :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.department_name }}
                      </div>
                    </template>
                    <template v-slot:[`item.vendor_name`]="{ item }">
                      <div
                        :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.vendor_name }}
                      </div>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      <div
                        style="
                          width: 120px;
                          font-size: 11px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ convertDate(item.created_at) }}
                      </div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div
                        :style="`text-transform:uppercase; font-size:11px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; color:${payreqStatusColor(
                          item.status
                        )};`"
                      >
                        {{ payreqStatusName(item.status) }}
                      </div>
                    </template>
                    <template v-slot:[`item.payment_type`]="{ item }">
                      <div
                        style="
                          width: 90px;
                          text-transform: uppercase;
                          font-size: 11px;
                          font-weight: bolder;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ paymentTypeName(item.payment_type) }}
                      </div>
                    </template>
                    <template v-slot:[`item.payreq_type`]="{ item }">
                      <div
                        style="
                          text-transform: uppercase;
                          font-size: 11px;
                          font-weight: bolder;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ payreqTypeName(item.payreq_type) }}
                      </div>
                    </template>
                    <template v-slot:[`item.created_by_name`]="{ item }">
                      <div
                        style="
                          width: 100px;
                          font-size: 11px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ item.created_by_name }}
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
              <div v-if="item.tab == 'Penerimaan'">
                <div style="margin-top: 5px; position: relative">
                  <v-data-table
                    height="50vh"
                    mobile-breakpoint="0"
                    class="elevation-1"
                    fixed-header
                    :headers="grHeaders"
                    style="cursor: pointer"
                    :items="grResult"
                    :loading="loading"
                    @click:row="rowClickGr"
                  >
                    <template v-slot:[`item.document_no`]="{ item }">
                      <div
                        :style="`min-width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.document_no }}
                      </div>
                    </template>
                    <template v-slot:[`item.company_name`]="{ item }">
                      <div
                        :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.company_name }}
                      </div>
                    </template>
                    <template v-slot:[`item.department_name`]="{ item }">
                      <div
                        :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.department_name }}
                      </div>
                    </template>
                    <template v-slot:[`item.vendor_name`]="{ item }">
                      <div
                        :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                      >
                        {{ item.vendor_name }}
                      </div>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                      <div
                        style="
                          width: 120px;
                          font-size: 11px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ convertDate(item.created_at) }}
                      </div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div
                        :style="`text-transform:uppercase; font-size:11px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; color:${grStatusColor(
                          item.status
                        )};`"
                      >
                        {{ grStatusName(item.status) }}
                      </div>
                    </template>
                    <template v-slot:[`item.created_by_name`]="{ item }">
                      <div
                        style="
                          width: 100px;
                          font-size: 11px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                        "
                      >
                        {{ item.created_by_name }}
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <div
          v-if="isEdit"
          style="
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.1);
          "
        ></div>
      </v-card>
      <div>
        <FpbFormEdit
          :param="{
            act: 'update',
            items: itemEdit
          }"
          :detail="detail"
          @loadPage="loadPage"
          :wWidth="wWidth"
          :dropdownUnit="dropdownUnit"
        />
      </div>
      <div>
        <FpbFormEdit
          :param="{ act: 'add' }"
          :detail="detail"
          @loadPage="loadPage"
          :wWidth="wWidth"
          :dropdownUnit="dropdownUnit"
        />
      </div>
    </div>
    <div style="position: absolute; right: 15px; top: 10px" class="d-flex">
      <h6
        class="mr-2"
        style="
          text-align: right;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          cursor: pointer;
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              small
              @click="previousPage"
              v-bind="attrs"
              v-on="on"
              :disabled="
                Number($route.query.idx) === 0 || $route.query.idx === undefined
              "
            >
              <v-icon style="position: relative; top: -1px; right: 0"
                >mdi-chevron-left</v-icon
              >
            </v-btn>
          </template>
          <span>previous</span>
        </v-tooltip>
      </h6>
      <p style="margin: 0; position: relative; top: 3px">
        <span>
          {{
            $route.query.idx !== undefined ? Number($route.query.idx) + 1 : '1'
          }}
        </span>
        /
        <span>
          {{ $route.query.idx !== undefined ? getFpbList.fpbList.length : '1' }}
        </span>
      </p>
      <h6
        class="ml-2"
        style="
          text-align: right;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          cursor: pointer;
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-if="$route.query.idx === undefined"
              icon
              small
              @click="nextPage"
              v-bind="attrs"
              v-on="on"
              disabled
            >
              <v-icon style="position: relative; top: -1px; right: 0"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
            <v-btn
              v-else
              icon
              small
              @click="nextPage"
              v-bind="attrs"
              v-on="on"
              :disabled="
                Number($route.query.idx) === getFpbList.fpbList.length - 1
              "
            >
              <v-icon style="position: relative; top: -1px; right: 0"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </template>
          <span>next</span>
        </v-tooltip>
      </h6>
    </div>

    <v-dialog v-model="rabDialog" width="500" persistent>
      <v-form
        :disabled="loading"
        lazy-validation
        ref="entryFormUpload"
        @submit.prevent="submitUploadDoc"
        style="position: relative"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2"> UPLOAD </v-card-title>

          <v-card-text class="d-flex justify-center align-center">
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Subject
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div>
                    <v-text-field
                      outlined
                      v-model="file_subject"
                      dense
                      :rules="rules.subjectRules"
                    ></v-text-field>
                  </div>
                  <div
                    style="padding: 10px; border: 2px dashed grey; height: 70px"
                  >
                    <v-file-input
                      ref="uploadDoc"
                      placeholder="Pilih File"
                      dense
                      append-icon="mdi-paperclip"
                      prepend-icon=""
                      @change="uploadFileDoc"
                      :rules="rules.docRules"
                    ></v-file-input>
                  </div>
                </v-col>
              </div>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              id="closeFileUpload"
              color="error"
              text
              outlined
              type="reset"
              @click="uploadDoc('close')"
              :loading="loading"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              text
              outlined
              type="submit"
              :loading="loading"
              :disabled="fileNameRab === '' || file_subject === ''"
            >
              simpan file
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="submitToPrDialog" width="500" persistent>
      <v-form
        :disabled="loading"
        ref="entryForm"
        @submit.prevent="submitToPr"
        style="position: relative"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            SUBMIT ITEM
          </v-card-title>

          <v-card-text class="d-flex justify-center align-center">
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p style="margin: 0; font-weight: bold">
                    Tentukan kategori untuk {{ selectedData.length }} item FPB
                    yang dipilih
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="height: 70px">
                    <v-select
                      v-if="detail !== null"
                      label="Item Category"
                      style="position: relative; font-size: 12px"
                      v-model="fpb_category_item_type"
                      :items="['PR', 'CA']"
                      outlined
                    ></v-select>
                  </div>
                </v-col>
              </div>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              @click="closeSubmitToPRDialog"
              :loading="loading"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              outlined
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <div style="position: fixed; bottom: 10px; right: 0; z-index: 3">
      <Scanner />
    </div>
    <v-snackbar
      top
      elevation="0"
      color="white"
      v-model="snackbar"
      :timeout="timeout"
    >
      <v-alert outlined type="error" prominent border="left">
        {{ alertText }}
      </v-alert>
    </v-snackbar>
    <v-dialog v-model="pdfDialog" width="90%" @click:outside="closePdf">
      <v-card style="position: relative; width: 100%; height: 90vh">
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              @click.prevent="closePdf"
              v-bind="attrs"
              v-on="on"
              text
              icon
              color="white"
              style="
                position: fixed;
                top: 10px;
                right: 10px;
                z-index: 3;
                filter: drop-shadow(1px 1px 1px white);
              "
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
        <div
          id="loader"
          class="d-flex justify-center align-center"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        >
          <v-progress-circular
            v-if="isPdfOpen && selectedPdf !== null"
            :rotate="-90"
            :size="150"
            :width="15"
            :value="value"
            color="black"
          >
            {{ value / 10 }}%
          </v-progress-circular>
        </div>
        <div v-if="showPdf" style="height: 100%; padding: 5px 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                @click.prevent="closePdf"
                v-bind="attrs"
                v-on="on"
                text
                icon
                outlined
                color="white"
                style="position: fixed; top: 10px; right: 10px; z-index: 3"
              >
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
          <vue-pdf-app
            v-if="selectedPdf !== null"
            @pages-rendered="pagesRendered"
            @open="openHandler"
            :pdf="selectedPdf"
            :file-name="detail.request_no"
          >
            <template #toolbar-sidebar-prepend="{ toggleTheme }">
              <button @click="toggleTheme = null" type="button">
                Toggle theme
              </button>
            </template>
          </vue-pdf-app>

          <div
            v-else
            class="d-flex justify-center align-center"
            style="
              height: 100%;
              width: 100%;
              margin-left: auto;
              position: relative;
              z-index: 1;
              background: #eaeaea;
            "
          >
            <h3 style="margin: 0">File Not Found</h3>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="detail !== null"
      v-model="dokumenDialog"
      width="500"
      persistent
    >
      <v-card style="position: relative">
        <v-card-title class="text-h5 grey lighten-2">
          Dokumen Pendukung
        </v-card-title>
        <v-card-text class="d-flex justify-center align-center">
          <v-col md="12" style="padding: 0; position: relative; top: 20px">
            <v-data-table
              v-if="detail.file != null"
              mobile-breakpoint="0"
              fixed-header
              height="300"
              style="cursor: pointer"
              :headers="dokumenHeaders"
              :items="detail.file"
              @click:row="readyToDownload"
              hide-default-footer
            >
              <template v-slot:[`item.subject`]="{ item }">
                <div
                  style="
                    font-size: 12px;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                  "
                >
                  {{
                    item.subject !== null
                      ? item.subject !== ''
                        ? item.subject
                        : '-'
                      : '-'
                  }}
                </div>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div
                  style="
                    font-size: 12px;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                  "
                >
                  <v-btn
                    v-if="getUserProfile.employee.id === detail.employee.id"
                    icon
                    x-small
                    @click.stop="
                      unlinkDokumen(
                        item.file.slice(item.file.lastIndexOf('/') + 1)
                      )
                    "
                    style="margin-right: 5px"
                  >
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                  <v-btn icon x-small @click.stop="readyToDownload(item)">
                    <v-icon color="green">mdi-download</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>

            <v-btn
              v-if="
                getUserProfile.level.find(({ id }) => id === '41') ===
                  undefined && getUserProfile.employee.id === detail.employee.id
              "
              elevation="0"
              style="margin-right: auto; width: 100%"
              :loading="loading"
              color="primary"
              @click="uploadDoc('upload')"
            >
              <v-icon small style="margin-right: 5px">mdi-upload</v-icon>
              <span :style="`font-weight: bold; color:white;`"
                >Tambah Dokumen</span
              >
            </v-btn>
          </v-col>
        </v-card-text>
        <!-- <v-btn icon color="black" text @click="closeDokumenDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            text
            outlined
            @click="closeDokumenDialog"
            :loading="loading"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="getUserProfile.employee.id === detail.employee.id"
            color="error"
            text
            outlined
            :loading="loading"
            @click="deleteAllFile"
          >
            Delete all File
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="odooDialog" width="800" persistent>
      <v-card>
        <v-card-title class="text-h4 grey lighten-4">
          <div class="d-flex align-center" style="width: 100%">
            <div style="margin-right: auto">
              {{ selectedItemCode !== null ? selectedItemCode : 'Odoo' }}
            </div>
            <v-btn
              @click="sendItemEqualizationNotification(odooItem)"
              small
              color="primary"
              class="mr-5"
              outlined
            >
              <v-icon small class="mr-2">mdi-invoice-text-send-outline</v-icon>
              Lapor Penyesuaian
            </v-btn>
            <v-btn @click="closeOdooDialog" icon>
              <v-icon color="red">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text class="d-flex justify-center align-center">
          <v-col cols="12" style="padding: 0; position: relative; top: 20px">
            <v-row v-if="odooItem !== null" no-gutters>
              <v-col cols="6" style="padding: 5px">
                <v-card style="padding: 0">
                  <v-card-title
                    style="
                      height: 40px;
                      padding: 0;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      font-weight: bold;
                    "
                    class="text-h6 primary lighten-2 d-flex justify-center align-center"
                  >
                    eSanQua
                  </v-card-title>

                  <v-card-text
                    style="
                      padding: 0;
                      margin: 0;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      border-bottom: none;
                    "
                  >
                    <p
                      :style="`color:${
                        odooItem.status === '00' ? 'green' : 'red'
                      }; font-size:14px; text-align:center; padding:5px 0 0 0; margin:0;`"
                    >
                      "{{ odooItem.message }}"
                    </p>
                  </v-card-text>
                  <v-card-text
                    style="
                      padding-top: 10px;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      border-top: none;
                    "
                  >
                    <v-row no-gutters>
                      <v-col cols="12" style="padding: 0; margin-top: 10px">
                        <v-textarea
                          label="Nama Barang"
                          :value="odooItem.name"
                          dense
                          :auto-grow="true"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" style="padding: 0; margin-top: 10px">
                        <v-text-field
                          readonly
                          dense
                          label="Code"
                          :value="odooItem.code"
                        />
                      </v-col>
                      <v-col cols="12" style="padding: 0; margin-top: 10px">
                        <v-text-field
                          readonly
                          dense
                          label="Uom"
                          :value="odooItem.uom"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" style="padding: 5px">
                <v-card style="padding: 0">
                  <v-card-title
                    style="
                      height: 40px;
                      padding: 0;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      font-weight: bold;
                    "
                    class="text-h6 purple lighten-2 d-flex justify-center align-center"
                  >
                    Odoo
                  </v-card-title>
                  <div v-if="odooItem.odoo || odooItem.odoo.length > 0">
                    <v-card-text
                      style="
                        padding: 0;
                        margin: 0;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-bottom: none;
                      "
                    >
                      <p
                        :style="`color:${
                          odooItem.status === '00' ? 'green' : 'red'
                        }; font-size:14px; text-align:center; padding:5px 0 0 0; margin:0;`"
                      >
                        "{{ odooItem.message }}"
                      </p>
                    </v-card-text>
                    <v-card-text
                      v-for="(xItems, index) in odooItem.odoo"
                      :key="index"
                      style="
                        padding-top: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-top: none;
                      "
                    >
                      <v-row no-gutters>
                        <v-col cols="12" style="padding: 0; margin-top: 10px">
                          <v-textarea
                            label="Nama Barang"
                            :value="xItems.name"
                            dense
                            :auto-grow="true"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" style="padding: 0; margin-top: 10px">
                          <v-text-field
                            readonly
                            dense
                            label="Code"
                            :value="xItems.code"
                          />
                        </v-col>
                        <v-col cols="12" style="padding: 0; margin-top: 10px">
                          <v-text-field
                            readonly
                            dense
                            label="Uom"
                            :value="xItems.uom"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                  <v-card-text
                    v-else
                    class="d-flex justify-center align-center"
                    style="
                      padding-top: 10px;
                      border: 1px solid rgba(0, 0, 0, 0.1);
                      height: 235px;
                    "
                  >
                    <p
                      :style="`color:${
                        odooItem.status === '00' ? 'green' : 'red'
                      }; font-size:18px; font-weight:bold;`"
                      class="d-flex justify-center align-center"
                    >
                      "{{ odooItem.message }}"
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div v-else>
              <p
                class="d-flex justify-center align-center"
                style="font-weight: bold; font-size: 21px"
              >
                DATA NOT FOUND
              </p>
            </div>

            <div style="margin-top: 20px"></div>
          </v-col>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <v-dialog v-model="closeDialog" width="500" persistent>
      <v-form
        :disabled="loading"
        ref="entryFormClose"
        @submit.prevent="submitCloseFpb"
        style="position: relative"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            CLOSE FPB ?
          </v-card-title>

          <v-card-text class="d-flex justify-center align-center">
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p style="margin: 0; font-weight: bold">
                    Berikan komentar Anda terhadap FPB yang diajukan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="height: 70px">
                    <v-radio-group v-model="closeFpbForm.comment" mandatory>
                      <v-radio
                        label="Barang sudah diterima dengan baik"
                        value="1"
                      ></v-radio>
                      <v-radio label="Lain-lain" value="0"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <v-col
                  v-if="closeFpbForm.comment == 0"
                  cols="12"
                  style="padding: 0"
                >
                  <v-textarea
                    outlined
                    v-model="closeFpbForm.other"
                    dense
                    :auto-grow="true"
                    rows="3"
                    placeholder="Tulis komentar lain disini..."
                    clear-icon="mdi-close-circle"
                    :disabled="closeFpbForm.comment != 0"
                  ></v-textarea>
                </v-col>
              </div>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              @click="closeFpb"
              :loading="loading"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              outlined
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="selectApprovalDialog" width="500" persistent>
      <v-form
        :disabled="loading"
        ref="selectApprovalForm"
        @submit.prevent="
          detail.status.id == 0
            ? submitNow()
            : detail.status.id == 1
            ? fetch()
            : null
        "
        style="position: relative"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Ingin merubah approval default ?
          </v-card-title>

          <v-card-text class="d-flex justify-center align-center">
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p style="margin: 0; font-weight: bold">
                    Silahkan pilih approval matrix yang dikehendaki
                  </p>
                  <v-card-subtitle
                    >*kosongkan jika tidak ingin merubah
                    approval</v-card-subtitle
                  >
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="height: 45px">
                    <v-autocomplete
                      ref="autocomplete"
                      v-model="approval_matrix_id"
                      :items="dropdownApprovalMatrix"
                      :search-input.sync="approvalSearch"
                      style="margin: 0"
                      label="Pilih Approval"
                      placeholder="kosongkan jika tidak ingin merubah approval"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      hide-details
                      hide-no-data
                      hide-selected
                      clearable
                    ></v-autocomplete>
                  </div>
                </v-col>
              </div>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              @click="selectApprovalDialog = false"
              :loading="loading"
            >
              Batal
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              outlined
              :loading="loading"
            >
              Lanjutkan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="selectApprovalDialog" width="500" persistent>
      <v-form
        :disabled="loading"
        ref="selectApprovalForm"
        @submit.prevent="
          detail.status.id == 0
            ? submitNow()
            : detail.status.id == 1
            ? fetch()
            : null
        "
        style="position: relative"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Ingin merubah approval default ?
          </v-card-title>

          <v-card-text class="d-flex justify-center align-center">
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p style="margin: 0; font-weight: bold">
                    Silahkan pilih approval matrix yang dikehendaki
                  </p>
                  <v-card-subtitle
                    >*kosongkan jika tidak ingin merubah
                    approval</v-card-subtitle
                  >
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="height: 45px">
                    <v-autocomplete
                      v-model="approval_matrix_id"
                      :items="dropdownApprovalMatrix"
                      :search-input.sync="approvalSearch"
                      style="margin: 0"
                      label="Pilih Approval"
                      placeholder="kosongkan jika tidak ingin merubah approval"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      hide-details
                      hide-no-data
                      hide-selected
                      clearable
                    ></v-autocomplete>
                  </div>
                </v-col>
              </div>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              @click="selectApprovalDialog = false"
              :loading="loading"
            >
              Batal
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              outlined
              :loading="loading"
            >
              Lanjutkan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        background: rgba(0, 0, 0, 0.1);
      "
    >
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        class="d-flex justify-center align-center"
      >
        <p
          class="d-flex justify-center align-center"
          style="font-size: 12px; margin: auto"
        >
          Please wait
        </p>
      </v-progress-circular>
    </div>

    <div>
      <FormPayreq
        @closeFormPayreq="openFormPayreq"
        :detail="detail"
        :selectedData="selectedData"
        :dropdownUnit="dropdownUnit"
      />
    </div>
    <div>
      <FormPenerimaan
        :detail="detail"
        :selectedData="selectedData"
        @closeFormGr="openFormGr"
      />
    </div>
  </v-container>
</template>
<script>
import 'vue-pdf-app/dist/icons/main.css'
import axios from 'axios'
import buildType from '../../services/buildType'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {
    FpbFormEdit: () => import('./fpb/FpbItemEditForm.vue'),
    Scanner: () => import('../home/components/Scanner.vue'),
    FormPayreq: () => import('./payreq/FormPayreq.vue'),
    FormPenerimaan: () => import('./goodsreceipt/FormGr.vue')
  },
  data() {
    return {
      pageKey: 0,
      config: require('../../services/config.json')[
        process.env.VUE_APP_BUILD_TYPE
      ],
      wWidth: window.innerWidth,
      oAuth: buildType.apiURL('oauth'),
      proc: buildType.apiURL('proc'),
      upload: buildType.apiURL('upload2'),
      pagePath: this.$route.params.path,
      isFpbPageEdit: false,
      isEdit: false,
      rabDialog: false,
      submitToPrDialog: false,
      pdfDialog: false,
      dokumenDialog: false,
      closeDialog: false,

      checkbox: false,
      detail: null,
      searchItem: '',
      itemsAction: [],
      status: null,
      actionValue: 0,
      selectedData: [],
      loading: false,
      loader: null,
      fileNameRab: '',

      itemEdit: null,

      isAllow: false,
      category_pr: '',
      category_item: '',
      fpb_category_item_type: '',
      matrix: [],
      rules: {
        docRules: [],
        subjectRules: []
      },
      snackbar: false,
      alertText: '',
      timeout: 2000,
      value: 0,
      counter: 0,
      timer: 100,
      isPdfOpen: false,
      selectedPdf: null,
      showPdf: false,
      info: [],
      dropdownUnit: [],
      file_subject: '',
      tab: null,
      tabItems: [
        { tab: 'Detail Barang', content: '' },
        { tab: 'Approval', content: '' },
        { tab: 'Payment Request', content: '' },
        { tab: 'Penerimaan', content: '' }
      ],

      selectedItemCode: null,
      odooDialog: false,
      odooItem: null,
      dropdownProject: [],
      odoo_project: null,
      budget_plan_id: null,
      dropdownRAB: [],
      selectedItem: null,
      options: {},
      closeFpbForm: {
        comment: null,
        other: ''
      },
      payreqResult: [],
      grResult: [],
      approval_matrix_id: null,
      dropdownApprovalMatrix: [],
      approvalSearch: null,
      approvalSelect: null,
      selectApprovalDialog: false
      // config: {
      //   // toolbar: false,
      //   secondaryToolbar: true,
      //   toolbar: {
      //     toolbarViewerRight: true
      //     // toolbarViewerLeft: false
      //   }
      //   // sidebar: false
      // },
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getFpbId', 'getUserProfile', 'getFpbList', 'getTabs']),
    headers() {
      const arrHeaders = [
        // {
        //   text: 'No',
        //   value: 'no',
        //   align: 'left'
        //   // sortable: true,
        // },
        {
          text: 'Cek',
          value: 'check_result',
          align: 'left'
          // sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'No. PR',
          value: 'pr_no',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Item',
          value: 'product.name',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Qty Dibutuhkan',
          value: 'qty',
          align: 'left',
          sortable: false
        },
        // {
        //   text: 'Qty Dibayar',
        //   value: 'qty_paid',
        //   align: 'left',
        //   sortable: false
        // },
        // {
        //   text: 'Qty Diterima',
        //   value: 'qty_done',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Qty Dibayar',
          value: 'qty_paid',
          align: 'left',
          sortable: false
        },
        {
          text: 'Qty Diterima',
          value: 'qty_done',
          align: 'left',
          sortable: false
        },
        {
          text: 'Unit',
          value: 'uom',
          align: 'left',
          sortable: false
        },
        {
          text: 'Budget',
          value: 'budget_price_per_unit',
          align: 'left',
          sortable: false
        },
        {
          text: 'Total Budget',
          value: 'budget_price_total',
          align: 'left',
          sortable: false
        },
        {
          text: 'Penawaran',
          value: 'quotation_price_per_unit',
          align: 'left',
          sortable: false
        },
        {
          text: 'Harga Terakhir',
          value: 'last_price',
          align: 'left',
          sortable: false
        },
        // {
        //   text: 'Realisasi',
        //   value: 'realization',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Supplier',
          value: 'vendor',
          align: 'left',
          sortable: false
        }
      ]
      if (this.detail.status.id === 0) {
        return arrHeaders
      } else {
        const newArr = arrHeaders.slice(1)
        newArr.unshift(
          // {
          //   text: 'No',
          //   value: 'no',
          //   align: 'left'
          //   // sortable: true,
          // },
          {
            text: 'Odoo',
            value: 'is_item_match_with_odoo',
            align: 'left'
          }
        )
        // console.log('Here>>>>>>>>', newArr)
        return newArr
      }
    },
    approvalHeaders() {
      const arrHeaders = [
        { text: 'Sequence', value: 'sequence', align: 'left', width: '5%' },
        { text: 'Doc. No', value: 'document_no', align: 'left', width: '10%' },
        { text: 'Model', value: 'table_name', align: 'center', width: '10%' },
        { text: 'User', value: 'approver_user', align: 'center', width: '20%' },
        {
          text: 'Min. Approver',
          value: 'min_approver',
          align: 'center',
          width: '5%'
        },
        {
          text: 'Approved by',
          value: 'approved',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Rejected by',
          value: 'rejected',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Created at',
          value: 'created_at',
          align: 'center',
          width: '15%'
        },
        {
          text: 'Update at',
          value: 'updated_at',
          align: 'center',
          width: '15%'
        }
      ]
      return arrHeaders
    },
    payreqHeaders() {
      const arrHeaders = [
        {
          text: 'No. Dokumen',
          value: 'document_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        },
        {
          text: 'PIC',
          value: 'created_by_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'company_name',
          sortable: false
        },
        {
          text: 'Department',
          value: 'department_name',
          sortable: false
        },
        {
          text: 'Jenis Payreq',
          value: 'payreq_type',
          sortable: false
        },
        {
          text: 'Jenis Pembayaran',
          value: 'payment_type',
          sortable: false
        },
        {
          text: 'Dibayar Kepada',
          value: 'vendor_name',
          sortable: false
        },
        {
          text: 'Dibuat pada',
          value: 'created_at',
          align: 'left',
          sortable: false
        }
      ]

      return arrHeaders
    },
    grHeaders() {
      const arrHeaders = [
        {
          text: 'No. Dokumen',
          value: 'document_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        },
        {
          text: 'PIC',
          value: 'created_by_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'company_name',
          sortable: false
        },
        {
          text: 'Department',
          value: 'department_name',
          sortable: false
        },
        {
          text: 'Dibuat pada',
          value: 'created_at',
          align: 'left',
          sortable: false
        }
      ]

      return arrHeaders
    },
    dokumenHeaders() {
      const arrHeaders = [
        {
          text: 'File Name',
          value: 'subject',
          align: 'left',
          sortable: false,
          width: '90%'
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
          sortable: false,
          width: '10%'
        }
      ]
      return arrHeaders
    },
    dropdownCategoryItem() {
      const arr = [
        { id: 1, name: 'Raw Material' },
        { id: 2, name: 'Factory Supply' },
        { id: 3, name: 'Office Supply' },
        { id: 4, name: 'Sparepart' },
        { id: 5, name: 'Jasa' },
        { id: 6, name: 'Maintenance Repair' },
        { id: 7, name: 'Investment / Asset' }
      ]
      if (this.detail != null) {
        if (this.detail.company.id == 5 || this.detail.company.id == 14) {
          arr.splice(
            2,
            4,
            {
              id: 8,
              name: 'Office Supply (< 1jt)'
            },
            {
              id: 9,
              name: 'Office Supply (1jt-5jt)'
            },
            {
              id: 10,
              name: 'Office Supply (> 5j)'
            },
            {
              id: 11,
              name: 'Marketing Material (< 1jt)'
            },
            {
              id: 12,
              name: 'Marketing Material (1jt-5jt)'
            },
            {
              id: 13,
              name: 'Marketing Material (> 5j)'
            },
            {
              id: 14,
              name: 'Sparepart (< 1jt)'
            },
            {
              id: 15,
              name: 'Sparepart (1jt-5jt)'
            },
            {
              id: 16,
              name: 'Sparepart (> 5j)'
            },
            {
              id: 17,
              name: 'Jasa (< 1jt)'
            },
            {
              id: 18,
              name: 'Jasa (1jt-5jt)'
            },
            {
              id: 19,
              name: 'Jasa (> 5j)'
            },
            {
              id: 20,
              name: 'Maintenance Repair (< 1jt)'
            },
            {
              id: 21,
              name: 'Maintenance Repair (1jt-5jt)'
            },
            {
              id: 22,
              name: 'Maintenance Repair (> 5j)'
            }
          )
        }
      }
      return arr
    }
  },
  watch: {
    selectedData() {
      if (this.detail != null) {
        if (this.detail.status.id == 0) {
          if (this.selectedData.length != 0) {
            if (this.selectedData.length == 1) {
              this.itemsAction = [
                { id: 0, name: '' },
                { id: 1, name: 'Edit' },
                { id: 2, name: 'Delete' }
              ]
            }
            if (this.selectedData.length > 1) {
              this.itemsAction = [
                { id: 0, name: '' },
                { id: 9, name: 'Delete' }
              ]
            }
          } else {
            this.itemsAction = [{ id: 0, name: '' }]
          }
        } else if (this.detail.status.id == 2) {
          this.itemsAction = [{ id: 0, name: '' }]
          // if (this.selectedData.length === 1) {
          // check if FPB item status is Draft ?
          // if (this.selectedData[0].status.id == 0) {
          //   this.itemsAction.push({ id: 2, name: 'Delete' })
          // }

          if (this.selectedData.length > 0) {
            let flag = false
            // id = 0 -> draft | 1 -> waiting | 2 -> approved
            const xSlc = this.selectedData
            console.log(xSlc)
            if (
              xSlc.find(
                ({ status }) =>
                  status.id == 0 || status.id == 3 || status.id == 5
              ) !== undefined
            ) {
              flag = false
            } else {
              flag = true
            }
            if (flag) {
              this.itemsAction.push({ id: 6, name: 'Cancel PR' })
            } else {
              this.itemsAction = [{ id: 0, name: '' }]
              // check if selected item all draft
              if (
                xSlc.find(({ status }) => status.id == 2 || status.id == 3) ==
                undefined
              ) {
                this.itemsAction = [
                  { id: 3, name: 'Submit Item' },
                  { id: 8, name: 'Change to PR' },
                  { id: 10, name: 'Cancel' }
                ]
              }

              // check if all FPB item status is CA ?
              if (xSlc.find(({ status }) => status.id != 3) == undefined) {
                // if (this.selectedData.find(({ status }) => status.id != 3) ==
                // undefined) {
                this.itemsAction.push(
                  { id: 4, name: 'Set to draft' },
                  { id: 8, name: 'Change to PR' }
                  // { id: 7, name: 'Realisasi' }
                )
                if (
                  this.getUserProfile.level.find(({ id }) => id === '26') !=
                    undefined ||
                  this.getUserProfile.employee.id === this.detail.employee.id
                ) {
                  this.itemsAction.push(
                    { id: 11, name: 'Buat Payreq' },
                    { id: 12, name: 'Buat Penerimaan' }
                  )
                }
              } else {
                if (
                  xSlc.find(({ status }) => status.id != 0 && status.id != 3) ==
                  undefined
                ) {
                  if (this.detail.fpb_type == 1) {
                    this.itemsAction.push({ id: 8, name: 'Change to PR' })
                  }
                }
              }
            }
          }
          // }
        } else {
          this.itemsAction = [{ id: 0, name: '' }]
        }
      } else {
        this.itemsAction = [{ id: 0, name: '' }]
      }
    },
    approvalSearch(val) {
      if (val !== null) {
        if (val !== '') {
          val &&
            val !== this.approvalSelect &&
            this.getApprovalMatrixDropdown(val)
        } else {
          this.approvalSelect = null
          this.dropdownApprovalMatrix = []
        }
      } else {
        this.approvalSelect = null
        this.dropdownApprovalMatrix = []
      }
    }
  },
  created() {
    if (this.pagePath === 'fpb-list') {
      this.isFpbPageEdit = true
    }
    if (this.pagePath === 'cart') {
      this.isFpbPageEdit = false
    }
    if (this.getTabs !== this.tabs) {
      // this.setArrTabs()
    }
    this.loadPage()
  },
  mounted() {
    this.initDropdown()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    // ...mapMutations(['setTabs']),
    customSearch(value, search, item) {
      return Object.values(item.product).some(
        (v) => v && v.toString().toLowerCase().includes(search)
      )
    },
    // setArrTabs() {
    //   const tabsArr = [
    //     {
    //       tab_name: 'Catalogue',
    //       path: '/e-catalogue/catalogue-list'
    //     },
    //     {
    //       tab_name: 'Vendor List',
    //       path: '/e-catalogue/vendor-list'
    //     },
    //     {
    //       tab_name: 'FPB',
    //       // path: '/e-catalogue/fpb-list'
    //       path: '',
    //       sub_tabs: [
    //         {
    //           sub_title: 'FPB List',
    //           path: '/e-catalogue/fpb-list'
    //         }
    //       ],
    //       isShown: false
    //     }
    //   ]
    //   if (
    //     this.getUserProfile.level.find(({ id }) => id === '1') !== undefined
    //   ) {
    //     const arrTab = [
    //       // {
    //       //   tab_name: 'RAB',
    //       //   path: '/e-catalogue/rab'
    //       // },
    //       {
    //         tab_name: 'Project',
    //         path: '/e-catalogue/project'
    //       }
    //     ]
    //     for (let i = 0; i < arrTab.length; i++) {
    //       tabsArr.push(arrTab[i])
    //     }
    //   }
    //   if (
    //     this.getUserProfile.level.find(({ id }) => id === '1') !== undefined ||
    //     (this.getUserProfile.level.find(({ id }) => id === '3') !== undefined &&
    //       this.getUserProfile.level.find(({ id }) => id === '26') !== undefined)
    //   ) {
    //     tabsArr[2].sub_tabs.push({
    //       sub_title: 'FPB Project',
    //       path: '/e-catalogue/fpb/project-item'
    //     })
    //   }

    //   this.tabs = tabsArr
    //   this.setTabs(tabsArr)
    // },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, pInputType, pInputAtt) {
      const title =
        pInputType === 'file'
          ? 'Upload bukti approvement pada kolom dibawah'
          : ''
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            // html: pStatusMsg,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No',
            input: pInputType !== undefined ? pInputType : null,
            inputLabel: pInputType !== undefined ? title : null,
            inputAttributes: pInputAtt !== undefined ? pInputAtt : null
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    showMsgDialog2(pModalType, pStatusMsg, pBtnCancel, pText) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              additional +
              '<br/>' +
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: 'Submit',
            cancelButtonText: 'No',
            input: 'textarea',
            inputLabel: 'Tolong isi alasan pada kolom dibawah sebelum submit',
            inputPlaceholder: 'Tulis sesuatu disini...',
            inputAttributes: {
              'aria-label': 'Tulis sesuatu disini'
            }
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    showMsgDialog3(pModalType, pStatusMsg, pBtnCancel, yesBtn, noBtn, pText) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html:
              '<strong style="font-size:18px;">' +
              pStatusMsg +
              '</strong>' +
              '<br/>' +
              additional,
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            confirmButtonText: yesBtn,
            cancelButtonText: noBtn !== '' ? noBtn : 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    nextPage() {
      this.loading = true
      try {
        const pageIndex = Number(this.$route.query.idx) + 1
        const path = `/e-catalogue/fpb-list/fpb/${this.getFpbList.fpbList[pageIndex].id}?idx=${pageIndex}`
        if (this.$route.path !== path) this.$router.push(path)
        setTimeout(() => {
          this.loadPage()
        }, 200)
      } catch (e) {
        this.loading = false
      }
    },
    previousPage() {
      this.loading = true
      try {
        const pageIndex = Number(this.$route.query.idx) - 1
        const path = `/e-catalogue/fpb-list/fpb/${this.getFpbList.fpbList[pageIndex].id}?idx=${pageIndex}`
        if (this.$route.path !== path) this.$router.push(path)
        setTimeout(() => {
          this.loadPage()
        }, 200)
      } catch (e) {
        this.loading = false
      }
    },
    async loadPage() {
      this.loading = true
      this.detail = null
      console.log('params', this.$route.params)
      console.log('getUser', this.getUserProfile)
      if (this.$route.params.id !== undefined) {
        this.$store.commit('setFpbId', this.$route.params.id)
      }
      setTimeout(async () => {
        if (this.getFpbId !== null) {
          this.itemEdit = null
          this.actionValue = 0
          this.selectedData = []
          const url = `${this.proc}fpb/detail/${this.getFpbId}`
          // url = url + '&order_by=prd.id&order_type=ASC'
          await axios
            .get(url)
            .then((res) => {
              console.log('loadPage>>>', res)
              this.detail = res.data.data
              this.authorityCheck(res.data.data)
              // this.checkCode(this.detail)
              // this.budgetplanDropdown()
              this.projectDropdown()
              // this.getApprovalMatrixDropdown('')
              this.getPayreq()
              this.getGr()
              this.loading = false
              return null
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
              return null
            })
          this.category_pr = this.detail.category_pr
          this.category_item = this.detail.category_item
          this.odoo_project =
            this.detail.project !== null ? this.detail.project.id : null
          this.pageKey++
          // this.budget_plan_id =
          //   this.detail.budget_plan !== null ? this.detail.budget_plan.id : null
          // if (this.detail.status.id == 0) {
          //   if (
          //     this.detail.purchase_request_detail.find(
          //       ({ is_item_match_with_odoo }) =>
          //         is_item_match_with_odoo === null
          //       // ({ check_result }) => check_result.status === -99
          //     ) !== undefined
          //   ) {
          //     setTimeout(() => {
          //       this.loadPage()
          //     }, 100)
          //   }
          // }
        } else {
          this.loading = false
          this.$router.push('/e-catalogue/' + this.$route.params.path)
        }
      }, 300)
    },
    initDropdown() {
      this.dropdown()
      // this.projectDropdown()
    },
    dropdown() {
      axios
        .get(`${this.proc}master/unit/drop_down?keyword=`)
        .then((res) => {
          console.log('sdsada')

          this.dropdownUnit = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    projectWatcher(v) {
      if (v !== null) {
        this.category_pr = 'project'
        this.category_item = 7
      }
    },
    projectDropdown() {
      axios
        .get(
          `${this.proc}globalmaster/project/dropdown?keyword=&filter=[{"company_id":${this.detail.company.id}}]`
        )
        .then((res) => {
          this.dropdownProject = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getApprovalMatrixDropdown(v) {
      this.dropdownApprovalMatrix = []
      let url = `${this.oAuth}approval_matrix/dropdown?keyword=${v}&table_name=tr_purchaserequests`
      url = url + `&company_id=${this.detail.company.id}`
      // url = url + `&department_id=${detail.department.id}`
      // if (this.detail.category_item == 7) {
      url = url + `&ecatalogue_fpb_category_item=${this.detail.category_item}`
      // }
      axios
        .get(url)
        .then((res) => {
          console.log('approval matrix>>>', url)
          if (res.data.status_code === '00') {
            return (this.dropdownApprovalMatrix = res.data.data)
          }
          return (this.dropdownApprovalMatrix = [])
        })
        .catch((err) => {
          this.dropdownApprovalMatrix = []
          return console.log(err)
        })
    },
    // budgetplanDropdown() {
    // axios
    //   .get(
    //     `${this.proc}rab/dropdown?keyword=&filter=[{"company_id":${this.detail.company.id}}]`
    //   )
    //   .then((res) => {
    //     this.dropdownRAB = res.data.data
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
    // },
    back() {
      this.detail = null
      this.$store.commit('setFpbId', null)
      // this.$router.push('/e-catalogue/' + this.$route.params.path)

      if (this.prevRoute !== undefined) {
        if (this.prevRoute.path !== '/e-catalogue/cart') {
          this.$router.push(this.prevRoute.path)
        } else {
          this.$router.push('/e-catalogue/' + this.$route.params.path)
        }
      } else {
        this.$router.push('/e-catalogue/' + this.$route.params.path)
      }
    },
    rowClick(pValue) {
      if (!this.isEdit) {
        this.itemEdit = pValue
        setTimeout(() => {
          document.getElementById('fpbFormEdit').click()
        }, 500)
      } else {
        this.showMsgDialog('warning', 'Simpan Perubahan', true)
      }
    },
    editDetailFPB() {
      // this.getApprovalMatrixDropdown('')
      setTimeout(() => {
        this.isEdit = true
      }, 300)
    },
    async saveChange() {
      this.showMsgDialog('question', 'Simpan Perubahan', true).then(
        async (res) => {
          if (res.isConfirmed) {
            let xFlag = false
            let flagMsg = ''
            if (this.odoo_project !== null) {
              if (this.detail.company.id != 5 && this.detail.company.id != 14) {
                if (
                  (this.category_item === 7 && this.category_pr === 'asset') ||
                  (this.category_item === 7 && this.category_pr === 'project')
                ) {
                  xFlag = true
                } else {
                  // xFlag = false
                  flagMsg = 'Kategori barang & karegori pr harus asset'
                }
              } else {
                xFlag = true
              }
            } else {
              if (this.category_pr === 'project') {
                // xFlag = false
                flagMsg = 'Kolom project tidak boleh kosong'
              } else {
                // xFlag = true
                if (this.category_pr !== null) {
                  if (this.category_pr === 'asset') {
                    if (this.category_item !== null) {
                      if (this.category_item === 7) {
                        xFlag = true
                      } else {
                        flagMsg =
                          'Pembelian asset, Kategori Barang dan Kategori PR wajib memilih Investment / Asset'
                      }
                    } else {
                      flagMsg = 'Kolom kategori barang tidak boleh kosong'
                    }
                  } else {
                    if (this.category_item !== null) {
                      if (this.category_item !== 7) {
                        xFlag = true
                      } else {
                        flagMsg =
                          'Pembelian asset, Kategori Barang dan Kategori PR wajib memilih Investment / Asset'
                      }
                    } else {
                      flagMsg = 'Kolom kategori barang tidak boleh kosong'
                    }
                  }
                } else {
                  flagMsg = 'Kolom kategori pr tidak boleh kosong'
                }
              }
            }
            if (xFlag) {
              this.loading = true
              const form = {
                id: this.getFpbId,
                act: 'update',
                category_item: this.category_item,
                category_pr: this.category_pr,
                budget_is_approved: this.detail.budget_is_approved,
                memo_special_request: this.detail.memo_special_request,
                reference_from_ecommerce: this.detail.reference_from_ecommerce,
                project_id: this.odoo_project,
                fpb_type: this.detail.fpb_type
                // budget_plan_id: this.budget_plan_id
                // file: this.fileNameRab
              }
              await axios
                .post(`${this.proc}fpb/update`, form)
                .then((res) => {
                  this.loading = false
                  if (res.data.status_code === '00') {
                    this.showMsgDialog('success', res.data.status_msg, false)
                    if (this.fileNameRab.length > 0) {
                      this.fileNameRab = ''
                      this.$refs.entryForm.reset()
                      setTimeout(() => {
                        this.rabDialog = !this.rabDialog
                      }, 500)
                    }
                    this.loadPage()
                  } else {
                    this.showMsgDialog('error', res.data.status_msg, false)
                  }
                })
                .catch((err) => {
                  console.log(err)

                  this.loading = false
                })
              this.isEdit = false
            } else {
              this.showMsgDialog('warning', flagMsg, false)
              // this.isEdit = false
              // this.odoo_project = null
            }
          }
        }
      )
    },
    checkBeforeSubmitFpb() {
      let xFlag = false
      if (this.category_pr === 'asset' || this.category_pr === 'project') {
        if (this.category_pr === 'asset') {
          const notAssetCode = this.detail.purchase_request_detail.find(
            ({ product }) => !product.code.includes('AST')
          )
          if (notAssetCode !== undefined) {
            this.showMsgDialog(
              'warning',
              "Kategori PR yang dipilih hanya untuk barang dengan kode 'AST'",
              false
            )
          } else {
            xFlag = true
          }
        } else {
          xFlag = true
        }
      } else {
        const isAssetCode = this.detail.purchase_request_detail.find(
          ({ product }) => product.code == null || product.code.includes('AST')
        )
        if (isAssetCode !== undefined) {
          this.showMsgDialog(
            'warning',
            "Kategori PR yang dipilih tidak dapat diproses untuk barang dengan kode 'AST' ataupun FREE TEXT",
            false
          )
        } else {
          xFlag = true
        }
      }

      if (xFlag) {
        // console.log(this.detail.purchase_request_detail)
        // console.log(this.approval_matrix_id)
        if (
          this.detail.purchase_request_detail == null ||
          this.detail.purchase_request_detail.length == 0
        ) {
          this.showMsgDialog('warning', 'Belum ada item yang diinput', false)
        } else {
          this.submitFPB()
        }
      }
    },
    async submitFPB() {
      if (this.category_pr === null || this.category_pr === '') {
        this.showMsgDialog('warning', 'Kategori PR harus dipilih dahulu', false)
        return null
      }
      if (this.detail.fpb_type == null) {
        this.showMsgDialog(
          'warning',
          'Tipe pemrosesan barang harus dipilih dahulu !',
          false
        )
        return null
      }

      this.showMsgDialog3(
        'warning',
        'Tidak dapat merubah data setelah submit, Pastikan semua data yang dibutuhkan sudah terisi sebelum anda menekan tombol submit',
        true,
        'Submit Sekarang',
        'Batalkan'
      ).then(async (res) => {
        if (res.isConfirmed) {
          let xFlag = false
          if (this.detail.fpb_type != 1) {
            if (
              this.detail.purchase_request_detail.find(
                ({ is_item_match_with_odoo }) =>
                  is_item_match_with_odoo === 0 ||
                  is_item_match_with_odoo === null
              ) !== undefined
            ) {
              this.showMsgDialog3(
                'warning',
                'Ada Barang yang terdeteksi tidak sesuai dengan data di odoo, silahkan sesuaikan dahulu untuk melanjutkan FPB',
                false,
                'OK',
                '',
                'Hubungi admin untuk penyesuaian data !'
              )

              xFlag = false
            } else {
              xFlag = true
            }
          } else {
            xFlag = true
          }

          if (xFlag) {
            console.log(this.getUserProfile)

            if (
              (this.detail.status.id == 0 || this.detail.status.id == 1) &&
              this.getUserProfile != null &&
              this.getUserProfile.employee != null &&
              this.getUserProfile.employee.department != null &&
              (this.getUserProfile.employee.department.id == 3 ||
                this.getUserProfile.employee.department.id == 9 ||
                this.getUserProfile.employee.department.id == 59)
            ) {
              this.getApprovalMatrixDropdown('')
              setTimeout(() => {
                this.selectApprovalDialog = true
              }, 300)
            } else {
              this.submitNow()
            }
          }
        }
      })
    },
    submitNow() {
      this.loading = true
      const form = {
        id: this.getFpbId,
        category_pr: this.category_pr,
        approval_matrix_id: this.approval_matrix_id
      }
      axios
        .post(`${this.proc}fpb/submit`, form)
        .then(async (res) => {
          this.loading = false
          this.category_pr = ''
          this.showMsgDialog('success', res.data.status_msg, false)
          if (this.detail.approval_matrix !== null) {
            await this.fetch()
            console.log('fetch done')
          } else {
            this.loadPage()
          }
          this.selectApprovalDialog = false
        })
        .catch((err) => {
          console.log(err)
          this.selectApprovalDialog = false
          this.loading = false
        })
    },
    cancelFpb() {
      this.showMsgDialog2(
        'question',
        'Anda yakin ingin membatalkan FPB ini ?',
        true
      ).then((res) => {
        console.log(res)
        if (res.isConfirmed) {
          if (res.value != '') {
            this.loading = true
            const date = new Date()
            const local = date.toLocaleString('id')
            const updateAt = `[${local}]\n${res.value}`
            axios
              .post(`${this.proc}fpb/cancel`, {
                document_id: this.getFpbId,
                cancel_reason: updateAt
              })
              .then((res) => {
                if (res.data.status_code === '00') {
                  this.showMsgDialog('success', res.data.status_msg, false)
                } else {
                  this.showMsgDialog('error', res.data.status_msg, false)
                }
                this.loading = false
                this.loadPage()
              })
              .catch((err) => {
                this.loading = false
                console.log(err)
              })
          } else {
            this.showMsgDialog(
              'warning',
              'Kolom alasan tidak boleh kosong',
              false
            )
          }
        }
      })
    },
    submitCloseFpb() {
      let close_reason = ''
      if (this.closeFpbForm.comment == 1) {
        close_reason = 'Barang sudah diterima dengan baik'
      } else {
        close_reason = this.closeFpbForm.other
      }
      console.log(close_reason)
      this.loading = true
      axios
        .post(`${this.proc}fpb/close`, {
          document_id: this.getFpbId,
          closed_reason: close_reason
        })
        .then((res) => {
          if (res.data.status_code === '00') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.closeFpb()
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          this.loading = false
          this.loadPage()
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    async closeFpb() {
      setTimeout(() => {
        this.closeDialog = !this.closeDialog
      }, 300)
      if (!this.closeDialog) {
        this.closeFpbForm = {
          comment: null,
          other: ''
        }
      }
      // this.showMsgDialog2(
      //   'question',
      //   'Anda yakin ingin meng-close FPB ini ?',
      //   true
      // ).then((res) => {
      //   if (res.isConfirmed) {
      //     this.loading = true
      //     axios
      //       .post(`${this.proc}fpb/close`, {
      //         document_id: this.getFpbId,
      //         closed_reason: res.value
      //       })
      //       .then((res) => {
      //         if (res.data.status_code === '00') {
      //           this.showMsgDialog('success', res.data.status_msg, false)
      //         } else {
      //           this.showMsgDialog('error', res.data.status_msg, false)
      //         }
      //         this.loading = false
      //         this.loadPage()
      //       })
      //       .catch((err) => {
      //         console.log(err)
      //         this.loading = false
      //       })
      //   }
      // })
    },
    setToDraft() {
      this.showMsgDialog(
        'question',
        'Kembalikan FPB menjadi DRAFT ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          this.loader = 'loading'

          const l = this.loader
          this[l] = !this[l]

          axios
            .post(`${this.proc}fpb/set_to_draft`, { id: this.getFpbId })
            .then((res) => {
              this.showMsgDialog('', res.data.status_msg, false)
              this[l] = false
              this.loadPage()
            })
            .catch((err) => {
              console.log(err)
              this[l] = false
            })

          this.loader = null
        }
      })
    },

    confirmFPB() {
      // const inputType = 'file'
      // const inputAtt = {
      //   accept: 'image/*',
      //   'aria-label': 'Upload bukti approve'
      // }
      this.showMsgDialog(
        'question',
        'Setujui FPB ini sekarang ?',
        true
        // inputType,
        // inputAtt
      ).then((res) => {
        if (res.isConfirmed) {
          const document_id = this.detail.approval_matrix[0].document_id
          this.loader = 'loading'
          const l = this.loader
          this[l] = !this[l]
          axios
            .post(`${this.proc}fpb/confirm`, {
              document_id: document_id
            })
            .then((res) => {
              this.showMsgDialog('success', res.data.status_msg, false)
              this[l] = false
              this.loadPage()
            })
            .catch((err) => {
              console.log(err)
              this[l] = false
            })
          this.loader = null
        }
      })
    },
    rejectFPB() {
      this.showMsgDialog2(
        'question',
        'Anda yakin ingin menolak FPB ini ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          const document_id = this.detail.approval_matrix[0].document_id
          this.loader = 'loading'
          const l = this.loader
          this[l] = !this[l]
          axios
            .post(`${this.proc}fpb/reject`, {
              document_id: document_id,
              reject_reason: res.value
            })
            .then((res) => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
              } else {
                this.showMsgDialog('error', res.data.status_msg, false)
              }
              this[l] = false
              this.loadPage()
            })
            .catch((err) => {
              console.log(err)
              this[l] = false
            })
          this.loader = null
        }
      })
    },

    generatePdfFPB() {
      this.loader = 'loading'

      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => {
        this[l] = false
        axios({
          url: `${this.proc}fpb/export_to_pdf/${this.getFpbId}`,
          method: 'GET',
          responseType: 'arraybuffer'
        })
          .then((res) => {
            this.selectedPdf = res.data
            setTimeout(() => {
              this.viewPDF()
            }, 1000)
          })
          .catch((error) => {
            console.log(error)
          })
      }, 2000)

      this.loader = null
    },
    downloadDokumen() {
      // if (this.detail.file !== null) {
      // if (this.detail.file.length > 0) {
      setTimeout(() => {
        this.dokumenDialog = true
      }, 300)
      // } else {
      //   this.showMsgDialog('warning', 'Belum ada file yang diupload', false)
      // }
      // } else {
      //   this.showMsgDialog('warning', 'Belum ada file yang diupload', false)
      // }
    },
    readyToDownload(fileLink) {
      window.open(fileLink.file, '_blank')
    },
    unlinkDokumen(fName, pValue) {
      this.loading = true
      const data = {
        module: 'fpb',
        file_name: fName
      }
      axios
        .post(`${this.upload}esanqua/procurement/unlink`, data)
        .then((res) => {
          if (res.data.status_code === '00') {
            this.fileNameRab = ''
            if (pValue === undefined) {
              this.removeFileFromDetail(fName)
            }
          } else {
            this.showMsgDialog('warning', res.data.status_msg, false)
            if (pValue === undefined) {
              if (res.data.status_msg === 'File not found') {
                this.removeFileFromDetail(fName)
              }
            }
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog('error', err.message, false)
        })
    },
    removeFileFromDetail(fName) {
      for (let i = 0; i < this.detail.file.length; i++) {
        if (this.detail.file[i].file.includes(fName)) {
          this.detail.file.splice(i, 1)
        }
      }
      this.updatePhoto(false)
    },
    closeDokumenDialog() {
      setTimeout(() => {
        this.dokumenDialog = false
      }, 300)
    },
    actionSelect(event) {
      switch (event) {
        case 1:
          // this.initDropdown()
          this.itemEdit = this.selectedData[0]
          setTimeout(() => {
            document.getElementById('fpbFormEdit').click()
          }, 500)
          break
        case 2:
          this.delete()
          break
        case 3:
          if (this.detail.fpb_type != 3 && this.detail.fpb_type != null) {
            if (this.detail.fpb_type == 1) {
              this.fpb_category_item_type = 'CA'
            }
            if (this.detail.fpb_type == 2) {
              this.fpb_category_item_type = 'PR'
            }
            setTimeout(() => {
              this.submitToPr()
            }, 100)
          } else {
            this.submitToPrDialog = true
          }
          break
        case 4:
          this.setToDraftItem()
          break
        case 5:
          this.paidCa()
          break
        case 6:
          this.cancelPr()
          break
        // case 7:
        //   this.updateRealization()
        //   break
        case 8:
          if (
            this.selectedData.find(
              ({ is_item_match_with_odoo }) => is_item_match_with_odoo == 0
            ) != undefined
          ) {
            this.showMsgDialog(
              'warning',
              'Tidak dapat merubah ke PR karena ada item yang belum sesuai dengan odoo',
              false
            )
            setTimeout(() => {
              this.actionValue = 0
              this.selectedData = []
            }, 100)
            break
          }
          this.fpb_category_item_type = 'PR'
          this.submitToPr()
          break
        case 9:
          this.deleteBatch()
          break
        case 10:
          this.cancelItem()
          break
        case 11:
          this.openFormPayreq('open')
          break
        case 12:
          this.openFormGr('open')
          break
      }
    },

    openFormPayreq(val) {
      if (val == 'open') {
        var xItem = this.selectedData.find(
          ({ qty_paid, qty }) => qty == qty_paid
        )
        console.log(xItem)
        if (xItem != undefined) {
          this.showMsgDialog(
            'warning',
            `[${xItem.product.code}] Tidak dapat diproses karna QTY Dibayar (${xItem.qty_paid}) sudah sama dengan QTY Dibutuhkan (${xItem.qty})`,
            false
          )
          setTimeout(() => {
            this.actionValue = 0
            this.selectedData = []
          }, 200)
          return
        }
        setTimeout(() => {
          document.getElementById('payreqForm').click()
        }, 200)
      }
      if (val == 'close') {
        this.actionValue = 0
        this.selectedData = []
      }
    },
    openFormGr(val) {
      if (val == 'open') {
        var xItem = this.selectedData.find(
          ({ qty_paid, qty_done }) => qty_paid == qty_done || qty_paid == 0
        )

        if (xItem != undefined) {
          setTimeout(() => {
            this.actionValue = 0
            this.selectedData = []
          }, 200)

          if (xItem.qty_paid == 0) {
            this.showMsgDialog(
              'warning',
              `[${xItem.product.code}] Tidak dapat diproses karna QTY Dibayar belum tersedia (${xItem.qty_done})`,
              false
            )
            return
          }
          if (xItem.qty_paid == xItem.qty_done) {
            this.showMsgDialog(
              'warning',
              `[${xItem.product.code}] Tidak dapat diproses karna QTY Diterima (${xItem.qty_done}) sama dengan QTY Dibayar (${xItem.qty_paid})`,
              false
            )
            return
          }
        }
        setTimeout(() => {
          document.getElementById('goodsReceiptForm').click()
        }, 200)
      }
      if (val == 'close') {
        this.actionValue = 0
        this.selectedData = []
      }
    },
    addItem() {
      // this.initDropdown()
      setTimeout(() => {
        document.getElementById('fpbFormAdd').click()
      }, 500)
    },
    uploadDoc(event) {
      if (event === 'upload') {
        setTimeout(() => {
          this.rabDialog = true
        }, 500)
      }
      if (event === 'close') {
        if (this.fileNameRab !== '') {
          let fName = ''
          fName = this.fileNameRab
          this.unlinkDokumen(fName, true)
          this.file_subject = null
          // this.$refs.uploadDoc.reset()
        }
        this.$refs.entryFormUpload.reset()
        setTimeout(() => {
          this.rabDialog = false
        }, 500)
      }
      this.fileNameRab = ''
    },
    uploadFileDoc(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024 * 2) {
          this.loading = true
          const data = new FormData()
          data.append('file', event)
          data.append('module', 'fpb')
          axios
            .post(`${this.upload}esanqua/procurement`, data)
            .then((res) => {
              if (res.data.status === true) {
                this.showMsgDialog(
                  'success',
                  'File siap disimpan, Silahkan lanjutkan dengan klik tombol "simpan file"',
                  false
                )
                this.fileNameRab = res.data.data.name
                // setTimeout(() => {
                //   this.submitUploadDoc()
                // }, 200)
              } else {
                this.showMsgDialog('warning', res.data.message, false)
              }
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog('error', err.message, false)
            })
        } else {
          setTimeout(() => {
            this.showMsgDialog('warning', 'Max size = 2MB', false)
            this.fileNameRab = ''
          }, 300)
        }
      } else {
        this.fileNameRab = ''
        // this.$refs.uploadDoc.reset()
      }
    },
    submitUploadDoc() {
      this.rules.docRules = [(v) => !!v || 'Tidak ada file yang terupload']
      this.rules.subjectRules = [(v) => !!v || 'Kolom Subject diperlukan']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryFormUpload.validate()) {
          self.updatePhoto(true)
        }
      })
    },
    async updatePhoto(isAddFile) {
      this.loader = 'loading'

      const l = this.loader
      this[l] = !this[l]
      const file = []
      if (this.detail.file != null) {
        for (let i = 0; i < this.detail.file.length; i++) {
          const fName = this.detail.file[i].file.slice(
            this.detail.file[i].file.lastIndexOf('/') + 1
          )
          file.push({
            subject: this.detail.file[i].subject,
            file: fName
          })
        }
      }
      if (isAddFile) {
        file.push({
          subject: this.file_subject,
          file: this.fileNameRab
        })
      }
      const form = {
        id: this.getFpbId,
        file: file
      }
      await axios
        .post(`${this.proc}fpb/update_file_upload`, form)
        .then((res) => {
          this[l] = false
          if (res.data.status_code === '00') {
            this.showMsgDialog('success', 'File berhasil disimpan', false)
            this.file_subject = null
            this.fileNameRab = ''
            document.getElementById('closeFileUpload').click()
            this.loadPage()
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
            this.unlinkDokumen(this.fileNameRab)
          }
        })
        .catch((err) => {
          this.showMsgDialog('error', err.message, false)
          this[l] = false
          this.fileNameRab = ''
          this.$refs.entryFormUpload.reset()
        })
      this.loader = null
      this.isEdit = false
    },
    async deleteAllFile() {
      this.showMsgDialog(
        'question',
        'Semua file akan terhapus, Anda yakin ?',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          this.loading = true
          const form = {
            id: this.getFpbId,
            file: []
          }
          await axios
            .post(`${this.proc}fpb/update_file_upload`, form)
            .then((res) => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.loadPage()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
              this.loading = false
              setTimeout(() => {
                this.dokumenDialog = false
              }, 300)
            })
            .catch((err) => {
              this.loading = false
              this.showMsgDialog('error', err.message, false)
            })
        }
      })
    },
    delete() {
      this.showMsgDialog(
        'question',
        'Item akan dihapus, Anda yakin ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          axios
            .delete(`${this.proc}fpb/delete_detail/${this.selectedData[0].id}`)
            .then((res) => {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.loadPage()
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          this.actionValue = 0
        }
      })
    },
    price(pItem) {
      if (pItem !== undefined) {
        if (pItem !== null) {
          return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return '0'
    },
    percentConvert(detail) {
      const realization = detail.total_realization
      const totalPrice = detail.total_price
      let percent = 0
      if (realization != 0 && totalPrice != 0) {
        percent = (realization / totalPrice) * 100
      }
      return percent
    },
    hasBudget(val) {
      switch (val) {
        case 0:
          return 'Tidak'
        case 1:
          return 'Ada'
      }
    },

    authorityCheck(item) {
      if (item.approval_matrix !== null) {
        const matrixId = item.approval_matrix[0].document_id
        axios
          .get(
            `${this.oAuth}approval_matrix/document/allow_user?document_id=${matrixId}&application_id=2`
          )
          .then((res) => {
            if (res.data.status_code !== '-99') {
              if (res.data.is_allow_approve !== 0) {
                this.isAllow = true
              } else {
                this.isAllow = false
              }
            } else {
              this.isAllow = false
            }
          })
          .catch((err) => {
            this.showMsgDialog(
              'error',
              err
                ? 'Cek Otoritas error, Silahkan info ke Admin'
                : 'Cek Otoritas error, Silahkan info ke Admin',
              false
            )
          })
      }
    },
    // async fetch2() {
    //   // this.showMsgDialog(
    //   //   'warning',
    //   //   'Data approval saat ini akan dikosongkan apakah anda yakin ?',
    //   //   true
    //   // ).then((res) => {
    //   //   if (res.isConfirmed) {
    //   const form = {
    //     act: 'fetch_matrix',
    //     document_id: this.detail.id,
    //     document_no: this.detail.request_no,
    //     application_id: 2,
    //     table_name: 'tr_purchaserequests',
    //     ecatalogue_fpb_category_item: this.detail.category_item,
    //     department_id: this.detail.department.id,
    //     company_id: this.detail.company.id
    //   }
    //   this.loading = true
    //   axios
    //     .post(`${this.oAuth}approval_matrix/document/save`, form)
    //     .then((res) => {
    //       // if (res.data.status_code !== '-99') {
    //       //   this.showMsgDialog('success', res.data.status_msg, false)
    //       // } else {
    //       //   if (res.data.status_msg === 'Parameter value has problem') {
    //       //     this.showMsgDialog('error', res.data.error_msg[0].msg, false)
    //       //   } else {
    //       //     this.showMsgDialog('error', res.data.status_msg, false)
    //       //   }
    //       // }
    //       this.loading = false
    //       this.loadPage()
    //       return null
    //     })
    //     .catch((err) => {
    //       this.showMsgDialog(
    //         'error',
    //         err
    //           ? 'Cek Otoritas error, Silahkan info ke Admin'
    //           : 'Cek Otoritas error, Silahkan info ke Admin',
    //         false
    //       )
    //       this.loading = false
    //       this.loadPage()
    //       return null
    //     })
    //   // } else {
    //   //   return null
    //   // }
    //   // })
    // },
    checkBeforeFetch() {
      if (
        (this.detail.status.id == 0 || this.detail.status.id == 1) &&
        this.getUserProfile != null &&
        this.getUserProfile.employee != null &&
        this.getUserProfile.employee.department != null &&
        (this.getUserProfile.employee.department.id == 3 ||
          this.getUserProfile.employee.department.id == 9 ||
          this.getUserProfile.employee.department.id == 59)
      ) {
        this.getApprovalMatrixDropdown('')
        setTimeout(() => {
          this.selectApprovalDialog = true
        }, 300)
      } else {
        this.fetch()
      }
    },
    async fetch() {
      this.loading = true
      const form = {
        id: this.getFpbId,
        approval_matrix_id: this.approval_matrix_id
      }
      await axios
        .post(`${this.proc}fpb/fetch_matrix`, form)
        .then(async (res) => {
          if (res.data.status_code == '00') {
            if (res.data.approval_matrix_result.status_code == '00') {
              this.loadPage()
            } else {
              this.showMsgDialog(
                'warning',
                res.data.approval_matrix_result.status_msg,
                false
              )
            }
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
          this.loading = false
          this.showMsgDialog(
            res.data.status_code == '00' ? 'success' : 'warning',
            res.data.status_msg,
            false
          )
          this.selectApprovalDialog = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.selectApprovalDialog = false
        })
    },
    submitToPr() {
      const itemArr = []
      for (let i = 0; i < this.selectedData.length; i++) {
        console.log(this.selectedData[i].check_result)
        // no more check item to odoo since 12/14/2023
        // if (this.fpb_category_item_type === 'PR') {
        //   if (this.selectedData[i].check_result.status === -99) {
        //     this.showMsgDialog(
        //       'warning',
        //       `Barang tidak sesuai terdeteksi (${this.selectedData[i].check_result.code}), pastikan seluruh barang sudah sesuai dengan data di odoo sebelum memproses PR !`,
        //       false
        //     )
        //     this.closeSubmitToPRDialog()
        //     return
        //   }
        // }
        itemArr.push({
          id: this.selectedData[i].id,
          product_code: this.selectedData[i].product.code,
          product_name: this.selectedData[i].product.name,
          qty: this.selectedData[i].qty,
          uom: this.selectedData[i].uom,
          description: this.selectedData[i].description,
          note: this.selectedData[i].description
        })
      }
      const form = {
        id: this.getFpbId,
        type:
          this.fpb_category_item_type === 'PR'
            ? 'po'
            : this.fpb_category_item_type.toLowerCase(),
        items: itemArr
      }
      this.showMsgDialog(
        'question',
        'Submit item-item tersebut sekarang ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          this.loader = 'loading'
          const l = this.loader
          this[l] = !this[l]
          axios
            .post(`${this.proc}fpb/odoo/create_pr`, form)
            .then((res) => {
              this[l] = false
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.loadPage()
                this.closeSubmitToPRDialog()
              } else {
                this.showMsgDialog('error', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this[l] = false
            })
          this.loader = null
        } else {
          this.actionValue = 0
          this.closeSubmitToPRDialog()
        }
      })
    },
    closeSubmitToPRDialog() {
      setTimeout(() => {
        this.actionValue = 0
        this.fpb_category_item_type = ''
        this.selectedData = []
        this.submitToPrDialog = false
      }, 200)
    },
    async cancelPr() {
      if (this.getUserProfile.employee.id === this.detail.employee.id) {
        let flag = false
        for (let i = 0; i < this.selectedData.length; i++) {
          if (
            this.selectedData[i].status.id === 1 ||
            this.selectedData[i].status.id === 2
          ) {
            flag = true
          } else {
            flag = false
          }
        }
        if (flag) {
          const groupByPr = Object.groupBy(this.selectedData, (item) => {
            return item.pr_no
          })
          const key = Object.keys(groupByPr)
          const arrJoin = key.join(',')
          this.showMsgDialog2(
            'question',
            'Anda yakin ingin membatalkan PR tersebut ?',
            true,
            arrJoin
          ).then((res) => {
            if (res.isConfirmed) {
              this.loading = true
              axios
                .post(
                  // CARI CARA UNTUK GROUPING ARRAY VALUE DAN AMBIL BEBERAPA VALUENYA
                  // 'http://localhost:9191/api/procurement/v1/fpb/odoo/cancel_pr',
                  `${this.proc}fpb/odoo/cancel_pr`,
                  {
                    pr_no: arrJoin,
                    // pr_no: this.selectedData[0].pr_no,
                    cancel_reason: res.value
                  }
                )
                .then((res) => {
                  if (res.data.status_code === '00') {
                    let status = ''
                    for (let i = 0; i < res.data.data.length; i++) {
                      status =
                        status +
                        // '<p style="font-size:12px;>' +
                        res.data.data[i].pr_no +
                        ' ' +
                        res.data.data[i].status_msg +
                        '\n'
                      // '</p>' +
                      // '<br/>'
                    }
                    this.showMsgDialog('success', status, 'false')
                    this.loadPage()
                  } else {
                    this.showMsgDialog('error', res.data.status_msg, 'false')
                  }
                  this.loading = false
                })
                .catch((err) => {
                  this.actionValue = 0
                  this.loading = false
                  this.showMsgDialog('error', err.message, false)
                })
            } else {
              this.actionValue = 0
            }
          })
          setTimeout(() => {
            this.actionValue = 0
          }, 100)
        } else {
          setTimeout(() => {
            this.actionValue = 0
          }, 100)
          this.showMsgDialog(
            'warning',
            'Hanya barang dengan status "Waiting" and "PR" yang dapat dibatalkan',
            'false'
          )
        }
      } else {
        this.showMsgDialog(
          'warning',
          'Maaf Anda tidak dapat membatalkan PR ini',
          'false'
        )
        setTimeout(() => {
          this.actionValue = 0
        }, 100)
      }
    },
    setToDraftItem() {
      this.showMsgDialog2(
        'question',
        'Anda yakin ingin membatalkan CA ?',
        true
      ).then(async (res) => {
        let successCount = 0
        let failCount = 0
        if (res.isConfirmed) {
          this.loading = true
          for (let i = 0; i < this.selectedData.length; i++) {
            await axios
              .post(`${this.proc}fpb/item/set_to_draft`, {
                id: this.selectedData[i].id,
                settodraft_reason: res.value
              })
              .then((res) => {
                if (res.data.status_code === '00') {
                  successCount = successCount + 1
                } else {
                  failCount = failCount + 1
                }
                return null
              })
              .catch((err) => {
                console.log(err)
                failCount = failCount + 1
                return null
              })
          }

          this.actionValue = 0
          const additionalHtml =
            '<span>' +
            'Finish' +
            '</span>' +
            '<br/>' +
            '<span>' +
            `update berhasil = ${successCount}` +
            '</span>' +
            '<br/>' +
            '<span>' +
            `update gagal = ${failCount}` +
            '</span>'
          this.showMsgDialog('success', additionalHtml, 'false')
          this.loadPage()
          this.loading = false
        } else {
          this.actionValue = 0
        }
      })
    },
    cancelItem() {
      this.showMsgDialog2(
        'question',
        'Anda yakin ingin cancel item ini ?',
        true
      ).then(async (res) => {
        let successCount = 0
        let failCount = 0
        if (res.isConfirmed) {
          this.loading = true
          for (let i = 0; i < this.selectedData.length; i++) {
            await axios
              .post(`${this.proc}fpb/item/cancel`, {
                id: this.selectedData[i].id,
                cancel_reason: res.value
              })
              .then((res) => {
                if (res.data.status_code === '00') {
                  successCount = successCount + 1
                } else {
                  failCount = failCount + 1
                }
                return null
              })
              .catch((err) => {
                console.log(err)
                failCount = failCount + 1
                return null
              })
          }

          this.actionValue = 0
          const additionalHtml =
            '<span>' +
            'Finish' +
            '</span>' +
            '<br/>' +
            '<span>' +
            `cancel berhasil = ${successCount}` +
            '</span>' +
            '<br/>' +
            '<span>' +
            `cancel gagal = ${failCount}` +
            '</span>'
          this.showMsgDialog('success', additionalHtml, 'false')
          this.loadPage()
          this.loading = false
        } else {
          this.actionValue = 0
        }
      })
    },
    paidCa() {
      alert('Cooming Soon')
      setTimeout(() => {
        this.actionValue = 0
      }, 200)
    },

    viewPDF() {
      if (!this.isPdfOpen) {
        this.pdfDialog = true
        this.isPdfOpen = true
        // this.selectedPdf = 'true'
        // this.selectedPdf = pItem.file
        this.countDownTimer()
      }
    },
    countDownTimer() {
      if (this.counter < this.timer) {
        setTimeout(() => {
          this.counter += 1
          this.value += 10
          this.countDownTimer()
        }, 1)
      } else {
        this.showPdf = true
        this.value = 0
        this.counter = 0
      }
    },
    async openHandler(pdfApp) {
      this.info = []
      const info = await pdfApp.pdfDocument
        .getMetadata()
        .catch(console.error.bind(console))

      if (!info) return
      const props = Object.keys(info.info)
      props.forEach((prop) => {
        const obj = {
          name: prop,
          value: info.info[prop]
        }
        this.info.push(obj)
      })
    },
    pagesRendered(pdfApp) {
      setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = 'page-actual'))
    },
    closePdf() {
      this.pdfDialog = false
      this.isPdfOpen = false
      this.showPdf = false
    },
    deleteBatch() {
      this.showMsgDialog(
        'question',
        'Item tersebut akan dihapus, Anda yakin ?',
        true
      ).then((res) => {
        if (res.isConfirmed) {
          for (let i = 0; i < this.selectedData.length; i++) {
            axios
              .delete(
                `${this.proc}fpb/delete_detail/${this.selectedData[i].id}`
              )
              .then((res) => {
                if (i === this.selectedData.length - 1) {
                  if (res.data.status_code === '00') {
                    this.showMsgDialog('success', res.data.status_msg, 'false')
                  } else {
                    this.showMsgDialog('error', res.data.status_msg, 'false')
                  }
                  setTimeout(() => {
                    this.actionValue = 0
                    this.loadPage()
                  }, 200)
                }
              })
              .catch((err) => {
                if (i === this.selectedData.length - 1) {
                  this.showMsgDialog('error', err.message, 'false')
                  setTimeout(() => {
                    this.actionValue = 0
                  }, 200)
                }
              })
          }
        } else {
          this.actionValue = 0
        }
      })
    },
    checkItem(xItem) {
      console.log(xItem)
      this.selectedItemCode = xItem.product.code
      this.odooItem = ''
      if (xItem.check_result == undefined) {
        if (xItem.is_item_match_with_odoo == 1) {
          this.odooItem = {
            id: xItem.id,
            code: xItem.product.code,
            message: 'Item Match',
            name: xItem.product.name,
            status: '00',
            uom: xItem.uom,
            odoo: [
              {
                name: xItem.product.name,
                code: xItem.product.code,
                uom: xItem.uom
              }
            ]
          }
        } else {
          // hit api checking item to odoo
          this.odooItem = {
            id: xItem.id,
            code: xItem.product.code,
            message: 'Item Not Match',
            name: xItem.product.name,
            status: '-99',
            uom: xItem.uom,
            odoo: [
              {
                name: '???',
                code: '???',
                uom: '???'
              }
            ]
          }
        }
      } else {
        this.odooItem = xItem.check_result
        if (xItem.is_item_match_with_odoo == 0 && xItem.product.code == null) {
          this.odooItem = {
            id: xItem.id,
            code: xItem.product.code,
            message: 'Code not found',
            name: xItem.product.name,
            status: '-99',
            uom: xItem.uom,
            odoo: [
              {
                name: xItem.product.name,
                code: xItem.product.code,
                uom: xItem.uom
              }
            ]
          }
        } else if (xItem.is_item_match_with_odoo == 0) {
          Object.assign(this.odooItem, {
            id: xItem.id
          })
          // this.odooItem.id = xItem.id
        }
      }
      setTimeout(() => {
        console.log(this.odooItem)
        this.odooDialog = true
      }, 200)
    },

    closeOdooDialog() {
      this.odooDialog = false
      this.odooItem = null
      this.selectedItemCode = null
    },
    takeFPB() {
      this.showMsgDialog('question', 'Ambil FPB ini ?', true).then((res) => {
        if (res.isConfirmed) {
          this.loader = 'loading'
          const l = this.loader
          this[l] = !this[l]
          axios
            .post(`${this.proc}fpb/take`, {
              document_id: this.getFpbId
            })
            .then((res) => {
              if (res.data.status_code === '00') {
                this.showMsgDialog('success', 'take success', false)
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
              this[l] = false
              this.loadPage()
            })
            .catch((err) => {
              console.log(err)
              this[l] = false
            })
          this.loader = null
        }
      })
    },
    resyncFpbItem() {
      this.showMsgDialog('question', 'Refresh ulang item FPB ?', true).then(
        (res) => {
          if (res.isConfirmed) {
            this.loading = true
            axios
              .post(`${this.proc}fpb/refresh_item`, {
                request_id: this.getFpbId
              })
              .then((res) => {
                this.showMsgDialog(
                  res.data.status_code == '00' ? 'success' : 'warning',
                  res.data.status_msg,
                  false
                )
                this.loading = false
                this.loadPage()
              })
              .catch((err) => {
                console.log(err)
                this.loading = false
              })
          }
        }
      )
    },
    sendItemEqualizationNotification(pItem) {
      let notMatchItem = []
      if (
        this.getDiffHour(this.detail.last_click_equalization_at) >=
          this.config.notifPendingDelay ||
        this.detail.last_click_equalization_at == null
      ) {
        if (pItem == null) {
          if (this.selectedData.length > 0) {
            notMatchItem = this.selectedData
              .filter((item) => {
                return item.is_item_match_with_odoo != 1
              })
              .map((obj) => {
                return obj.id
              })
          } else {
            notMatchItem = this.detail.purchase_request_detail
              .filter((item) => {
                return item.is_item_match_with_odoo != 1
              })
              .map((obj) => {
                return obj.id
              })
          }
        } else {
          notMatchItem.push(pItem.id)
        }

        // console.log('SendEmail', notMatchItem, pItem)

        this.showMsgDialog(
          'question',
          'Kirim notifikasi ke admin untuk melakukan penyesuaian item ?',
          true
        ).then((res) => {
          if (res.isConfirmed) {
            this.loading = true
            axios
              .post(
                `${this.proc}fpb/item/send_notification_equalization_odoo`,
                {
                  id: this.getFpbId,
                  items: notMatchItem
                }
              )
              .then((res) => {
                this.showMsgDialog(
                  res.data.status_code == '00' ? 'success' : 'warning',
                  res.data.status_msg,
                  false
                )

                this.loading = false
                this.loadPage()
              })
              .catch((err) => {
                console.log(err)
                this.loading = false
              })
          }
        })
      } else {
        this.showMsgDialog(
          'warning',
          `Laporan penyesuaian data sudah dilakukan oleh (${
            this.detail.last_click_equalization_by_name
          }) pada jam ${moment(this.detail.last_click_equalization_at).format(
            'HH:mm:SS'
          )}, silahkan kembali lagi nanti setelah ${
            this.config.notifPendingDelay
          } jam berlalu`,
          false
        )
      }
    },
    async getPayreq() {
      const url = `${this.proc}payreq/list?purchase_request_id=${this.detail.id}&offset=0&limit=1000&order_by=id&order_type=DESC`

      await axios
        .get(url)
        .then((res) => {
          console.log('list payreq >>>>', res)
          if (res.data.status_code === '00') {
            this.payreqResult = res.data.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    rowClickPayreq(pValue, pSlot) {
      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/payreq/detail/${pValue.id}?idx=${pSlot.index}`
        )
      }, 200)
    },
    async getGr() {
      const url = `${this.proc}gr/list?purchase_request_id=${this.detail.id}&offset=0&limit=1000&order_by=id&order_type=DESC`

      await axios
        .get(url)
        .then((res) => {
          console.log('list gr >>>>', res)
          if (res.data.status_code === '00') {
            this.grResult = res.data.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    rowClickGr(pValue, pSlot) {
      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/goodsreceipt/detail/${pValue.id}?idx=${pSlot.index}`
        )
      }, 200)
    },
    async updateRealization() {
      const xFlag = false
      if (xFlag) {
        this.loading = true
        await axios
          .post(`${this.proc}fpb/item/update_realization`, {
            id: this.selectedData[0].id,
            realization: this.realization_value
          })
          .then((res) => {
            if (res.data.status_code === '00') {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.loadPage()
            } else {
              this.showMsgDialog('warning', res.data.status_msg, 'false')
            }
            this.actionValue = 0
            this.loading = false
            return null
          })
          .catch((err) => {
            console.log(err)
            this.actionValue = 0
            this.loading = false
            this.showMsgDialog('error', err, 'false')
            return null
          })
      }
    },
    getDiffHour(raw) {
      if (raw !== null) {
        const timeStart = moment(raw)
        const timeEnd = moment(new Date())
        var diff_s = timeEnd.diff(timeStart)
        var duration = moment.duration(diff_s)
        var hours = duration.asHours()
        return hours
      }
    },

    paymentTypeName(val) {
      const arrType = ['Tunai', 'Bank', 'Giro', 'Cek', 'Transfer']
      return arrType[val - 1]
    },
    payreqTypeName(val) {
      const arrType = ['CA', 'Reimburs', 'Pelunasan PO', 'Lain-lain']
      return arrType[val - 1]
    },
    payreqStatusName(val) {
      const arrType = [
        'DRAFT',
        'WAITING APPROVAL',
        'IN PROGRESS',
        'PAID',
        'DONE',
        'CANCEL',
        'REJECT'
      ]
      return arrType[val]
    },
    payreqStatusColor(val) {
      const arrType = [
        'grey',
        'orange',
        'green',
        'green',
        'green',
        'red',
        'red'
      ]
      return arrType[val]
    },
    grStatusName(val) {
      const arrType = ['DRAFT', 'DONE', 'CANCEL']
      return arrType[val]
    },
    grStatusColor(val) {
      const arrType = ['grey', 'green', 'red']
      return arrType[val]
    },

    statusName(val) {
      if (val == -1) {
        return 'REJECTED'
      } else {
        const arrType = [
          'DRAFT',
          'WAITING APPROVAL',
          'IN PROGRESS',
          'CLOSE',
          'CANCEL',
          'PENDING'
        ]
        return arrType[val]
      }
    },
    statusColor(val) {
      if (val == -1) {
        return 'red'
      } else {
        const arrType = ['grey', 'orange', 'green', '#4caf50', 'red', '#1976d2']
        return arrType[val]
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fpb-title {
  margin: auto;
  width: 50%;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.items-total-div-1 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  min-height: 150px;
  width: 100%;
  justify-content: space-between;
  .items-total-div-2 {
    // padding: 10px 0;
    width: '40%';
    .items-total-box {
      border: 1px solid #e0e0e0;
      padding: 20px 10px;
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      font-size: 14px;
      div {
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        padding-top: 20px;
        display: flex;
        p {
          margin: 0;
          margin-right: 10px;
          min-width: 80px;
          position: relative;
          span {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  .items-total-div-3 {
    padding: 0 10px;
    width: 60%;
    font-size: 14px;
  }
}
.actionButton {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 15px;
}
.fpb-status-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.category-line {
  margin: 10px 0 10px 0;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  line-height: 75px;
}
@media (max-width: 1200px) {
  .category-line-item {
    margin-bottom: 10px;
  }
}
@media (max-width: 808px) {
  .actionButton {
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 2;
    padding: 10px 0;
  }
  .items-total-div-1 {
    flex-direction: column-reverse;
    .items-total-div-2 {
      width: '100%';
      .items-total-box {
        width: 100%;
      }
    }

    .items-total-div-3 {
      margin-top: 10px;
      width: 100%;
    }
  }
  .tipe-proses {
    margin-top: 5px;
  }
}
// @media (max-width: 980px) {
//   .category-line {
//     // max-width: 750px;
//     flex-wrap: wrap;
//   }
// }

@media (max-width: 840px) {
  .fpb-status-section {
    // flex-direction: column;
    flex-wrap: wrap;
  }
}
@media (max-width: 780px) {
  .fpb-title {
    font-size: 3vw;
  }
}
// @media (max-width: 701px) {
//   .items-total-div-1 {
//     flex-direction: column-reverse;
//     .items-total-div-2 {
//       width: '100%';
//       .items-total-box {
//         width: 100%;
//       }
//     }

//     .items-total-div-3 {
//       margin-top: 10px;
//       width: 100%;
//     }
//   }
// }
</style>
