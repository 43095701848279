<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="45%">
      <template v-slot:activator="{ on, attrs }">
        <p id="addItemMovement" v-bind="attrs" v-on="on" @click="add"></p>
      </template>
      <v-card style="position: relative">
        <v-form
          ref="entryForm"
          @submit.prevent="submit"
          style="position: relative"
        >
          <v-card
            flat
            style="
              z-index: 2;
              position: sticky;
              top: 0;
              background: white;
              border-bottom: 1px solid black;
              margin-bottom: 10px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
              "
            >
              <v-toolbar-title class="overline"
                ><p style="font-size: 20px; margin: 0; font-weight: bold">
                  Add Item
                </p>
              </v-toolbar-title>
            </div>
            <div>
              <v-btn
                large
                type="submit"
                color="primary"
                class="ma-2"
                style="color: white"
              >
                simpan
              </v-btn>

              <v-btn
                large
                color="red"
                class="ma-2"
                style="color: white"
                @click="close"
              >
                Batal
              </v-btn>
            </div>
          </v-card>

          <v-row no-gutters style="padding: 15px">
            <v-row
              no-gutters
              v-if="
                getUserProfile.employee.company.plant_id === '6' &&
                (getUserProfile.employee.department_id === 1 ||
                  getUserProfile.employee.department_id === 3)
              "
            >
              <v-col
                v-if="
                  getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                md="12"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0; margin-bottom: 10px">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Company
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0"
                    ><v-select
                      v-model="company_id"
                      :items="dropDownCompany"
                      outlined
                      item-text="name"
                      item-value="id"
                      return-id
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
            </v-row>
            <v-col md="12" style="padding: 0; margin-bottom: 20px">
              <div style="padding: 0 10px">
                <v-col cols="4" style="padding: 0; margin-bottom: 10px">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Nama Aset
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0"
                  ><v-autocomplete
                    v-model="selectedAsset"
                    @change="selectedAssetWatcher"
                    outlined
                    item-text="name"
                    item-id="id"
                    hide-details
                    hide-no-data
                    return-object
                    label="- ketik disini -"
                    :loading="loading"
                    :items="result"
                    :search-input.sync="search"
                    @keydown.enter.prevent
                    clearable
                  ></v-autocomplete>
                  <!-- @keydown.enter.prevent="enterSearch(search)" -->
                </v-col>
              </div>
            </v-col>
            <v-col md="12" style="padding: 0; margin-bottom: 20px">
              <div style="padding: 0 10px">
                <v-col cols="4" style="padding: 0; margin-bottom: 10px">
                  <p
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Pilih Lot
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0"
                  ><v-autocomplete
                    v-model="selectedlot"
                    chips
                    deletable-chips
                    multiple
                    small-chips
                    outlined
                    item-text="name"
                    item-id="id"
                    hide-details
                    hide-no-data
                    return-object
                    clearable
                    :items="lotDetail !== null ? lotDetail : []"
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
import { mapGetters } from 'vuex'
export default {
  name: 'Movement-Form-Add-Item',
  components: {},
  data() {
    return {
      asset2: buildType.apiURL('asset2'),
      asset: buildType.apiURL('asset'),
      dialog: false,
      search: null,
      loading: false,
      selectedAsset: [],
      result: [],

      selectedlot: [],
      lotDetail: null,
      dropDownCompany: [],
      company_id: 0
    }
  },
  watch: {
    search(val) {
      console.log(val)
      if (this.search === null || this.search === '') {
        return (this.result = [])
      }
      return val && val !== this.select && this.querySelections(val)
    },
    company_id() {
      this.result = []
      // this.selectedAsset = []
      // this.search = null
      // this.selectedlot = []
      // this.lotDetail = null
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    selectedAssetWatcher(val) {
      console.log(val)
      if (this.selectedAsset === null) {
        return (this.selectedAsset = [])
      }
      return this.initLot()
    },
    // enterSearch(val) {
    //   val && val !== this.select && this.querySelections(val)
    // },
    querySelections(v) {
      this.loading = true
      axios
        .get(
          `${this.asset}asset/dropdown?keyword=${v}&company_id=${
            this.company_id === 0 ? '' : this.company_id
          }`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            this.result = res.data.data
          }
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
        })
    },

    initLot() {
      axios
        .get(
          `${this.asset}asset/lot/dropdown?asset=${
            this.selectedAsset.id
          }&company_id=${this.company_id === 0 ? '' : this.company_id}`
        )
        .then((res) => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.lotDetail = res.data.data
          }
        })
        .catch((err) => {
          console.log(err)
          // this.showMsgDialog(
          //   'error',
          //   err
          //     ? 'Something went wrong, Please contact an admin!'
          //     : 'Something went wrong, Please contact an admin!',
          //   false
          // )
        })
    },
    clear() {
      this.$emit('loadPage')

      this.result = []
      this.selectedAsset = []
      this.search = null
      this.selectedlot = []
      this.lotDetail = null
      this.dropDownCompany = []
      this.company_id = 0
    },
    close() {
      this.clear()
      this.dialog = false
    },
    add() {
      const application = this.getUserProfile.level
      const result = application.find(({ id }) => id === '1' || id === '29')
      if (result !== undefined) {
        const company = [
          { id: 1, name: 'TMP' },
          { id: 2, name: 'IMP' },
          { id: 3, name: 'GIT' },
          { id: 4, name: 'KAJ' },
          { id: 5, name: 'WIM' },
          { id: 6, name: 'SMI' }
        ]

        if (application.find(({ id }) => id === '1') !== undefined) {
          this.dropDownCompany = company
        } else if (
          application.find(({ id }) => id === '29') !== undefined &&
          this.getUserProfile.employee.company.plant_id === '6'
        ) {
          this.dropDownCompany = company
        }
        return (this.company_id = this.getUserProfile.employee.company.plant_id)
      }
      //  else {
      //   this.dropDownCompany = [
      //     {
      //       id: this.getUserProfile.sanqua_company_id,
      //       name: this.getUserProfile.sanqua_company_name
      //     }
      //   ]
      // }
    },
    submit() {
      this.$emit('getLot', this.selectedlot)
      this.close()
    }
  }
}
</script>
<style lang="scss" scoped></style>
