<template>
  <div class="fluid" id="masterjob">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
x
    <v-row>
      <v-col
        cols="12"
        class="d-flex"
        style="
          position: relative;
          padding: 0 20px;
          text-align: center;
        "
      >
        <h4 class="indigo--text" style="font-weight: bold; margin: auto">
          MASTER PEKERJAAN
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="refreshTable"
                :loading="refreshingTable"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
        </h4>
      </v-col>
    </v-row>

    <div v-if="paramAPI != null" class="masterjob-list">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="
            position: relative;
            padding: 0 20px;
            text-align: center;
            margin-top: 20px;
          "
        >
          <v-row>
            <v-col cols="2">
              <div style="display: flex; flex-direction: flex; gap: 8px">
                <v-btn
                  outlined
                  elevation="1"
                  color="indigo"
                  class="indigo--text font-weight-bold"
                  style="font-size: 12px"
                  @click="openJobDialog"
                >
                  Tambah Lowongan
                </v-btn>
              </div>
            </v-col>
            <!-- <v-col cols="2"></v-col> -->
            <v-col cols="2">
              <v-select
                @change="companyWatcherFilter"
                v-model="paramAPI.company_id"
                :items="dropdown.companyFilter"
                style="height: 50px"
                label="Perusahaan"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                :disabled="isDisableCompanyDropdown"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                @change="departmentWatcher"
                v-model="paramAPI.department_id"
                :items="dropdown.departmentFilter"
                :disabled="paramAPI.company_id == null"
                style="height: 50px"
                label="Department"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                clearable
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                @change="positionWatcher"
                v-model="paramAPI.position_id"
                :items="dropdown.position"
                style="height: 50px"
                label="Jabatan"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                clearable
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                @change="typeWatcher"
                v-model="paramAPI.employement_type"
                :items="dropdown.employee_type"
                style="height: 50px"
                label="Jenis"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                clearable
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                @change="statusWatcher"
                v-model="paramAPI.status"
                :items="dropdown.status"
                style="height: 50px"
                label="Status"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                clearable
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="d-flex"
          style="
            position: relative;
            padding: 0 20px;
            text-align: center;
          "
        >
          <v-row>
            <v-col cols="2">
              <v-select
                v-model="actionValue"
                @change="actionWatcher"
                :disabled="selectedItem.length < 1"
                :items="[
                  { id: 0, name: 'Delete' },
                  { id: 1, name: 'Buka Lowongan' },
                  { id: 2, name: 'Tutup Lowongan' },
                ]"
                style="
                  position: relative;
                  font-size: 12px;
                  width: 170px;
                "
                label="Action"
                item-text="name"
                item-value="id"
                return-id
                clearable
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="4" style="margin-left: auto;">
              <v-text-field
                v-model="paramAPI.keyword"
                label="Pencarian"
                type="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="ketik nama / lokasi lalu enter"
                outlined
                dense
                clearable
                @click:clear="keywordWatcher(null)"
                @keyup.enter="keywordWatcher"
                :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-col cols="12">
      <v-data-table
        v-model="selectedItem"
        mobile-breakpoint="0"
        fixed-header
        height="50vh"
        :headers="headers"
        style="cursor: pointer; margin: 0 20px"
        :items="result"
        :loading="loading"
        @click:row="rowClick"
        item-key="id"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
        }"
        show-select
      >
        <template v-slot:[`item.name`]="{ item }">
          <div style="width: 120px">{{item.name}}</div>
        </template>
        <template v-slot:[`item.company_name`]="{ item }">
          <div style="font-size:12px; width: 120px">{{item.company_name}}</div>
        </template>
        <template v-slot:[`item.department_name`]="{ item }">
          <div style="font-size:12px;">{{item.department_name}}</div>
        </template>
        <template v-slot:[`item.position`]="{ item }">
          <div style="font-size:12px;">{{item.position}}</div>
        </template>
        <template v-slot:[`item.location`]="{ item }">
          <div style="width: 120px;">{{item.location}}</div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div style="font-weight: bold; font-size:12px;">
          <v-chip small :color="`${jobStatusColor(item.status)}`">
            {{
              jobStatus(item.status)
            }}
          </v-chip>
          </div>
        </template>
        <template v-slot:[`item.employement_type`]="{ item }">
          <div>
            {{typeName(item.employement_type)}}
          </div>
        </template>
        <template v-slot:[`item.deadline`]="{ item }">
          <div style="width: 120px;">{{formattedDate(item.deadline)}}</div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <div style="font-size:12px;">{{formattedDate(item.createdAt)}}</div>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          <div style="font-size:12px;">{{formattedDate(item.updatedAt)}}</div>
        </template>
        <!-- <template v-slot:[`item.action`]="{ item }">
          <v-btn @click.stop="showConfirmAction(item)" color="red" x-small>
            <v-icon small color="white">mdi-delete</v-icon>
          </v-btn>
        </template> -->
      </v-data-table>
    </v-col>

    <div class="formJobDialog">
      <v-dialog v-if="form != null" v-model="showOpenJob" max-width="600px" persistent>
        <v-card>
          <v-card-title style="position: sticky; top:0px; background-color: white; z-index: 10;" class="headline indigo--text">
            {{ form.act == 'update' ? 'Edit' : 'Tambah' }} Lowongan
            <v-btn text small fab icon @click="closeJobDialog" style="position: absolute; top: 10px; right: 10px;">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form :disabled="form.act == 'update'" @submit.prevent="addJob">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    @keydown.enter.prevent
                    label="Posisi *"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    label="Jenis Pekerjaan"
                    @keydown.enter.prevent
                    v-model="form.employement_type"
                    :items="dropdown.employee_type"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    clearable
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    @keydown.enter.prevent
                    label="Jabatan"
                    v-model="form.position"
                    :items="dropdown.position"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    @keydown.enter.prevent
                    label="Perusahaan *"
                    v-model="form.company"
                    :items="dropdown.company"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    clearable
                    @change="companyWatcher"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    @keydown.enter.prevent
                    label="Department"
                    v-model="form.department"
                    :items="dropdown.department"
                    :disabled="form.act == 'update' || form.company == null"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    @keydown.enter.prevent
                    v-model="form.location"
                    label="Lokasi Penempatan *"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    @keydown.enter.prevent
                    label="Batas Pendaftaran"
                    v-model="form.deadline"
                    clearable
                    dense
                    type="date"
                    name="date"
                    step="1"
                    :disabled="false"
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    @keydown.enter.prevent
                    label="Status"
                    v-model="form.status"
                    :items="dropdown.status"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    :disabled="false"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <p style="margin:0; font-size: 14px;">Keterangan Tambahan</p>
                  <vue-editor
                    class="textEditor"
                    style="min-height:100px;"
                    v-model="form.requirements"
                    clearable
                  ></vue-editor>
                </v-col>
              </v-row>
              <!-- <v-textarea
                label="Requirement"
                dense
                auto-grow
                rows="3"
                v-model="form.requirements"
              />
              <v-textarea
                label="Keterangan Lain-lain"
                dense
                auto-grow
                rows="3"
                v-model="form.description"
              /> -->
              <v-card-actions>
                <v-btn style="width: 100%; margin-top:30px;" type="submit" color="primary">Simpan</v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="showAlert" :color="alertType" top>
        {{ alertMessage }}
      </v-snackbar>
    </div>
    <v-dialog v-model="confirmDeleteData" max-width="400px">
      <v-card>
        <v-card-title class="headline red--text">
          Konfirmasi Hapus
        </v-card-title>
        <v-card-text> {{ selectedItem.length }} data akan dihapus, Apakah Anda yakin ? </v-card-text>
        <v-card-actions>
          <v-btn color="green" dark @click="eraseData(true)">Ya</v-btn>

          <v-btn color="red" dark @click="eraseData(false)">Batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmOpenJob" max-width="400px">
      <v-card>
        <v-card-title class="headline primary--text">
          Konfirmasi Update
        </v-card-title>
        <v-card-text> {{ selectedItem.length }} lowongan akan dibuka, Apakah Anda yakin ? </v-card-text>
        <v-card-actions>
          <v-btn color="green" dark @click="changeStatusOpen(true)">Ya</v-btn>

          <v-btn color="red" dark @click="changeStatusOpen(false)">Batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmCloseJob" max-width="400px">
      <v-card>
        <v-card-title class="headline primary--text">
          Konfirmasi Update
        </v-card-title>
        <v-card-text> {{ selectedItem.length }} lowongan akan ditutup, Apakah Anda yakin ? </v-card-text>
        <v-card-actions>
          <v-btn color="green" dark @click="changeStatusClose(true)">Ya</v-btn>

          <v-btn color="red" dark @click="changeStatusClose(false)">Batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import buildType from '../../../services/buildType'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      build: process.env.VUE_APP_BUILD_TYPE,
      recruitmentApi: buildType.apiURL('recruitment'),
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      showAlert: false,
      alertMessage: '',
      alertType: '',
      loading: false,
      dialog: false,
      selectedItem: [],
      refreshingTable: false,
      options: {},
      showOpenJob: false,
      form: null,
      confirmDeleteData: false,
      confirmOpenJob: false,
      confirmCloseJob: false,
      headers: [
        { text: 'Posisi', value: 'name', sortable: false },
        { text: 'Jabatan', value: 'position_name', sortable: false },
        { text: 'Department', value: 'department_name', sortable: false },
        { text: 'Perusahaan', value: 'company_name', sortable: false },
        { text: 'Lokasi', value: 'location', sortable: false },
        { text: 'Jenis', value: 'employement_type', sortable: false },
        { text: 'Batas Pendaftaran', value: 'deadline', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Tgl. Buat', value: 'createdAt', sortable: false },
        { text: 'Tgl. Update', value: 'updatedAt', sortable: false }
        // { text: 'Action', value: 'action', sortable: false }
      ],
      result: [],
      actionValue: null,
      paramAPI: null,
      isDisableCompanyDropdown: false,
      totalData: 0,
      dropdown: {
        employee_type: [
          {
            id: 1,
            name: 'Full Time'
          },
          {
            id: 2,
            name: 'Part Time'
          },
          {
            id: 3,
            name: 'Intership'
          }
          // {
          //   id: 4,
          //   name: 'Lain-lain'
          // }
        ],
        company: [],
        department: [],
        position: [],
        status: [
          {
            id: 0,
            name: 'Close'
          },
          {
            id: 1,
            name: 'Open'
          }
        ],
        companyFilter: [],
        departmentFilter: []
      }
    }
  },
  async mounted() {
    this.setParamVariable()
    setTimeout(async () => {
      await this.getDataFromApi()
      this.dropdownCompanyFilter()
      this.dropdownPosition()
    }, 300)
  },
  computed: {
    ...mapGetters([
      'getUserProfile'
    ])
  },
  methods: {
    actionWatcher(val) {
      if (val == null) {
        // reset param
      }

      if (val == 0) {
        // delete
        this.showConfirmAction(0)
      }
      if (val == 1) {
        // Open
        this.showConfirmAction(1)
      }
      if (val == 2) {
        // update status
        this.showConfirmAction(2)
      }
    },
    companyWatcherFilter(v) {
      this.paramAPI.department_id = null

      if (v != null) {
        this.dropdownDepartmentFilter()
      } else {
        this.dropdown.departmentFilter = []
      }
      this.getDataFromApi()
    },
    companyWatcher(v) {
      this.form.department = null

      if (v != null) {
        this.dropdownDepartment()
      } else {
        this.dropdown.department = []
      }
    },
    departmentWatcher(v) {
      this.getDataFromApi()
    },
    typeWatcher(v) {
      this.getDataFromApi()
    },
    positionWatcher(v) {
      this.getDataFromApi()
    },
    statusWatcher(v) {
      this.getDataFromApi()
    },
    keywordWatcher(v) {
      if (v == null) {
        this.paramAPI.keyword = ''
      }
      this.getDataFromApi()
    },
    async dropdownCompanyFilter() {
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.companyFilter = res.data.data
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    async dropdownCompany() {
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    async dropdownDepartment() {
      let url = `${this.hrsApi}master/universal/department/dropdown`
      if (this.form.company != null) {
        url = url + `?filter=[{"company_id":${this.form.company.id}}]`
      } else {
        if (this.paramAPI.company_id != null) {
          url = url + `?filter=[{"company_id":${this.paramAPI.company_id.id}}]`
        }
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('department>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async dropdownDepartmentFilter() {
      let url = `${this.hrsApi}master/universal/department/dropdown`
      if (this.paramAPI.company_id != null) {
        url = url + `?filter=[{"company_id":${this.paramAPI.company_id}}]`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('department>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.departmentFilter = res.data.data)
          }
          return (this.dropdown.departmentFilter = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.departmentFilter = [])
        })
    },
    async dropdownPosition() {
      const url = `${this.hrsApi}master/universal/employeelevel/dropdown`
      await axios
        .get(url)
        .then((res) => {
          this.dropdown.position = res.data.data
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    refreshTable() {
      this.setParamVariable()
      this.getDataFromApi()
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          setTimeout(() => {
            if (data.status_code == '00') {
              this.result = data.data
              this.totalData = data.total_record
            }
            this.loading = false
          }, 1000)
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          return (this.result = [])
        })
    },
    async initDataTable() {
      return await new Promise((resolve, reject) => {
        let url = `${this.recruitmentApi}master/universal/job/list?keyword=${
        this.paramAPI.keyword
        }&order_by=${this.paramAPI.sortBy}&order_type=${
          this.paramAPI.sortType
        }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
          this.paramAPI.limit
        }`
        const filter = []
        if (this.paramAPI.is_archived != null) {
          url = url + `&isArchived=${this.paramAPI.is_archived}`
        }
        if (this.paramAPI.status != null) {
          filter.push(`{"status": ${this.paramAPI.status}}`)
        }
        if (this.paramAPI.company_id != null) {
          filter.push(`{"company_id": ${this.paramAPI.company_id}}`)
        }
        if (this.paramAPI.department_id != null) {
          filter.push(`{"department_id": ${this.paramAPI.department_id}}`)
        }
        if (this.paramAPI.position_id != null) {
          filter.push(`{"position_id": ${this.paramAPI.position_id}}`)
        }
        if (this.paramAPI.employement_type != null) {
          filter.push(`{"employement_type": ${this.paramAPI.employement_type}}`)
        }
        if (filter.length > 0) {
          url = url + `&filter=[${filter}]`
        }
        axios
          .get(url)
          .then((res) => {
            console.log('response>>>', res)
            // if (res.data.status_code == '00') {
            resolve(res.data)
            // }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async openJobDialog() {
      this.form = {
        act: 'add',
        name: null,
        company: null,
        department: null,
        position: null,
        description: null,
        requirements: null,
        employement_type: null,
        deadline: null,
        location: null,
        status: null
      }
      this.dropdownCompany()
      setTimeout(() => {
        this.showOpenJob = true
      }, 300)
    },
    closeJobDialog() {
      this.showOpenJob = false
      this.dropdown.company = []
      this.dropdown.department = []
      this.dropdown.position = []
      setTimeout(() => {
        this.form = null
      }, 500)
    },
    async rowClick(item) {
      // this.showDetail(item)
      this.form = {
        act: 'update',
        id: item.id,
        name: item.name,
        company: {
          id: item.company_id,
          name: item.company_name
        },
        department: {
          id: item.department_id,
          name: item.department_name
        },
        position: {
          id: item.position_id,
          name: item.position_name
        },
        description: item.description,
        requirements: item.requirements,
        employement_type: item.employement_type,
        deadline: moment(item.deadline).format('YYYY-MM-DD'),
        location: item.location,
        status: item.status
      }
      this.dropdownCompany()
      if (item.department_id != null) {
        this.dropdownDepartment()
      }
      setTimeout(() => {
        this.showOpenJob = true
      }, 300)
    },
    // showDetail(item) {
    //   this.selectedItem = item
    //   this.dialog = true
    // },
    async addJob() {
      try {
        if (this.form.name == null || this.form.name == '') {
          return alert('Kolom nama tidak boleh kosong')
        }
        if (this.form.company == null || this.form.company == '') {
          return alert('Kolom perusahaan tidak boleh kosong')
        }
        if (this.form.location == null || this.form.location == '') {
          return alert('Kolom lokasi tidak boleh kosong')
        }
        // if (this.form.employement_type == null || this.form.employement_type == '') {
        //   return alert('Kolom jenis pekerjaan tidak boleh kosong')
        // }
        const payload = {
          act: this.form.act,
          name: this.form.name,
          // description: this.form.description,
          requirements: this.form.requirements,
          employement_type: this.form.employement_type,
          deadline: this.form.deadline,
          location: this.form.location,
          status: this.form.status,
          is_check_name: true,
          filter: []
        }
        if (this.form.company != null) {
          Object.assign(payload, {
            company_id: this.form.company.id,
            company_name: this.form.company.name
          })
          payload.filter.push({ company_id: this.form.company.id })
        }
        if (this.form.department != null) {
          Object.assign(payload, {
            department_id: this.form.department.id,
            department_name: this.form.department.name
          })
        }
        if (this.form.position != null) {
          Object.assign(payload, {
            position_id: this.form.position.id,
            position_name: this.form.position.name
          })
        }
        if (this.form.act == 'update') {
          Object.assign(payload, { id: this.form.id })
        }

        this.loading = true
        axios
          .post(`${this.recruitmentApi}master/universal/job/save`, payload)
          .then((res) => {
            console.log('response>>>', res)
            this.showAlert = true
            if (res.data.status_code == '00') {
              this.getDataFromApi()
              this.alertMessage = res.data.status_msg
              this.alertType = 'success'
              this.closeJobDialog()
            } else {
              this.alertMessage = res.data.status_msg
              this.alertType = 'error'
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            throw new Error(`Terjadi kesalahan saat menambahkan pekerjaan. ${err}`)
          })
      } catch (e) {
        this.loading = false
        console.error(e)
        alert(e.message)
      }
    },
    formattedDate(val) {
      let date = '-'
      if (val !== null) {
        const a = new Date(val)
        const b = a.toLocaleDateString('id')
        date = b
      }

      return date
    },
    showConfirmAction(val) {
      console.log('delete clicked')
      setTimeout(() => {
        if (val == 0) {
          this.confirmDeleteData = true
        }
        if (val == 1) {
          this.confirmOpenJob = true
        }
        if (val == 2) {
          this.confirmCloseJob = true
        }
      }, 100)
    },
    async eraseData(bool) {
      if (bool) {
        this.loading = true
        const arrSuccess = []
        const arrFail = []
        let i = 0
        while (
          (await new Promise((resolve, reject) => {
            axios
              .put(`${this.recruitmentApi}master/universal/job/archive/${this.selectedItem[i].id}`)
              .then((res) => {
                if (res.data.status_code === '00') {
                  arrSuccess.push({
                    index: i,
                    msg: res.data.status_msg
                  })
                } else {
                  arrFail.push({
                    index: i,
                    msg: res.data.status_msg
                  })
                }
                resolve(i++)
              })
              .catch((err) => {
                console.log(err)
                arrFail.push({
                  index: i,
                  msg: 'error'
                })
                reject(new Error(i++))
              })
          })) <
          this.selectedItem.length - 1
        ) {
          console.log('cek ' + i)
        }

        this.getDataFromApi()
        setTimeout(() => {
          this.showAlert = true
          this.alertMessage = `${arrSuccess.length} data berhasil di delete | ${arrFail.length} data gagal di reset`
          this.alertType = 'success'
          this.loading = false
          this.actionValue = null
          this.confirmDeleteData = false
          this.selectedItem = []
        }, 300)
      } else {
        setTimeout(() => {
          this.actionValue = null
          this.confirmDeleteData = false
          this.selectedItem = []
        }, 100)
      }
    },
    async changeStatusOpen(bool) {
      if (bool) {
        this.loading = true
        const arrSuccess = []
        const arrFail = []
        let i = 0
        while (
          (await new Promise((resolve, reject) => {
            const payload = {
              act: 'update',
              id: this.selectedItem[i].id,
              status: 1
            }
            axios
              .post(`${this.recruitmentApi}master/universal/job/save`, payload)
              .then((res) => {
                if (res.data.status_code === '00') {
                  arrSuccess.push({
                    index: i,
                    msg: res.data.status_msg
                  })
                } else {
                  arrFail.push({
                    index: i,
                    msg: res.data.status_msg
                  })
                }
                resolve(i++)
              })
              .catch((err) => {
                console.log(err)
                arrFail.push({
                  index: i,
                  msg: 'error'
                })
                reject(new Error(i++))
              })
          })) <
          this.selectedItem.length - 1
        ) {
          console.log('cek ' + i)
        }

        this.getDataFromApi()
        setTimeout(() => {
          this.showAlert = true
          this.alertMessage = `Update data selesai, ${arrSuccess.length} berhasil | ${arrFail.length} gagal`
          this.alertType = ''
          this.loading = false
          this.actionValue = null
          this.confirmOpenJob = false
          this.selectedItem = []
        }, 300)
      } else {
        setTimeout(() => {
          this.confirmOpenJob = false
          this.actionValue = null
          this.selectedItem = []
        }, 100)
      }
    },
    async changeStatusClose(bool) {
      if (bool) {
        this.loading = true
        const arrSuccess = []
        const arrFail = []
        let i = 0
        while (
          (await new Promise((resolve, reject) => {
            const payload = {
              act: 'update',
              id: this.selectedItem[i].id,
              status: 0
            }
            axios
              .post(`${this.recruitmentApi}master/universal/job/save`, payload)
              .then((res) => {
                if (res.data.status_code === '00') {
                  arrSuccess.push({
                    index: i,
                    msg: res.data.status_msg
                  })
                } else {
                  arrFail.push({
                    index: i,
                    msg: res.data.status_msg
                  })
                }
                resolve(i++)
              })
              .catch((err) => {
                console.log(err)
                arrFail.push({
                  index: i,
                  msg: 'error'
                })
                reject(new Error(i++))
              })
          })) <
          this.selectedItem.length - 1
        ) {
          console.log('cek ' + i)
        }

        this.getDataFromApi()
        setTimeout(() => {
          this.showAlert = true
          this.alertMessage = `Update data selesai, ${arrSuccess.length} berhasil | ${arrFail.length} gagal`
          this.alertType = ''
          this.loading = false
          this.actionValue = null
          this.selectedItem = []
          this.confirmCloseJob = false
        }, 300)
      } else {
        setTimeout(() => {
          this.confirmCloseJob = false
          this.selectedItem = []
          this.actionValue = null
        }, 100)
      }
    },
    setParamVariable() {
      this.paramAPI = {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: null,
        sortBy: 'name',
        sortType: 'asc',
        itemsPerPage: 10,
        company_id: null,
        department_id: null,
        position_id: null,
        employement_type: null,
        is_archived: 0
      }
    },
    jobStatusColor(val) {
      const color = ['red', 'green']
      if (val != null) {
        return color[val]
      }
      return 'black'
    },
    jobStatus(val) {
      const status = ['Close', 'Open']
      if (val != null) {
        return status[val]
      }
      return ''
    },
    typeName(val) {
      if (val != null) {
        const name = this.dropdown.employee_type.find(
          ({ id }) => id == val
        ).name
        return name
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
#masterjob {
  position: relative;
  .masterjob-list {
    margin: 10px 20px;
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .recruit-toolbar-1 {
      margin-left: auto;
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid rgba(255, 255, 255, 0.2);
  border-top: 16px solid #2e2be2;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gantistatus {
  margin-top: 20px;
  width: 100%;
}
</style>
