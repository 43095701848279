<template>
  <div class="fluid" id="masterjob">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>

    <v-row>
      <v-col
        cols="12"
        class="d-flex"
        style="
          position: relative;
          padding: 0 20px;
          text-align: center;
          margin-top: 20px;
        "
      >
        <h4 class="indigo--text" style="font-weight: bold; margin: auto">
          Data Posisi Dilamar
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="refreshTable"
                :loading="refreshingTable"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
        </h4>
      </v-col>
    </v-row>
    <div v-if="paramAPI != null" class="masterjob-list">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="
            position: relative;
            padding: 0 20px;
            text-align: center;
            margin-top: 20px;
          "
        >
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                @change="positionWatcher"
                v-model="paramAPI.job_id"
                :items="dropdown.job"
                style="height: 50px"
                label="Posisi"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                @change="companyWatcher"
                v-model="paramAPI.company_id"
                :items="dropdown.company"
                style="height: 50px"
                label="Lokasi Penempatan"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                @change="statusWatcher"
                v-model="paramAPI.status"
                :items="dropdown.status"
                style="height: 50px"
                label="Status"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="d-flex"
          style="position: relative; padding: 0 20px; text-align: center"
        >
          <v-row>
            <v-col cols="2">
              <v-select
                v-model="actionValue"
                @change="actionWatcher"
                :disabled="selectedItem.length < 1"
                :items="dropdown.action"
                style="position: relative; font-size: 12px; width: 170px"
                label="Action Update"
                item-text="name"
                item-value="id"
                return-object
                clearable
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="4" style="margin-left: auto">
              <v-text-field
                v-model="paramAPI.keyword"
                label="Pencarian"
                type="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="ketik nama / lokasi lalu enter"
                outlined
                dense
                clearable
                @click:clear="keywordWatcher(null)"
                @keyup.enter="keywordWatcher"
                :disabled="loading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-col cols="12">
      <v-data-table
        v-model="selectedItem"
        mobile-breakpoint="0"
        fixed-header
        height="50vh"
        :headers="headers"
        style="cursor: pointer; margin: 0 20px"
        :items="result"
        :loading="loading"
        @click:row="rowClick"
        item-key="id"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
        }"
        show-select
      >
        <template v-slot:[`item.job_name`]="{ item }">
          <div>
            {{ item.job.name }}
          </div>
        </template>
        <template v-slot:[`item.company_name`]="{ item }">
          <div>
            {{ item.job.company_name }}
          </div>
        </template>
        <template v-slot:[`item.candidate_name`]="{ item }">
          <div>
            {{ item.applicant.name }}
          </div>
        </template>
        <template v-slot:[`item.candidate_email`]="{ item }">
          <div>
            {{ item.applicant.email }}
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div :style="`color:${statusColor(item.status)}`">
            {{ statusName(item.status) }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">{{
          formattedDate(item.createdAt)
        }}</template>
        <template v-slot:[`item.updatedAt`]="{ item }">{{
          formattedDate(item.updatedAt)
        }}</template>
        <template v-slot:[`item.view`]="{ item }">
          <v-btn
            @click.stop="openCv(item)"
            color="indigo"
            class="white--text"
            x-small
          >
            CV
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="changeStatusDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline primary--text">
          Konfirmasi Update
        </v-card-title>
        <v-card-text v-if="actionValue != null">
          Update status {{ selectedItem.length }} aplikasi lamaran menjadi
          {{ actionValue.name }}, Apakah Anda yakin ?
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="changeStatusNotes"
            placeholder="Tulis Keterangan Tambahan"
            dense
            auto-grow
            rows="3"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" dark @click="changeStatusBatch(true)">Ya</v-btn>

          <v-btn color="red" dark @click="changeStatusBatch(false)"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="changeStatusDialog2" max-width="400px">
      <v-card v-if="detail != null">
        <v-card-title class="headline primary--text">
          Konfirmasi Update
        </v-card-title>
        <v-card-text>
          Status lamaran akan dirubah, Apakah Anda yakin ?
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="detail.changeNotes"
            placeholder="Tulis Keterangan Tambahan"
            dense
            auto-grow
            rows="3"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" dark @click="changeStatusSelf(true)">Ya</v-btn>

          <v-btn color="red" dark @click="changeStatusSelf(false)">Batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDetailDialog" max-width="500" persistent>
      <v-card elevation="0">
        <v-card-title
          style="
            position: sticky;
            top: 0px;
            background-color: white;
            z-index: 10;
          "
          class="headline indigo--text"
        >
          <!-- Detail Lamaran -->
          <v-btn
            text
            small
            fab
            icon
            @click="closeDetailDialog"
            style="position: absolute; top: 10px; right: 10px"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-card v-if="detail != null" class="mx-auto">
        <v-list-item two-line>
          <v-list-item-content style="padding-left: 15px">
            <v-list-item-title class="text-h5">
              {{ detail.applicant.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{
              detail.applicant.email
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-btn x-small class="white--text" color="indigo"
                >Lihat biodata
                <v-icon x-small class="ml-1">mdi-open-in-new</v-icon>
              </v-btn>

              <v-btn x-small class="white--text ml-2" color="indigo">
                Lihat CV
                <v-icon x-small class="ml-2"
                  >mdi-file-document-arrow-right</v-icon
                >
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-4" style="margin-top: 0"></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <v-col class="text-h3" cols="12"> {{ detail.job.name }}</v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row>
                <v-col class="text-subtitle-1" cols="4">Perusahaan:</v-col>
                <v-col cols="8">{{ detail.job.company_name }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-row>
                <v-col class="text-subtitle-1" cols="4">Lokasi:</v-col>
                <v-col cols="8">{{ detail.job.location }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-row>
                <v-col class="text-subtitle-1" cols="4">Jenis:</v-col>
                <v-col cols="8">{{
                  employementType(detail.job.employement_type)
                }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-row>
                <v-col class="text-subtitle-1" cols="4">Status:</v-col>
                <v-col cols="8">
                  <!-- <v-chip
                    class="white--text"
                    :color="statusColor(detail.status)"
                    >{{ statusName(detail.status) }}</v-chip
                  > -->
                  <div style="height: 40px">
                    <v-autocomplete
                      chips
                      color="blue-grey lighten-2"
                      v-model="detail.status"
                      :items="dropdown.status"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      required
                      @keydown.enter.prevent
                      @change="detailStatusWatcher"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :color="statusColor(data.item.id)"
                          class="white--text"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- <div class="my-4 text-subtitle-1">$ • Italian, Cafe</div> -->

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            @click="openChangeStatusDialogSelf()"
            elevation="0"
            color="primary"
            style="width: 100%"
          >
            Simpan Perubahan
          </v-btn>
          <!-- <v-btn v-if="detail.status == 0" outlined color="primary">
            Screening
          </v-btn> -->
          <!-- <v-btn v-if="detail.status == 1" outlined color="primary">
            Qualified
          </v-btn>
          <v-btn v-if="detail.status == 1" outlined color="error">
            Not Qualified
          </v-btn>
          <v-btn v-if="detail.status != 10" outlined color="error">
            Cancel
          </v-btn> -->
          <!-- <v-btn style=" margin-top:30px;" color="success">Screening</v-btn>
              <v-btn style=" margin-top:30px;" color="success">Qualified</v-btn>
              <v-btn style=" margin-top:30px;" color="red">Not Qualified</v-btn>
              <v-btn style=" margin-top:30px;" color="success">Psikotes</v-btn>
              <v-btn style=" margin-top:30px;" color="success">Interview HR</v-btn>
              <v-btn style=" margin-top:30px;" color="success">Interview User</v-btn>
              <v-btn style=" margin-top:30px;" color="success">Offering</v-btn>
              <v-btn style=" margin-top:30px;" color="success">Accepted</v-btn>
              <v-btn style=" margin-top:30px;" color="error">Not Accepted</v-btn>
              <v-btn style=" margin-top:30px;" color="error">Canceled</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showAlert" :color="alertType" top>
      {{ alertMessage }}
    </v-snackbar>
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import moment from 'moment'
import buildType from '../../../services/buildType'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      build: process.env.VUE_APP_BUILD_TYPE,
      recruitmentApi: buildType.apiURL('recruitment'),
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      showAlert: false,
      alertMessage: '',
      alertType: '',
      searchKeyword: '',
      loading: false,
      dialog: false,
      refreshingTable: false,
      options: {},
      confirmDeleteData: false,
      headers: [
        { text: 'Posisi', value: 'job_name', sortable: false },
        { text: 'Penempatan', value: 'company_name', sortable: false },
        { text: 'Nama Pelamar', value: 'candidate_name', sortable: false },
        { text: 'Email', value: 'candidate_email', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Lihat', value: 'view', sortable: false }
      ],
      selectedItem: [],
      dropdown: {
        job: [],
        status: [
          {
            id: 0,
            name: 'Applied'
          },
          {
            id: 1,
            name: 'Screening'
          },
          {
            id: 2,
            name: 'Qualified'
          },
          {
            id: 3,
            name: 'Not Qualified'
          },
          {
            id: 4,
            name: 'Psikotes'
          },
          {
            id: 5,
            name: 'Interview HR'
          },
          {
            id: 6,
            name: 'Interview User'
          },
          {
            id: 7,
            name: 'Offering'
          },
          {
            id: 8,
            name: 'Accepted'
          },
          {
            id: 9,
            name: 'Not Accepted'
          },
          {
            id: 10,
            name: 'Canceled'
          }
        ],
        action: [
          {
            id: 1,
            name: 'Screening'
          },
          {
            id: 2,
            name: 'Qualified'
          },
          {
            id: 3,
            name: 'Not Qualified'
          },
          {
            id: 4,
            name: 'Psikotes'
          },
          {
            id: 5,
            name: 'Interview HR'
          },
          {
            id: 6,
            name: 'Interview User'
          },
          {
            id: 7,
            name: 'Offering'
          },
          {
            id: 8,
            name: 'Accepted'
          },
          {
            id: 9,
            name: 'Not Accepted'
          },
          {
            id: 10,
            name: 'Canceled'
          }
        ],
        company: [],
        employee_type: [
          {
            id: 1,
            name: 'Full Time'
          },
          {
            id: 2,
            name: 'Part Time'
          },
          {
            id: 3,
            name: 'Intership'
          }
        ]
      },
      paramAPI: null,
      totalData: 0,
      actionValue: null,
      isDisableCompanyDropdown: false,
      result: [],
      changeStatusDialog: false,
      changeStatusDialog2: false,
      changeStatusNotes: null,
      detail: null,
      showDetailDialog: false
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  mounted() {
    // this.fetchData()
    // this.fetchMasterJobNames()
    this.setParamVariable()
    setTimeout(async () => {
      await this.getDataFromApi()
      this.dropdownJob()
      this.dropdownCompany()
    }, 300)
  },
  methods: {
    actionWatcher(val) {
      if (val == null) {
        // reset param
      } else {
        this.openChangeStatusDialog(val)
      }
    },
    statusWatcher(v) {
      // console.log(v)
      this.getDataFromApi()
    },
    keywordWatcher(v) {
      if (v == null) {
        this.paramAPI.keyword = ''
      }
      this.getDataFromApi()
    },
    async positionWatcher(v) {
      // setTimeout(async () => {
      this.getDataFromApi()
      // }, 100)
    },
    companyWatcher(v) {
      this.getDataFromApi()
    },
    detailStatusWatcher(v) {
      console.log('detail>>>', this.detail)
    },
    async dropdownJob() {
      const url = `${this.recruitmentApi}master/universal/job/dropdown`
      await axios
        .get(url)
        .then((res) => {
          console.log('job>>>', res)

          this.dropdown.job = res.data.data
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    async dropdownCompany() {
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          setTimeout(() => {
            if (data.status_code == '00') {
              this.result = data.data
              this.totalData = data.total_record
            }
            this.loading = false
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
          return (this.result = [])
        })
    },
    async initDataTable() {
      return await new Promise((resolve, reject) => {
        let url = `${this.recruitmentApi}application/list?keyword=${
          this.paramAPI.keyword
        }&order_by=${this.paramAPI.orderBy}&order_type=${
          this.paramAPI.orderType
        }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
          this.paramAPI.limit
        }`
        const filter = []
        // if (this.paramAPI.is_archived != null) {
        // filter.push(`{"is_delete": ${this.paramAPI.is_archived}}`)
        // url = url + `&isArchived=${this.paramAPI.is_archived}`
        // }
        if (this.paramAPI.company_id != null) {
          filter.push(`{"$job.company_id$": ${this.paramAPI.company_id}}`)
        }
        if (this.paramAPI.status != null) {
          filter.push(`{"status": ${this.paramAPI.status}}`)
        }
        if (this.paramAPI.job_id != null) {
          filter.push(`{"job_id": ${this.paramAPI.job_id}}`)
        }
        if (filter.length > 0) {
          url = url + `&filter=[${filter}]`
        }
        axios
          .get(url)
          .then((res) => {
            console.log('response>>>', res)
            // if (res.data.status_code == '00') {
            resolve(res.data)
            // }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    refreshTable() {
      this.loading = true
      this.clearSearch()
    },
    search() {
      this.getDataFromApi()
    },
    clearSearch() {
      this.setParamVariable()
      this.getDataFromApi()
      this.dropdownJob()
    },
    rowClick(item) {
      this.detail = {}

      Object.assign(this.detail, item)
      Object.assign(this.detail, {
        origin_status: item.status,
        changeNotes: ''
      })
      console.log('detail>>>', this.detail)
      setTimeout(() => {
        this.showDetailDialog = true
      }, 300)
    },
    closeDetailDialog() {
      this.showDetailDialog = false
      setTimeout(() => {
        this.detail = null
      }, 300)
    },
    formattedDate(val) {
      let date = '-'
      if (val !== null) {
        const a = new Date(val)
        const b = a.toLocaleDateString('id')
        date = b
      }

      return date
    },
    openChangeStatusDialog(val) {
      setTimeout(() => {
        this.changeStatusDialog = true
      }, 300)
    },
    openChangeStatusDialogSelf(val) {
      setTimeout(() => {
        this.changeStatusDialog2 = true
      }, 300)
    },
    closeChangeStatusDialog(val) {
      this.changeStatusDialog = false
      setTimeout(() => {
        this.actionValue = null
        this.changeStatusNotes = null
        this.selectedItem = []
      }, 300)
    },
    async changeStatusBatch(bool) {
      if (bool) {
        this.loading = true
        const arrSuccess = []
        const arrFail = []
        let i = 0
        while (
          (await new Promise((resolve, reject) => {
            const payload = {
              id: this.selectedItem[i].id,
              status: this.actionValue.id,
              notes: this.changeStatusNotes
            }
            axios
              .post(`${this.recruitmentApi}application/changeStatus`, payload)
              .then((res) => {
                if (res.data.status_code === '00') {
                  arrSuccess.push({
                    index: i,
                    msg: res.data.status_msg
                  })
                } else {
                  arrFail.push({
                    index: i,
                    msg: res.data.status_msg
                  })
                }
                resolve(i++)
              })
              .catch((err) => {
                console.log(err)
                arrFail.push({
                  index: i,
                  msg: 'error'
                })
                reject(new Error(i++))
              })
          })) <
          this.selectedItem.length - 1
        ) {
          console.log('cek ' + i)
        }

        this.getDataFromApi()
        setTimeout(() => {
          this.showAlert = true
          this.alertMessage = `Update data selesai, ${arrSuccess.length} berhasil | ${arrFail.length} gagal`
          this.alertType = 'success'
          this.loading = false
          this.closeChangeStatusDialog()
          console.log(arrFail)
        }, 300)
      } else {
        setTimeout(() => {
          this.closeChangeStatusDialog()
        }, 100)
      }
    },
    async changeStatusSelf(bool) {
      if (bool) {
        this.loading = true
        const payload = {
          id: this.detail.id,
          status: this.detail.status,
          notes: this.detail.changeNotes
        }
        await axios
          .post(`${this.recruitmentApi}application/changeStatus`, payload)
          .then((res) => {
            if (res.data.status_code == '00') {
              this.changeStatusDialog2 = false
              this.closeDetailDialog()
              this.getDataFromApi()
              this.showMsgDialog('success', res.data.status_msg, false)
            } else {
              this.changeStatusDialog2 = false
              this.showMsgDialog('error', res.data.status_msg, false)
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.changeStatusDialog2 = false
            console.error(err)
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      } else {
        setTimeout(() => {
          this.changeStatusDialog2 = false
        }, 100)
      }
    },
    statusName(val) {
      const status = [
        'Applied',
        'Screening',
        'Qualified',
        'Not Qualified',
        'Psikotes',
        'Interview HR',
        'Interview User',
        'Offering',
        'Accepted',
        'Not Accepted',
        'Canceled'
      ]
      return status[val]
    },
    statusColor(val) {
      const status = [
        '#555',
        'green',
        'green',
        'red',
        'green',
        'green',
        'green',
        'green',
        'green',
        'red',
        'red'
      ]
      return status[val]
    },
    employementType(val) {
      if (val != null) {
        const name = this.dropdown.employee_type.find(
          ({ id }) => id == val
        ).name
        return name
      }
      return ''
    },
    setParamVariable() {
      this.paramAPI = {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: null,
        orderBy: 'id',
        orderType: 'asc',
        itemsPerPage: 10,
        company_id: null,
        department_id: null,
        position_id: null,
        job_id: null,
        is_archived: 0
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#masterjob {
  position: relative;
  .masterjob-list {
    margin: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .recruit-toolbar-1 {
      margin-left: auto;
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid rgba(255, 255, 255, 0.2);
  border-top: 16px solid #2e2be2;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gantistatus {
  margin-top: 20px;
  width: 100%;
}
</style>
