export default {
  state: {
    oeeId: null,
    planId: null
  },
  mutations: {
    setOeeId(state, payload) {
      state.oeeId = payload
    },
    setPlanId(state, payload) {
      state.planId = payload
    }
  },
  getters: {
    getOeeId(state) {
      return state.oeeId
    },
    getPlanId(state) {
      return state.planId
    }
  }
}
