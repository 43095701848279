<template>
  <div v-if="result.can_access_salary">
    <div
      v-if="
        getUserProfile.level.find(({ id }) => id == appLevel.administrator) !==
          undefined && getUserProfile.employee.email !== result.email
      "
    >
      <v-card
        class="mx-auto"
        width="100%"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
          height: 400px;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255);
        "
      >
        DATA NOT FOUND
      </v-card>
    </div>
    <div v-else>
      <div
        v-if="getLoginPayroll || getUserProfile.employee.email === result.email"
      >
        <div v-if="permission.read_perm == 1">
          <div v-if="result !== null">
            <v-form
              ref="entryForm"
              @submit.prevent="submit"
              lazy-validation
              :disabled="!isEdit"
            >
              <v-row style="padding: 15px" no-gutters>
                <v-col
                  v-if="permission.update_perm == 1"
                  cols="12"
                  style="padding: 0 10px; text-align: right"
                >
                  <a
                    v-if="!isEdit"
                    href=""
                    @click.prevent="editForm"
                    style="font-size: 14px"
                  >
                    <v-icon small color="#1976d2">
                      mdi-application-edit-outline
                    </v-icon>
                    Edit info
                  </a>
                  <p
                    v-else
                    @click.prevent="editForm"
                    style="
                      font-size: 14px;
                      margin: 0;
                      color: red;
                      cursor: pointer;
                    "
                  >
                    Batal
                    <v-icon small color="red"> mdi-close </v-icon>
                  </p>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Template BPJS
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      :items="dropdown.bpjs_template"
                      item-text="name"
                      item-value="code"
                      return-code
                      dense
                      style="margin: 0"
                      v-model="result.bpjs_template"
                      :append-icon="!isEdit ? '' : '$dropdown'"
                    ></v-autocomplete>
                  </v-col>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Metode Payroll
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      :items="[
                        {
                          id: 1,
                          name: 'Harian'
                        },
                        {
                          id: 2,
                          name: 'Bulanan'
                        }
                      ]"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      style="margin: 0"
                      v-model="result.payroll_method"
                      :append-icon="!isEdit ? '' : '$dropdown'"
                    ></v-autocomplete>
                  </v-col>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Objek Pajak
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      :items="dropdown.tax_object"
                      item-text="code"
                      item-value="id"
                      return-object
                      dense
                      style="margin: 0"
                      v-model="result.tax_object"
                      :append-icon="!isEdit ? '' : '$dropdown'"
                    ></v-autocomplete>
                  </v-col>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <div flat class="d-flex justify-start align-center">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kirim Slip ke Email
                    </p>
                    <v-switch
                      v-model="result.is_send_payslip"
                      inset
                      color="success"
                      style="margin-left: 15px"
                    ></v-switch>
                  </div>
                </v-col>

                <v-col
                  v-if="
                    getUserProfile.level.find(
                      ({ id }) => id == appLevel.administrator
                    ) !== undefined ||
                    getUserProfile.employee.email ===
                      'a1.arifrahman.1213@gmail.com'
                  "
                  cols="12"
                  md="4"
                  style="padding: 0 10px"
                >
                  <div flat class="d-flex justify-start align-center">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Auto generate payroll
                    </p>
                    <v-switch
                      v-model="result.auto_generate_payroll"
                      inset
                      color="success"
                      style="margin-left: 15px"
                    ></v-switch>
                  </div>
                </v-col>
              </v-row>
              <v-row style="padding: 15px; margin-bottom: 10px" no-gutters>
                <v-col cols="12" style="padding: 0; margin-bottom: 20px">
                  <v-toolbar-title
                    class="overline"
                    style="padding: 0 0 0 10px; text-align: center"
                  >
                    <p
                      style="
                        font-size: 15px;
                        margin: 0;
                        font-weight: bold;
                        color: indigo;
                        position: relative;
                      "
                    >
                      Salary
                    </p>
                  </v-toolbar-title>
                  <v-divider style="margin: 0"></v-divider>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <div flat class="d-flex justify-start align-center">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Set UMR Berdasarkan Perusahaan
                    </p>
                    <v-checkbox
                      v-model="result.is_umr_based_on_company"
                      :false-value="false"
                      :true-value="true"
                      style="margin-left: 15px"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Upah Minimum Regional (UMR)
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div v-if="!result.is_umr_based_on_company">
                      <v-text-field
                        v-if="isEdit"
                        style="height: 30px; position: relative; top: -3px"
                        dense
                        v-model="result.umr"
                        :disabled="isDisabled"
                        type="text"
                      />
                      <v-text-field
                        v-else
                        :value="priceConvert(result.umr)"
                        style="height: 30px; position: relative; top: -3px"
                        dense
                        disabled
                      />
                    </div>
                    <v-text-field
                      v-else
                      :value="priceConvert(result.umr_based_on_company)"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" md="4"> </v-col>
              </v-row>
              <v-row style="padding: 15px; margin-bottom: 10px" no-gutters>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Gaji Pokok
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="isEdit"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      v-model="result.gaji_pokok"
                      :disabled="isDisabled"
                      type="text"
                    />
                    <v-text-field
                      v-else
                      :value="priceConvert(result.gaji_pokok)"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" md="3" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tunjangan Jabatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="isEdit"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      v-model="result.tunjangan_jabatan"
                      :disabled="isDisabled"
                      type="text"
                    />
                    <v-text-field
                      v-else
                      :value="priceConvert(result.tunjangan_jabatan)"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" md="3" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tunjangan Penempatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="isEdit"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      v-model="result.tunjangan_penempatan"
                      :disabled="isDisabled"
                    />
                    <v-text-field
                      v-else
                      :value="priceConvert(result.tunjangan_penempatan)"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" md="3" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tunjangan Perumahan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="isEdit"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      v-model="result.tunjangan_perumahan"
                      :disabled="isDisabled"
                      type="text"
                    />
                    <v-text-field
                      v-else
                      :value="priceConvert(result.tunjangan_perumahan)"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                    />
                  </v-col>
                </v-col>
              </v-row>

              <v-row style="padding: 15px; margin-bottom: 10px" no-gutters>
                <v-col cols="12" style="padding: 0; margin-bottom: 20px">
                  <v-toolbar-title
                    class="overline"
                    style="padding: 0 0 0 10px; text-align: center"
                  >
                    <p
                      style="
                        font-size: 15px;
                        margin: 0;
                        font-weight: bold;
                        color: indigo;
                        position: relative;
                      "
                    >
                      Dasar Upah BPJS
                    </p>
                  </v-toolbar-title>
                  <v-divider style="margin: 0"></v-divider>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      BPJS Tenaga Kerja
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                      :value="priceConvert(result.base_bpjs_tk)"
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      BPJS Pensiun
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                      :value="priceConvert(result.base_bpjs_pensiun)"
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      BPJS Kesehatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                      :value="priceConvert(result.base_bpjs_kesehatan)"
                    />
                  </v-col>
                </v-col>
              </v-row>

              <!-- <v-row style="padding: 15px; margin-bottom: 10px" no-gutters>
        <v-col cols="12" style="padding: 0; margin-bottom:20px;">
          <v-toolbar-title
            class="overline"
            style="padding-left:10px; text-align:center;"
          >
            <p
              style="font-size: 15px; margin: 0; font-weight: bold; color:indigo; position:relative; "
            >
              Tanggungan
            </p>
          </v-toolbar-title>
          <v-divider style="margin: 0;"></v-divider>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              BPJS Tenaga Kerja TG.Perusahaan (4.24%)
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0;">
            <v-text-field
              style="height:30px; position:relative; top:-3px;"
              dense
              disabled
              v-model="result.bpjs_tk_comp"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              BPJS Pensiun TG.Perusahaan (2%)
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0;">
            <v-text-field
              style="height:30px; position:relative; top:-3px;"
              dense
              disabled
              v-model="result.bpjs_jp_comp"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              BPJS Kesehatan TG.Perusahaan (4%)
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0;">
            <v-text-field
              style="height:30px; position:relative; top:-3px;"
              dense
              disabled
              v-model="result.bpjs_k_comp"
            />
          </v-col>
        </v-col>
      </v-row>

      <v-row style="padding: 15px" no-gutters>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              BPJS Tenaga Kerja TG.Karyawan (2%)
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0;">
            <v-text-field
              style="height:30px; position:relative; top:-3px;"
              dense
              disabled
              v-model="result.bpjs_tk_emp"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              BPJS Pensiun TG.Karyawan (1%)
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0;">
            <v-text-field
              style="height:30px; position:relative; top:-3px;"
              dense
              disabled
              v-model="result.bpjs_jp_emp"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              BPJS Kesehatan TG.Karyawan (1%)
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0;">
            <v-text-field
              style="height:30px; position:relative; top:-3px;"
              dense
              disabled
              v-model="result.bpjs_k_emp"
            />
          </v-col>
        </v-col>
      </v-row> -->

              <v-row style="padding: 15px" no-gutters>
                <v-col cols="12" md="4" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tambahan BPJS Kesehatan
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="isEdit"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      type="number"
                      min="0"
                      v-model="result.tambahan_bpjs_k"
                      :disabled="isDisabled"
                    />
                    <v-text-field
                      v-else
                      :value="result.tambahan_bpjs_k"
                      style="height: 30px; position: relative; top: -3px"
                      dense
                      disabled
                    />
                  </v-col>
                </v-col>
                <v-col cols="12" md="8" style="padding: 0 10px"></v-col>
                <v-col
                  v-if="permission.update_perm == 1"
                  cols="12"
                  style="padding: 0 10px; margin-bottom: 50px; margin-top: 20px"
                  class="d-flex justify-space-between"
                >
                  <!-- <v-btn
              v-if="isEdit"
              @click.prevent="changeTab"
              type="button"
              rounded
              elevation="0"
              color="error"
              class="white--text font-weight-bold"
              style="font-size: 12px; width: 120px"
              ><v-icon>mdi-chevron-left</v-icon>
              Kembali
            </v-btn> -->
                  <v-btn
                    v-if="isEdit"
                    type="submit"
                    rounded
                    elevation="0"
                    color="success"
                    class="white--text font-weight-bold"
                    style="font-size: 12px; width: 160px"
                    :loading="loading"
                  >
                    Simpan Perubahan
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-row
              v-if="
                getUserProfile.level.find(
                  ({ id }) =>
                    id == appLevel.adminMaster || id == appLevel.adminSupport
                ) !== undefined ||
                getUserProfile.employee.email === result.email
              "
              style="padding: 15px"
              no-gutters
            >
              <v-col
                v-if="!isEdit"
                cols="12"
                style="padding: 0; margin-bottom: 0"
              >
                <v-divider></v-divider>
                <v-toolbar-title
                  class="overline"
                  style="padding-left: 10px; text-align: center"
                >
                  <p
                    style="
                      font-size: 16px;
                      margin: 0;
                      font-weight: bold;
                      color: indigo;
                      position: relative;
                    "
                  >
                    Riwayat Payroll
                  </p>
                </v-toolbar-title>
              </v-col>
              <v-col
                v-if="!isEdit"
                cols="12"
                style="padding: 0; margin-bottom: 0"
              >
                <div style="width: 210px; height: 40px">
                  <v-text-field
                    class="attendance-start-date ml-1 mr-2"
                    dense
                    label="Periode"
                    type="month"
                    name="monthperiod"
                    style="height: 37px"
                    step="1"
                    v-model="paramAPI.period"
                    outlined
                    clearable
                  />
                </div>
              </v-col>
              <v-col v-if="!isEdit" cols="12" style="padding: 0 10px">
                <v-data-table
                  mobile-breakpoint="0"
                  fixed-header
                  height="50vh"
                  :headers="headers"
                  style="cursor: pointer"
                  :items="listPayroll"
                  :loading="loading"
                  @click:row="rowClick"
                  item-key="id"
                  :options.sync="options"
                  :footer-props="{
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                  }"
                  :server-items-length="totalData"
                  @update:page="updatePage"
                  @update:items-per-page="updateItemPerPage"
                >
                  <template v-slot:[`item.no`]="{ index }">
                    <div
                      style="
                        font-size: 14px;
                        width: 20px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                    </div>
                  </template>
                  <template v-slot:[`item.period_year`]="{ item }">
                    <div :style="`font-size:12px; width:30px; padding: 0;`">
                      {{ item.period_year }}
                    </div>
                  </template>
                  <template v-slot:[`item.period_month`]="{ item }">
                    <div :style="`font-size:12px; width:65px; padding: 0;`">
                      {{ monthName(item.period_month) }}
                    </div>
                  </template>
                  <template v-slot:[`item.period`]="{ item }">
                    <div :style="`font-size:12px; width:160px; padding: 0;`">
                      {{ convertDate(item.period_start_date) }} -
                      {{ convertDate(item.period_end_date) }}
                    </div>
                  </template>
                  <template v-slot:[`item.employee.name`]="{ item }">
                    <div
                      style="
                        font-size: 12px;
                        width: 210px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ item.employee.name }}
                    </div>
                  </template>
                  <template v-slot:[`item.employee.company_name`]="{ item }">
                    <div
                      style="
                        font-size: 12px;
                        width: 250px;
                        padding: 0;
                        display: flex;
                        flex-direction: row;
                      "
                    >
                      {{ item.employee.company_name }}
                    </div>
                  </template>
                  <template v-slot:[`item.gaji_pokok`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.gaji_pokok) }}
                    </div>
                  </template>
                  <template v-slot:[`item.lembur`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.lembur) }}
                    </div>
                  </template>
                  <template v-slot:[`item.tunjangan_jabatan`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.tunjangan_jabatan) }}
                    </div>
                  </template>
                  <template v-slot:[`item.tunjangan_penempatan`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.tunjangan_penempatan) }}
                    </div>
                  </template>
                  <template v-slot:[`item.tunjangan_perumahan`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.tunjangan_perumahan) }}
                    </div>
                  </template>
                  <template v-slot:[`item.gaji_kotor`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.gaji_kotor) }}
                    </div>
                  </template>
                  <template v-slot:[`item.bpjs_tk_comp`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.bpjs_tk_comp) }}
                    </div>
                  </template>
                  <template v-slot:[`item.bpjs_k_comp`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.bpjs_k_comp) }}
                    </div>
                  </template>
                  <template v-slot:[`item.bpjs_jp_comp`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.bpjs_jp_comp) }}
                    </div>
                  </template>
                  <template v-slot:[`item.bpjs_tk_emp`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.bpjs_tk_emp) }}
                    </div>
                  </template>
                  <template v-slot:[`item.bpjs_k_emp`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.bpjs_k_emp) }}
                    </div>
                  </template>
                  <template v-slot:[`item.bpjs_jp_emp`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.bpjs_jp_emp) }}
                    </div>
                  </template>
                  <template v-slot:[`item.piutang`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.piutang) }}
                    </div>
                  </template>
                  <template v-slot:[`item.lain_lain`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.lain_lain) }}
                    </div>
                  </template>
                  <template v-slot:[`item.potongan_absensi`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.potongan_absensi) }}
                    </div>
                  </template>
                  <template v-slot:[`item.gaji_bersih`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{ priceConvert(item.gaji_bersih) }}
                    </div>
                  </template>
                  <template v-slot:[`item.total_hari_kerja`]="{ item }">
                    <div :style="`font-size:12px; width:80px; padding: 0;`">
                      {{
                        item.total_hari_kerja !== null
                          ? item.total_hari_kerja
                          : 0
                      }}
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>

          <div
            v-else
            class="d-flex justify-center align-center"
            style="width: 80vw; height: 50vh; margin: auto"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              size="100"
            ></v-progress-circular>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center"
        style="height: 80vh"
      >
        <v-card
          v-if="showOtpInput"
          elevation="5"
          class="ma-auto position-relative"
          style="max-width: 500px; background-color: rgba(240, 240, 240, 0.7)"
        >
          <v-card-text
            style="padding: 15px 15px 0 15px; margin: 0"
            class="d-flex justify-center align-center"
          >
            <v-row style="padding: 10px 40px">
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-center"
                      :style="`
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);`"
                    >
                      MASUKKAN KODE OTP
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-otp-input
                      v-model="otp"
                      :disabled="loading || timer === null"
                      length="6"
                    ></v-otp-input>
                    <v-overlay absolute :value="loading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </v-col>
                  <v-col
                    v-if="this.timer !== null"
                    cols="12"
                    style="padding: 0"
                  >
                    <p
                      class="text-center"
                      :style="`
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.6);`"
                    >
                      {{ this.timer }}
                    </p>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <div
            class="d-flex justify-center align-center"
            style="padding: 0 50px"
          >
            <v-btn
              v-if="this.timer !== null"
              @click="verifyOTP"
              :loading="loading"
              class="white--text custom-btn"
              color="rgba(4, 123, 202, 0.979)"
              type="button"
            >
              Verify
            </v-btn>
            <v-btn
              v-else
              @click="generateOTP"
              class="white--text custom-btn"
              color="rgba(4, 123, 202, 0.979)"
              type="button"
            >
              Refresh
            </v-btn>
          </div>
        </v-card>
        <v-form
          v-else
          ref="loginForm"
          @submit.prevent="loginPayroll"
          style="position: relative; width: 500px"
          :disabled="loading"
        >
          <v-card
            elevation="5"
            style="
              padding-top: 20px;
              background-color: rgba(240, 240, 240, 0.7);
            "
          >
            <!-- <p class="white lighten-2 d-flex justify-center align-center">LOGIN</p> -->

            <v-card-text class="d-flex justify-center align-center">
              <v-row style="padding: 10px 40px">
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        class="text-left"
                        :style="`
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);`"
                      >
                        Masukkan password untuk mengakses halaman ini
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-model="loginForm.password"
                        type="password"
                        dense
                        style="height: 37px"
                        outlined
                        clearable
                        light
                      />
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <div
              class="d-flex justify-center align-center"
              style="padding: 0 50px"
            >
              <v-btn
                :loading="loading"
                class="white--text custom-btn"
                color="rgba(4, 123, 202, 0.979)"
                type="submit"
              >
                Submit
              </v-btn>
            </div>
          </v-card>
        </v-form>
      </div>
    </div>
  </div>
  <v-card
    v-else
    elevation="0"
    style="
      margin: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50vh;
      font-size: 14px;
      background: rgba(255, 255, 255);
    "
  >
    <p>YOU CANNOT ACCESS THIS DATA</p>
  </v-card>
</template>
<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
import { mapGetters, mapMutations } from 'vuex'
import 'vue-pdf-app/dist/icons/main.css'
export default {
  props: [
    'result',
    'dropdown',
    'isEdit',
    'permission',
    'appLevel',
    'isLoginPayroll'
  ],
  computed: {
    ...mapGetters(['getUserProfile', 'getLoginPayroll'])
  },
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      upload: buildType.apiURL('upload2'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        itemsPerPage: 10,
        sortBy: 'name',
        sortType: 'asc',
        company_id: null,
        department_id: null,
        period: null
      },
      // isEdit: false,
      loading: false,
      dialog: false,
      isDisabled: false,
      headers: [
        {
          text: 'No',
          value: 'no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tahun',
          value: 'period_year',
          align: 'left',
          sortable: false
        },
        {
          text: 'Bulan',
          value: 'period_month',
          align: 'left',
          sortable: false
        },
        {
          text: 'Cut Off',
          value: 'period',
          align: 'left',
          sortable: false
        },
        // {
        //   text: 'NIP',
        //   value: 'employee.nik',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Nama Karyawan',
          value: 'employee.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'employee.company_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Hari Kerja',
          value: 'total_hari_kerja',
          align: 'left',
          sortable: false
        },
        {
          text: 'Gaji Pokok',
          value: 'gaji_pokok',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lembur',
          value: 'lembur',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tunjangan Jabatan',
          value: 'tunjangan_jabatan',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tunjangan Penempatan',
          value: 'tunjangan_penempatan',
          align: 'left',
          sortable: false
        },
        {
          text: 'Tunjangan Perumahan',
          value: 'tunjangan_perumahan',
          align: 'left',
          sortable: false
        },
        {
          text: 'Gaji Kotor',
          value: 'gaji_kotor',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Perusahaan BPJS TK',
          value: 'bpjs_tk_comp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Perusahaan BPJS JP',
          value: 'bpjs_jp_comp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Perusahaan BPJS K',
          value: 'bpjs_k_comp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Karyawan BPJS TK',
          value: 'bpjs_tk_emp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Karyawan BPJS JP',
          value: 'bpjs_jp_emp',
          align: 'left',
          sortable: false
        },
        {
          text: 'TG.Karyawan BPJS K',
          value: 'bpjs_k_emp',
          align: 'left',
          sortable: false
        },
        {
          text: 'Piutang',
          value: 'piutang',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lain-lain',
          value: 'lain_lain',
          align: 'left',
          sortable: false
        },
        {
          text: 'Potongan Absensi',
          value: 'potongan_absensi',
          align: 'left',
          sortable: false
        },
        {
          text: 'Gaji Bersih',
          value: 'gaji_bersih',
          align: 'left',
          sortable: false
        }
      ],
      totalData: 0,

      options: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: []
      },
      response: [],
      value: 0,
      counter: 0,
      info: [],
      daysDropdown: [
        { id: 0, name: 'Minggu' },
        { id: 1, name: 'Senin' },
        { id: 2, name: 'Selasa' },
        { id: 3, name: 'Rabu' },
        { id: 4, name: 'Kamis' },
        { id: 5, name: 'Jumat' },
        { id: 6, name: 'Sabtu' }
      ],
      listPayroll: [],

      loginForm: {
        email: '',
        password: ''
      },
      otp: null,
      request_id: null,
      timer: null,
      xInterval: null,
      showOtpInput: null,
      isLoadingPage: true
    }
  },
  watch: {
    isEdit() {
      if (!this.isEdit) {
        this.$emit('cancel')
      }
    },
    async 'paramAPI.period'() {
      await this.getDataFromApi()
    }
    // 'result.is_umr_based_on_company'(v) {
    //   if (this.isEdit) {
    //     if (v) {
    //       this.getUmkByCompany()
    //     }
    //     // this.calculatePayment()
    //   }
    //   // this.result.department_id = this.result.department.id
    // }
  },
  async mounted() {
    if (
      this.getUserProfile.employee.email === this.result.email &&
      this.getUserProfile.level.find(
        ({ id }) => id == this.appLevel.administrator
      ) === undefined
    ) {
      this.getDataFromApi()
    }
  },
  methods: {
    ...mapMutations(['setLoginPayroll']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },

    async loginPayroll() {
      this.isLoadingPage = true
      this.loginForm.email = this.getUserProfile.employee.email
      await axios
        .post(`${this.oAuthApi}user/login`, this.loginForm)
        .then((res) => {
          console.log('login page>>>', res)
          if (res.data.status_code === '00') {
            this.generateOTP()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }

          this.isLoadingPage = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.isLoadingPage = false
        })
    },
    setCountdown(dateTime) {
      var dateTimeNow = dateTime
      const self = this
      this.xInterval = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = dateTimeNow - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Output the result in an element with id="demo"
        self.timer =
          days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '

        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(self.xInterval)
          self.timer = null
        }
        console.log(self.timer)
      }, 1000)
    },
    async generateOTP() {
      this.isLoadingPage = true
      await axios
        .post(`${this.oAuthApi}otp/generate`, {
          application_id: 21,
          application_name: 'HRIS'
        })
        .then((res) => {
          console.log('generate otp>>>', res)
          if (res.data.status_code === '00') {
            this.request_id = res.data.request_id
            this.showOtpInput = setTimeout(() => {
              const dateTime = new Date().getTime() + 1 * 60000
              this.setCountdown(dateTime)
            }, 0)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }

          this.isLoadingPage = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.isLoadingPage = false
        })
    },
    async verifyOTP() {
      await axios
        .post(`${this.oAuthApi}otp/verify`, {
          request_id: this.request_id,
          otp: this.otp
        })
        .then((res) => {
          console.log('verify otp>>>', res)
          if (res.data.status_code === '00') {
            // this.access_token = res.data.data.x_access_token
            setTimeout(() => {
              // this.showMsgDialog('warning', 'Invalid OTP', false)
              this.showOtpInput = false
              this.setLoginPayroll(res.data.access_token)
              clearInterval(this.xInterval)
              this.getDataFromApi()
            }, 300)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
          this.isLoadingPage = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.isLoadingPage = false
        })
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then((data) => {
        console.log(data)
        setTimeout(() => {
          this.listPayroll = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      let url = `${this.hrsApi}employee/payroll/list?keyword=${
        this.result.name
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&company_id=${Number(this.result.company.id)}`
      if (this.paramAPI.period !== null) {
        url =
          url +
          `&period_month=${this.paramAPI.period.split('-')[1]}&period_year=${
            this.paramAPI.period.split('-')[0]
          }`
      }
      console.log('url >>>>', url)
      return await new Promise((resolve) => {
        axios
          .get(url, {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          })
          .then((res) => {
            console.log('payroll list >>>>', url)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },

    rowClick(pItem) {
      setTimeout(() => {
        this.$router.push(`/hr/payroll/slip/detail/${pItem.id}`)
      }, 100)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    // calculatePayment() {
    //   //
    //   this.isDisabled = true
    //   const dupResult = this.result
    //   const gajiPokok =
    //     this.result.gaji_pokok !== null ? Number(this.result.gaji_pokok) : 0
    //   const tunjab =
    //     this.result.tunjangan_jabatan !== null
    //       ? Number(this.result.tunjangan_jabatan)
    //       : 0
    //   const tunperum =
    //     this.result.tunjangan_perumahan !== null
    //       ? Number(this.result.tunjangan_perumahan)
    //       : 0
    //   const tunpatan =
    //     this.result.tunjangan_penempatan !== null
    //       ? Number(this.result.tunjangan_penempatan)
    //       : 0
    //   const icombpjstk = 4.24 / 100
    //   const icombpjsjp = 2 / 100
    //   const icombpjsk = 4 / 100

    //   const iempbpjstk = 2 / 100
    //   const iempbpjsjp = 1 / 100
    //   const iempbpjsk = 1 / 100

    //   const totalPokokTunjab = gajiPokok + tunjab

    //   this.result.base_bpjs_tk = this.getBPJSTK(
    //     totalPokokTunjab + tunpatan + tunperum
    //   )
    //   this.result.base_bpjs_pensiun = this.getBPJSPensiun(totalPokokTunjab)
    //   this.result.base_bpjs_kesehatan = this.getBPJSKesehatan(totalPokokTunjab)

    //   this.result.bpjs_tk_comp = Number(this.result.base_bpjs_tk) * icombpjstk
    //   this.result.bpjs_jp_comp =
    //     Number(this.result.base_bpjs_pensiun) * icombpjsjp
    //   this.result.bpjs_k_comp =
    //     Number(this.result.base_bpjs_kesehatan) * icombpjsk

    //   this.result.bpjs_tk_emp = Number(this.result.base_bpjs_tk) * iempbpjstk
    //   this.result.bpjs_jp_emp =
    //     Number(this.result.base_bpjs_pensiun) * iempbpjsjp
    //   this.result.bpjs_k_emp =
    //     Number(this.result.base_bpjs_kesehatan) *
    //     (1 + Number(this.result.tambahan_bpjs_k)) *
    //     iempbpjsk

    //   console.log(
    //     '>>>>',
    //     dupResult,
    //     gajiPokok,
    //     tunjab,
    //     tunperum,
    //     tunpatan,
    //     icombpjsjp,
    //     icombpjsk,
    //     iempbpjsjp,
    //     iempbpjstk,
    //     iempbpjsk
    //   )

    //   this.isDisabled = false
    // },
    // getBPJSKesehatan(val) {
    //   let total = 0
    //   if (val !== null) {
    //     if (val !== 0) {
    //       if (val <= 5000000) {
    //         total = val
    //       }
    //       if (val > 5000000 && val <= 7000000) {
    //         total = 5000000
    //       }
    //       if (val > 7000000 && val <= 10000000) {
    //         total = 7000000
    //       }
    //       if (val > 10000000 && val <= 12000000) {
    //         total = 10000000
    //       }
    //       if (val > 12000000) {
    //         total = 12000000
    //       }
    //     }
    //   }
    //   return total
    // },
    // getBPJSTK(val) {
    //   let total = 0
    //   const min = 12000000
    //   const max = 17000000
    //   if (val !== null) {
    //     if (val !== 0) {
    //       if (val <= min) {
    //         total = val
    //       }
    //       if (val > min && val <= max) {
    //         total = min
    //       }
    //       if (val > max) {
    //         total = 0.75 * max
    //       }
    //     }
    //   }
    //   return total
    // },
    // getBPJSPensiun(val) {
    //   let total = 0
    //   const max = 8512400
    //   if (val !== null) {
    //     if (val !== 0) {
    //       if (val <= max) {
    //         total = val
    //       } else {
    //         if (val > max) {
    //           total = max
    //         }
    //       }
    //     }
    //   }
    //   return total
    // },
    editForm() {
      this.$emit('editForm')
    },
    changeTab() {
      this.$emit('changeTab', 1)
    },
    submit() {
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      let xPhoto = ''
      let department = null
      if (
        process.env.VUE_APP_BUILD_TYPE === 'development' ||
        process.env.VUE_APP_BUILD_TYPE === 'staging'
      ) {
        if (
          this.result.photo.includes(
            'https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/'
          )
        ) {
          xPhoto = this.result.photo.replace(
            'https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/',
            ''
          )
        } else {
          xPhoto = this.result.photo.replace(
            'http://10.10.20.8:8020/hris/photo/employee/',
            ''
          )
        }
      } else {
        xPhoto = this.result.photo.replace(
          'https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/',
          ''
        )
      }
      if (this.result.department !== null) {
        department = this.result.department_id
      } else {
        department = this.result.department.id
      }
      const newForm = {
        act: 'update',
        id: this.$route.params.id,
        nik: this.result.nik,
        name: this.result.name,
        company_id: this.result.company !== null ? this.result.company.id : 0,
        company_name:
          this.result.company !== null ? this.result.company.name : '',
        department_id: department,
        level_id: this.result.level !== null ? this.result.level.id : 0,
        card_no: this.result.card_no,
        email: this.result.email,
        gender: this.result.gender !== null ? this.result.gender.id : 0,
        religion: this.result.religion !== null ? this.result.religion.id : 0,
        birth_place: this.result.birth_place,
        birth_date: this.result.birth_date,
        no_identitas: this.result.no_identitas,
        address: this.result.address,
        mobile_phone_no: this.result.mobile_phone_no,
        education_level: this.result.education_level,
        education_major: this.result.education_major,
        mariage_status:
          this.result.mariage_status !== null
            ? this.result.mariage_status.id
            : 0,
        ptkp_status_id:
          this.result.ptkp_status !== null ? this.result.ptkp_status.id : 0,
        npwp_no: this.result.npwp_no,
        gol: this.result.gol,
        employee_status_id:
          this.result.employee_status !== null
            ? this.result.employee_status.id
            : 0,
        status: this.result.status,
        join_date: this.convertDate(this.result.join_date),
        contract_date: this.convertDate(this.result.contract_date),
        contract_month: this.result.contract_month,
        expire_contract_date: this.convertDate(
          this.result.expire_contract_date
        ),
        bpjs_no: this.result.bpjs_no,
        bpjs_tk_no: this.result.bpjs_tk_no,
        blood_type: this.result.blood_type,
        domicile_address: this.result.domicile_address,
        weekend_day: this.result.weekend_day,
        fingerprint_id: this.result.fingerprint_id,
        is_shift: this.result.is_shift,
        photo: xPhoto,
        gaji_pokok: this.result.gaji_pokok,
        tunjangan_jabatan: this.result.tunjangan_jabatan,
        tunjangan_penempatan: this.result.tunjangan_penempatan,
        tunjangan_perumahan: this.result.tunjangan_perumahan,
        tambahan_bpjs_k: this.result.tambahan_bpjs_k,
        // base_bpjs_tk: this.result.base_bpjs_tk,
        // base_bpjs_pensiun: this.result.base_bpjs_pensiun,
        // base_bpjs_kesehatan: this.result.base_bpjs_kesehatan,
        // bpjs_tk_comp: this.result.bpjs_tk_comp,
        // bpjs_jp_comp: this.result.bpjs_jp_comp,
        // bpjs_k_comp: this.result.bpjs_k_comp,
        // bpjs_tk_emp: this.result.bpjs_tk_emp,
        // bpjs_jp_emp: this.result.bpjs_jp_emp,
        // bpjs_k_emp: this.result.bpjs_k_emp,
        auto_generate_payroll: this.result.auto_generate_payroll,
        is_umr_based_on_company: this.result.is_umr_based_on_company,
        umr: this.result.is_umr_based_on_company
          ? `${this.result.umr_based_on_company}`
          : `${this.result.umr}`,
        clothing_size: this.result.clothing_size,
        account_number: this.result.account_number,
        resign_date: this.result.resign_date,
        working_on_company: this.result.working_on_company,
        is_send_payslip: this.result.is_send_payslip,
        bpjs_template: this.result.bpjs_template,
        tax_object_id:
          this.result.tax_object !== null ? this.result.tax_object.id : null,
        bpjs_status: this.result.bpjs_status,
        payroll_method: this.result.payroll_method,
        shift_category_id:
          this.result.shift_category !== null
            ? this.result.shift_category.id
            : null,

        height: this.result.height,
        weight: this.result.weight,
        nationality: this.result.nationality,
        house_status:
          this.result.house_status != null
            ? Number(this.result.house_status)
            : null,
        family_structure:
          this.result.family_structure.length > 0
            ? this.result.family_structure
            : null,
        formal_education:
          this.result.formal_education.length > 0
            ? this.result.formal_education
            : null,
        file: this.result.file,
        kk_no: this.result.kk_no,
        language_skill:
          this.result.language_skill.length > 0
            ? this.result.language_skill
            : null,
        sim: this.result.sim.length > 0 ? this.result.sim : null,
        social_media: this.result.social_media,
        vehicle: this.result.vehicle.length > 0 ? this.result.vehicle : null
      }
      console.log('UMR>>>>>>', newForm.umr)
      newForm.umr = Number(newForm.umr.replace(/,/g, ''))
      newForm.gaji_pokok = Number(newForm.gaji_pokok.replace(/,/g, ''))
      newForm.tunjangan_jabatan = Number(
        newForm.tunjangan_jabatan.replace(/,/g, '')
      )
      newForm.tunjangan_penempatan = Number(
        newForm.tunjangan_penempatan.replace(/,/g, '')
      )
      newForm.tunjangan_perumahan = Number(
        newForm.tunjangan_perumahan.replace(/,/g, '')
      )
      console.log('Save employee>>>>', newForm)

      this.save(newForm)
    },

    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            setTimeout(() => {
              this.$emit('cancel')
              return this.$emit('editForm')
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    convertDate(val) {
      if (val !== null || val !== '') {
        const rawDate = new Date(val).toLocaleDateString('id').split('/')
        const y = rawDate[2]
        const m = rawDate[1].length === 2 ? rawDate[1] : '0' + rawDate[1]
        const d = rawDate[0].length === 2 ? rawDate[0] : '0' + rawDate[0]
        const newDate = `${y}-${m}-${d}`
        return newDate
      }
      return ''
    },
    convertLocalDate(raw) {
      if (raw !== null) {
        return raw.split('-').reverse().join('-')
      }
    },
    monthName(val) {
      switch (val) {
        case 1:
          return 'JANUARI'
        case 2:
          return 'FEBRUARI'
        case 3:
          return 'MARET'
        case 4:
          return 'APRIL'
        case 5:
          return 'MAI'
        case 6:
          return 'JUNI'
        case 7:
          return 'JULI'
        case 8:
          return 'AGUSTUS'
        case 9:
          return 'SEPTEMBER'
        case 10:
          return 'OKTOBER'
        case 11:
          return 'NOVEMBER'
        case 12:
          return 'DESEMBER'
      }
    }
  }
}
</script>
<style lang="scss">
.custom-btn {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  border-radius: 5px;
  height: 40px;
}
</style>
