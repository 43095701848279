<template>
  <div class="fluid" id="recruit">
    <!-- <div class="row"> -->
    <v-row>
      <v-col
        cols="12"
        class="d-flex"
        style="
          position: relative;
          padding: 0 20px;
          text-align: center;
          margin-top: 20px;
        "
      >
        <h4 class="indigo--text" style="font-weight: bold; margin: auto">
          DATA KANDIDAT
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="refreshTable"
                :loading="refreshingTable"
              >
                <!-- click di atas -->
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
        </h4>
      </v-col>
    </v-row>
    <div class="recruit-list-dev">
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-content-between align-center"
          style="
            position: relative;
            padding: 0 20px;
            text-align: center;
            margin-top: 20px;
          "
        >
          <!-- <div class="d-flex justify-content-between align-center"> -->
          <div class="d-flex justify-center align-center">
            <!-- TAMBAH EMAIL -->
            <v-btn
              outlined
              elevation="1"
              color="indigo"
              class="indigo--text font-weight-bold"
              style="font-size: 12px; width: 150px"
              @click="showAddEmailForm = true"
            >
              Daftarkan Email
            </v-btn>

            <div style="width: 210px; height: 45px; margin-left: 15px">
              <v-select
                v-model="selectedJobName"
                :items="dropdown.job"
                label="Posisi"
                style="font-size: 12px"
                item-text="name"
                item-value="id"
                return-id
                clearable
                dense
                outlined
                @change="jobWatcher"
              />
            </div>
            <div style="width: 210px; height: 45px; margin-left: 15px">
              <v-select
                v-model="selectedStatus"
                label="Status"
                :items="dropdown.status"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
                clearable
                @change="statusWatcher"
              ></v-select>
            </div>

            <!-- <v-btn
                outlined
                elevation="1"
                color="indigo"
                class="indigo--text font-weight-bold"
                style="font-size: 12px"
                @click="showOpenJob = true"
              >
                Tambah Pekerjaan
              </v-btn> -->
          </div>
          <!-- <v-radio-group v-model="selectedJobName">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    elevation="1"
                    color="indigo"
                    class="indigo--text font-weight-bold"
                    style="font-size: 12px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Filter Data
                  </v-btn>
                </template>
                <div class="dropdown-filter">
                  <v-btn
                    color="blue"
                    class="reset-filter"
                    style="color: white"
                    @click="resetFilter"
                    >Reset Filter</v-btn
                  >
                  <v-btn
                    color="white"
                    @click="closeMenu"
                    class="close-menu-btn"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                  <v-list>
                    <v-list-item
                      v-for="(name, index) in masterJobNames"
                      :key="index"
                    >
                      <v-list-item-action>
                        <v-radio
                          :label="name"
                          :value="name"
                          @click="filterData(name)"
                        ></v-radio>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </v-radio-group> -->

          <div style="width: 310px; margin-left: 15px; height: 45px">
            <v-text-field
              v-model="searchKeyword"
              label="cari nama"
              type="search"
              outlined
              dense
              :append-icon="searchKeyword ? 'mdi-close-circle' : 'mdi-magnify'"
              style="margin-left: 10px"
              @click:append="clearSearch"
              @keyup.enter="search"
              :disabled="loading"
            ></v-text-field>
          </div>
          <!-- </div> -->

          <!-- <div class="recruit-toolbar-1" style="width: 150px">
            <v-text-field
              v-model="searchKeyword"
              label="cari nama"
              type="search"
              outlined
              dense
              :append-icon="searchKeyword ? 'mdi-close-circle' : 'mdi-magnify'"
              style="margin-left: 10px"
              @click:append="clearSearch"
              @keyup.enter="search"
              :disabled="loading"
            ></v-text-field>
          </div> -->
        </v-col>
      </v-row>
    </div>

    <!-- </div> -->

    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialog" max-width="1000">
          <v-card class="dialog-card" style="position: relative">
            <v-toolbar color="primary" dark style="position: sticky; top: 0">
              <v-toolbar-title
                class="headline"
                style="padding-left: 20px; padding-right: 20px"
                >Informasi Detail</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn icon @click="showConfirmDeleteData(selectedItem)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text v-if="selectedItem">
              <v-container>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    background: linear-gradient(to right, #2980b9, #6dd5fa);
                    color: #fff;
                  "
                >
                  <div
                    style="
                      flex: 1;
                      background: #fff;
                      padding: 20px;
                      text-align: center;
                    "
                  >
                    <img
                      :src="
                        selectedItem &&
                        selectedItem.body &&
                        selectedItem.body.foto_data_diri
                          ? selectedItem.body.foto_data_diri.path
                          : ''
                      "
                      alt="Foto Data Diri"
                      style="
                        width: 150px;
                        height: 150px;
                        border-radius: 50%;
                        border: 5px solid #2980b9;
                      "
                    />
                  </div>
                  <div style="flex: 2; padding: 20px">
                    <h2
                      style="margin-top: 0; font-size: 28px; font-weight: bold"
                    >
                      {{
                        selectedItem.nama_lengkap == null ||
                        selectedItem.nama_lengkap == ''
                          ? '-'
                          : selectedItem.nama_lengkap
                      }}
                    </h2>
                    <p style="margin-bottom: 10px; font-size: 18px">
                      <strong>Email:</strong>
                      {{
                        selectedItem.email == null || selectedItem.email == ''
                          ? '-'
                          : selectedItem.email
                      }}
                    </p>
                    <p style="margin-bottom: 10px; font-size: 18px">
                      <strong>Alamat:</strong>
                      {{
                        selectedItem && selectedItem.body
                          ? selectedItem.body.alamat
                          : '-'
                      }}
                    </p>
                    <p style="margin-bottom: 10px; font-size: 18px">
                      <strong>Telepon:</strong>
                      {{
                        selectedItem && selectedItem.body
                          ? selectedItem.body.telepon
                          : '-'
                      }}
                    </p>
                  </div>
                </div>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Informasi Pribadi</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <tbody>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Jenis Kelamin</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.jenis_kelamin
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Tinggi Badan</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.tinggi_badan
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Berat Badan</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.berat_badan
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Agama</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.agama
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Kebangsaan</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.kebangsaan
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Tempat Lahir</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.tempat_lahir
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Tanggal Lahir</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? formattedDate(selectedItem.body.tanggal_lahir)
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Status Perkawinan</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.status_perkawinan
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Golongan Darah</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.golongan_darah
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Nomor KTP</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.nomor_ktp
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Nomor SIM</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.nomor_sim
                            : '-'
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Status Rumah Tinggal</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.status_rumah_tinggal != null
                              ? selectedItem.body.status_rumah_tinggal +
                                (selectedItem.body.status_rumah != null
                                  ? ` (${selectedItem.body.status_rumah})`
                                  : '-')
                              : '-'
                            : '-'
                        }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>Status Rumah</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{
                          selectedItem && selectedItem.body
                            ? selectedItem.body.status_rumah
                            : '-'
                        }}
                      </td>
                    </tr> -->
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Kendaraan</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Jenis</th>
                      <th>Merek</th>
                      <th>Tahun</th>
                      <th>Pemilik</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="selectedItem.body && selectedItem.body.kendaraan">
                      <td class="table-cell" style="padding-left: 20px">
                        <strong>{{ selectedItem.body.kendaraan.jenis }}</strong>
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{ selectedItem.body.kendaraan.merk }}
                      </td>
                      <td class="table-cell" style="padding-left: 20px">
                        {{ selectedItem.body.kendaraan.tahun }}
                      </td>
                      <td class="table-cell status" style="padding-left: 20px">
                        {{ selectedItem.body.kendaraan.pemilik }}
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="4" style="text-align: center">-</td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Riwayat Pendidikan</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Nama Sekolah</th>
                      <th>Tingkat Pendidikan</th>
                      <th>Tempat Sekolah</th>
                      <th>Jurusan Sekolah</th>
                      <th>Tahun Masuk - Tahun Keluar</th>
                      <th>Status Kelulusan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body &&
                        selectedItem.body.riwayatPendidikan &&
                        selectedItem.body.riwayatPendidikan.length > 0
                      "
                    >
                      <tr
                        v-for="(pendidikan, index) in selectedItem.body
                          .riwayatPendidikan"
                        :key="index"
                      >
                        <td class="table-cell" style="padding-left: 20px">
                          <strong>{{ pendidikan.nama_sekolah }}</strong>
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ pendidikan.tingkat_pendidikan }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ pendidikan.tempat_sekolah }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ pendidikan.jurusan_sekolah }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ pendidikan.tahun_masuk }} -
                          {{ pendidikan.tahun_keluar }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ pendidikan.status_kelulusan }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="6" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Keluarga</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Hubungan</th>
                      <th>Nama</th>
                      <th>Jenis Kelamin</th>
                      <th>Tanggal Lahir</th>
                      <th>Pendidikan Terakhir</th>
                      <th>Perusahaan Terakhir</th>
                      <th>Jabatan Terakhir</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body &&
                        selectedItem.body.keluarga &&
                        selectedItem.body.keluarga.length > 0
                      "
                    >
                      <tr
                        v-for="(keluarga, index) in selectedItem.body.keluarga"
                        :key="index"
                      >
                        <td class="table-cell" style="padding-left: 20px">
                          <strong>{{ keluarga.hubungan_keluarga }}</strong>
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ keluarga.nama }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ keluarga.jenis_kelamin }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ keluarga.tanggal_lahir }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ keluarga.pendidikan_terakhir }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ keluarga.perusahaan_terakhir }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ keluarga.jabatan_terakhir }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ keluarga.keterangan }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="8" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Kursus & Training</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Nama Pelatihan</th>
                      <th>Penyelenggara</th>
                      <th>Lokasi</th>
                      <th>Tahun</th>
                      <th>Biaya</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body &&
                        selectedItem.body.kursusTraining &&
                        selectedItem.body.kursusTraining.length > 0
                      "
                    >
                      <tr
                        v-for="(obj, index) in selectedItem.body.kursusTraining"
                        :key="index"
                      >
                        <template v-if="obj.jenis_kursus">
                          <td class="table-cell" style="padding-left: 20px">
                            <strong>{{ obj.jenis_kursus }}</strong>
                          </td>
                          <td class="table-cell" style="padding-left: 20px">
                            {{ obj.penyelenggara }}
                          </td>
                          <td class="table-cell" style="padding-left: 20px">
                            {{ obj.lokasi }}
                          </td>
                          <td
                            class="table-cell status"
                            style="padding-left: 20px"
                          >
                            {{ obj.tahun_mulai_kursus }}
                            <!-- - -->
                            <!-- {{ obj.tahun_selesai_kursus }} -->
                          </td>
                          <td
                            class="table-cell status"
                            style="padding-left: 20px"
                          >
                            {{ obj.biaya_kursus }}
                          </td>
                        </template>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="5" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Pengetahuan Bahasa</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Jenis Bahasa</th>
                      <th>Tingkat Membaca</th>
                      <th>Tingkat Menulis</th>
                      <th>Tingkat Mendengar</th>
                      <th>Tingkat Berbicara</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body &&
                        selectedItem.body.pengetahuanBahasa &&
                        selectedItem.body.pengetahuanBahasa.length > 0
                      "
                    >
                      <tr
                        v-for="(pengetahuanBahasa, index) in selectedItem.body
                          .pengetahuanBahasa"
                        :key="index"
                      >
                        <td class="table-cell" style="padding-left: 20px">
                          <strong>{{ pengetahuanBahasa.jenis_bahasa }}</strong>
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ pengetahuanBahasa.tingkat_membaca }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ pengetahuanBahasa.tingkat_menulis }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ pengetahuanBahasa.tingkat_mendengar }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ pengetahuanBahasa.tingkat_berbicara }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="5" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Kegiatan Sosial</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Kegiatan Organisasi</th>
                      <th>Macam Kegiatan</th>
                      <th>Jabatan</th>
                      <th>Tahun Kegiatan</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body &&
                        selectedItem.body.kegiatanSosial &&
                        selectedItem.body.kegiatanSosial.length > 0
                      "
                    >
                      <tr
                        v-for="(kegiatanSosial, index) in selectedItem.body
                          .kegiatanSosial"
                        :key="index"
                      >
                        <td class="table-cell" style="padding-left: 20px">
                          <strong>{{
                            kegiatanSosial.kegiatan_organisasi
                          }}</strong>
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ kegiatanSosial.macam_kegiatan }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ kegiatanSosial.jabatan }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ kegiatanSosial.tahun_saat_berkegiatan }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ kegiatanSosial.keterangan }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="5" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle"
                  >Hobi & Kegiatan Waktu Luang</v-subheader
                >
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Frekuensi Baca</th>
                      <th>Kegiatan</th>
                      <th>Pokok Baca</th>
                      <th>Surat Kabar</th>
                      <th>Majalah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body && selectedItem.body.hobiKegiatanLuang
                      "
                    >
                      <tr>
                        <td class="table-cell" style="padding-left: 20px">
                          <strong>{{
                            selectedItem.body.hobiKegiatanLuang.frekuensiBaca
                          }}</strong>
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ selectedItem.body.hobiKegiatanLuang.kegiatan }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ selectedItem.body.hobiKegiatanLuang.pokokBaca }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ selectedItem.body.hobiKegiatanLuang.suratKabar }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ selectedItem.body.hobiKegiatanLuang.majalah }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="5" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Riwayat Pekerjaan</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Nama Perusahaan</th>
                      <th>Pekerjaan Dimulai-Selesai</th>
                      <th>Alamat Perusahaan</th>
                      <th>Telepon Perusahaan</th>
                      <th>Jabatan Awal-Akhir</th>
                      <th>Jenis Usaha</th>
                      <th>Jumlah Karyawan</th>
                      <th>Nama Atasan</th>
                      <th>Nama Direktur</th>
                      <th>Alasan Berhenti</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body &&
                        selectedItem.body.riwayatPekerjaan &&
                        selectedItem.body.riwayatPekerjaan.length > 0
                      "
                    >
                      <tr
                        v-for="(riwayatPekerjaan, index) in selectedItem.body
                          .riwayatPekerjaan"
                        :key="index"
                      >
                        <td class="table-cell" style="padding-left: 20px">
                          <strong>{{
                            riwayatPekerjaan.nama_perusahaan
                          }}</strong>
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ riwayatPekerjaan.pekerjaan_dimulai }} -
                          {{ riwayatPekerjaan.pekerjaan_selesai }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ riwayatPekerjaan.alamat_perusahaan }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ riwayatPekerjaan.telepon_perusahaan }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ riwayatPekerjaan.jabatan_awal }} -
                          {{ riwayatPekerjaan.jabatan_akhir }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ riwayatPekerjaan.jenis_usaha }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ riwayatPekerjaan.jumlah_karyawan }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ riwayatPekerjaan.nama_atasan }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ riwayatPekerjaan.nama_direktur }}
                        </td>
                        <td
                          class="table-cell status"
                          style="padding-left: 20px"
                        >
                          {{ riwayatPekerjaan.alasan_berhenti }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="10" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle"
                  >Referensi Kontak Kenalan</v-subheader
                >
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th>Hubungan</th>
                      <th>Alamat</th>
                      <th>Telepon</th>
                      <th>Pekerjaan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body &&
                        selectedItem.body.referensiKontakKenalan &&
                        selectedItem.body.referensiKontakKenalan.length > 0
                      "
                    >
                      <tr
                        v-for="(referensi, index) in selectedItem.body
                          .referensiKontakKenalan"
                        :key="index"
                      >
                        <td class="table-cell" style="padding-left: 20px">
                          <strong>{{ referensi.nama_kenalan }}</strong>
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ referensi.hubungan }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ referensi.alamat }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ referensi.telepon }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ referensi.pekerjaan }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="5" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>

                <v-divider></v-divider>
                <v-subheader class="subtitle">Pertanyaan</v-subheader>
                <v-simple-table dense class="table-bordered">
                  <thead>
                    <tr>
                      <th rowspan="2">Pertanyaan</th>
                      <th colspan="2">Jawaban</th>
                      <th>Penjelasan</th>
                    </tr>
                    <tr>
                      <th>Ya</th>
                      <th>Tidak</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="
                        selectedItem.body &&
                        selectedItem.body.pertanyaanList &&
                        selectedItem.body.pertanyaanList.length > 0
                      "
                    >
                      <tr
                        v-for="(pertanyaan, index) in selectedItem.body
                          .pertanyaanList"
                        :key="index"
                      >
                        <td class="table-cell" style="padding-left: 20px">
                          {{ pertanyaan.pertanyaan }}
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          <input
                            type="radio"
                            :checked="pertanyaan.jawaban.ya"
                            :id="'ya_' + index"
                            disabled
                          />
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          <input
                            type="radio"
                            :checked="pertanyaan.jawaban.tidak"
                            :id="'tidak_' + index"
                            disabled
                          />
                        </td>
                        <td class="table-cell" style="padding-left: 20px">
                          {{ pertanyaan.jawaban.penjelasan }}
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="4" style="text-align: center">-</td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>
                <v-divider></v-divider>
                <v-simple-table
                  dense
                  class="table-bordered"
                  style="text-align: center"
                >
                  <thead>
                    <tr>
                      <th>Gambaran Posisi Sebelumnya</th>
                      <th>Uraian Tugas & Tanggungjawab</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="vertical-align: top">
                        <img
                          :src="
                            selectedItem.body &&
                            selectedItem.body.gambaranStrukturPosisi
                              ? selectedItem.body.gambaranStrukturPosisi.path
                              : ''
                          "
                          alt="Gambaran Struktur Posisi Sebelumnya"
                          style="max-width: 400px; height: auto"
                        />
                      </td>
                      <td style="vertical-align: top">
                        <div
                          v-if="
                            selectedItem.body &&
                            selectedItem.body.uraianTugasTanggungJawab
                          "
                          style="
                            overflow-y: auto;
                            max-height: 200px;
                            word-wrap: break-word;
                            margin-top: 20px;
                          "
                        >
                          {{ selectedItem.body.uraianTugasTanggungJawab }}
                        </div>
                        <div v-else>-</div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div style="width: 100%">
                <div style="margin-bottom: 20px">
                  <v-btn
                    class="buttonText"
                    color="success"
                    dark
                    @click.stop="handleButtonClick()"
                    >{{ buttonText.accept }}</v-btn
                  >
                </div>
                <div v-if="showRejectButton">
                  <v-btn
                    class="buttonText"
                    color="error"
                    dark
                    @click.stop="handleRejectButtonClick()"
                    >{{ buttonText.reject }}</v-btn
                  >
                </div>
              </div>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- :items="formattedResult" -->

        <v-data-table
          v-model="selectedRow"
          :headers="headers"
          :items="result"
          :loading="loading"
          @click:row="rowClick"
          :options.sync="options"
          item-key="id"
          height="50vh"
          mobile-breakpoint="0"
          fixed-header
          show-select
          style="cursor: pointer; margin: 0 20px"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
          }"
        >
          <template v-slot:[`item.status`]="{ item }">
            <div :style="`color: ${statusColor(item.status)};`">
              {{ formattedStatus(item.status) }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div style="width: 210px">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <div>
              {{ item.email }}
            </div>
          </template>
          <template v-slot:[`item.dataCompleteness`]="{ item }">
            <div>{{ item.dataCompleteness }} %</div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">{{
            formattedDate(item.created_at)
          }}</template>
          <!-- <template v-slot:[`item.updatedAt`]="{ item }">{{
          formattedDate(item.updatedAt)
        }}</template> -->
          <template v-slot:[`item.view`]="{ item }">
            <v-btn
              @click.stop="openCv(item)"
              color="indigo"
              class="white--text"
              x-small
            >
              CV
            </v-btn>
          </template>
          <!-- <template v-slot:[`item.pekerjaan`]="{ item }">{{
            item.body !== null ? item.body.pekerjaan : null
          }}</template>
          <template v-slot:[`item.lahir`]="{ item }">{{
            item.body !== null ? formattedDate(item.body.tanggal_lahir) : null
          }}</template>
          <template v-slot:[`item.telepon`]="{ item }">{{
            item.body !== null ? item.body.telepon : null
          }}</template>
          <template v-slot:[`item.gender`]="{ item }">{{
            item.body !== null ? item.body.jenis_kelamin : null
          }}</template>
          <template v-slot:[`item.created_at`]="{ item }">{{
            formattedDate(item.created_at)
          }}</template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              @click.stop="showConfirmDeleteData(item)"
              color="red"
              x-small
            >
              <v-icon color="white" small>mdi-delete</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDeleteData" max-width="400px">
      <v-card>
        <v-card-title class="headline red--text">
          Konfirmasi Hapus
        </v-card-title>
        <v-card-text> Apakah Anda yakin ingin menghapus data ini? </v-card-text>
        <v-card-actions>
          <v-btn color="green" dark @click="eraseData(true, selectedItem.email)"
            >Ya</v-btn
          >

          <v-btn color="red" dark @click="eraseData(false)">Batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <!-- Add Email Form -->
      <v-dialog v-model="showAddEmailForm" max-width="400px">
        <v-card>
          <v-card-title class="headline indigo--text">
            Tambah Email
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addEmail">
              <v-text-field
                v-model="newEmail"
                label="Email"
                required
              ></v-text-field>
              <v-text-field
                v-model="newUser"
                label="User"
                required
              ></v-text-field>
              <v-btn type="submit" color="primary">Tambah</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="showAlert" :color="alertType" top>
        {{ alertMessage }}
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import 'firebase/auth'
import 'firebase/compat/firestore'
import db from '@/plugins/firebase_database.js'
import axios from 'axios'
// import moment from 'moment'
import buildType from '../../../services/buildType'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      build: process.env.VUE_APP_BUILD_TYPE,
      recruitmentApi: buildType.apiURL('recruitment'),
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      masterJobNames: [],
      selectedJobName: null,
      selectedStatus: null,
      showAddEmailForm: false,
      newEmail: '',
      newUser: '',
      showEmailDetail: false,
      emailList: [],
      showOpenJob: false,
      newJob: '',
      showAlert: false,
      alertMessage: '',
      alertType: '',
      confirmDeleteDialog: false,
      confirmDeleteData: false,
      emailToDeleteId: null,
      headers: [
        {
          text: 'Nama',
          value: 'name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Email',
          value: 'email',
          align: 'left',
          sortable: false
        },
        {
          text: 'Pendidikan',
          value: 'education',
          align: 'left',
          sortable: false
        },
        {
          text: 'Usia',
          value: 'age',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jenis Kelamin',
          value: 'gender',
          align: 'left',
          sortable: false
        },
        // {
        //   text: 'Tgl. Buat',
        //   value: 'created_at',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Dokumen',
          value: 'dataCompleteness',
          align: 'left',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lihat',
          value: 'view',
          align: 'left',
          sortable: false
        }
      ],
      loading: false,
      options: {},
      searchKeyword: '',
      filteredResult: [],
      refreshingTable: false,
      selectedItem: null,
      dialog: false,
      dropdown: {
        job: [],
        status: [
          { id: 0, name: 'Draft' },
          { id: 1, name: 'Screening' },
          { id: 2, name: 'Qualified' },
          { id: 3, name: 'Not Qualified' },
          { id: 4, name: 'Psikotes' },
          { id: 5, name: 'Interview HR' },
          { id: 6, name: 'Interview User' },
          { id: 7, name: 'Accepted' },
          { id: 8, name: 'Not Accepted' }
        ],
        action: [
          { id: 0, name: '' },
          { id: 1, name: 'Delete' }
        ]
      },
      selectedRow: [],
      paramAPI: null,
      items: [],
      result: [],
      totalData: 0,
      actionValue: null,
      detail: null
    }
  },
  computed: {
    ...mapGetters(['getUserProfile']),
    buttonText() {
      if (this.selectedItem) {
        switch (this.selectedItem.status) {
          case 1:
            return { accept: 'Kandidat belum mengisi formulir' }
          case 2:
            return { accept: 'Tandai Formulir', reject: 'Tolak Kandidat' }
          case 3:
            return { accept: 'Kualifikasikan', reject: 'Tidak terkualifikasi' }
          case 4:
            return { accept: 'Psikotest', reject: 'Tolak Kandidat' }
          case 5:
            return { accept: 'Interview HR', reject: 'Tolak Kandidat' }
          case 6:
            return { accept: 'Interview User', reject: 'Tolak kandidat' }
          case 7:
            return { accept: 'Offering', reject: 'Tolak Kandidat' }
          case 8:
            return { accept: 'Terima Kandidat', reject: 'Tolak Kandidat' }
          case 9:
            return {
              accept: 'Kandidat sudah diterima menjadi karyawan',
              reject: ''
            }
          case 10:
            return {
              accept: 'Kandidat sudah ditolak',
              reject: ''
            }
          case 11:
            return {
              accept: 'Psikotest',
              reject: 'Tolak Kandidat'
            }
          default:
            return { accept: 'Proses Kandidat', reject: '' }
        }
      }
      return { accept: '', reject: '' }
    },
    showRejectButton() {
      return (
        this.selectedItem &&
        ((this.selectedItem.status >= 3 && this.selectedItem.status <= 8) ||
          this.selectedItem.status === 11)
      )
    }
  },
  mounted() {
    this.setParamVariable()
    setTimeout(async () => {
      await this.getDataFromApi()
      this.dropdownJob()
    }, 300)
    // this.fetchData()
    // this.fetchMasterJobNames()
  },
  methods: {
    openCv(xItem) {},
    actionWatcher(val) {
      if (val == null) {
        // reset param
      } else {
        // this.openChangeStatusDialog(val)
      }
    },
    statusWatcher(v) {
      // console.log(v)
      this.getDataFromApi()
    },
    jobWatcher(v) {
      // console.log(v)
      this.getDataFromApi()
    },
    keywordWatcher(v) {
      if (v == null) {
        this.paramAPI.keyword = ''
      }
      this.getDataFromApi()
    },
    async dropdownJob() {
      const url = `${this.recruitmentApi}master/universal/job/dropdown`
      await axios
        .get(url)
        .then((res) => {
          console.log('job>>>', res)

          this.dropdown.job = res.data.data
          return null
        })
        .catch((err) => {
          console.log(err)
          return null
        })
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          setTimeout(() => {
            if (data.status_code == '00') {
              this.result = data.data
              this.totalData = data.total_record
            }
            this.loading = false
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
          return (this.result = [])
        })
    },
    async initDataTable() {
      return await new Promise((resolve, reject) => {
        let url = `${this.recruitmentApi}applicant/list?keyword=${
          this.paramAPI.keyword
        }&order_by=${this.paramAPI.orderBy}&order_type=${
          this.paramAPI.orderType
        }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
          this.paramAPI.limit
        }`
        const filter = []
        // if (this.paramAPI.is_archived != null) {
        // filter.push(`{"is_delete": ${this.paramAPI.is_archived}}`)
        // url = url + `&isArchived=${this.paramAPI.is_archived}`
        // }
        if (this.paramAPI.status != null) {
          filter.push(`{"status": ${this.paramAPI.status}}`)
        }
        // if (this.paramAPI.job_id != null) {
        //   filter.push(`{"job_id": ${this.paramAPI.job_id}}`)
        // }
        if (filter.length > 0) {
          url = url + `&filter=[${filter}]`
        }
        axios
          .get(url)
          .then((res) => {
            console.log('response>>>', res)
            // if (res.data.status_code == '00') {
            resolve(res.data)
            // }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    addEmail() {
      console.log(db)
      // Tambahkan data ke Firestore
      db.collection('Email')
        .doc(this.newEmail) // Gunakan email sebagai ID dokumen
        .set({
          email: this.newEmail,
          active: true,
          username: this.newUser
        })
        .then(() => {
          // Setelah data berhasil ditambahkan ke Firestore, kirim ke API juga
          fetch('http://localhost:3000/submitEmailAndName', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
              email: this.newEmail,
              name: this.newUser
            }).toString()
          })
            .then((response) => {
              console.log(response)
              if (response.ok) {
                this.loading = true
                this.fetchData()
                return response.json()
              }
              throw new Error('Network response was not ok.')
            })
            .then((data) => {
              console.log('Response from API:', data)
              // Reset form dan tampilkan pesan sukses
              this.newEmail = ''
              this.newUser = ''
              this.showAlert = true
              this.alertMessage = 'Email berhasil ditambahkan!'
              this.alertType = 'success'
              this.showAddEmailForm = false
            })
            .catch((error) => {
              console.error('Error sending data to API:', error)
              // Tampilkan pesan error
              this.showAlert = true
              this.alertMessage = 'Gagal menambahkan email. Silakan coba lagi.'
              this.alertType = 'error'
            })
        })
        .catch((error) => {
          console.error('Error adding email: ', error)
          // Tampilkan pesan error
          this.showAlert = true
          this.alertMessage = 'Gagal menambahkan email. Silakan coba lagi.'
          this.alertType = 'error'
        })
    },
    detailEmail() {
      this.emailList = []

      db.collection('Email')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const emailId = doc.id
            const emailData = doc.data()

            this.emailList.push({
              id: emailId,
              email: emailData.email,
              username: emailData.username,
              active: emailData.active
            })
          })

          this.showEmailDetail = true
        })
        .catch((error) => {
          console.error('Error fetching emails: ', error)
        })
    },

    addJob() {
      db.collection('OpenJob')
        .doc(this.newJob) // Gunakan nama pekerjaan sebagai ID dokumen
        .set({
          job: this.newJob,
          active: true
        })
        .then(() => {
          this.newJob = ''
          this.showAlert = true
          this.alertMessage = 'Pekerjaan berhasil ditambahkan!'
          this.alertType = 'success'
          // this.showOpenJob = false
        })
        .catch((error) => {
          console.error('Error adding job: ', error)
          this.showAlert = true
          this.alertMessage = 'Gagal menambahkan pekerjaan. Silakan coba lagi.'
          this.alertType = 'error'
        })
    },

    fetchData() {
      this.loading = true
      let url = 'http://localhost:3000/formData'

      // Siapkan parameter pencarian dan filter
      const params = {}

      if (this.searchKeyword) {
        params.keyword = encodeURIComponent(this.searchKeyword)
      }
      if (this.selectedJobName != null) {
        params.job_id = encodeURIComponent(this.selectedJobName)
      }
      console.log('this.selectedStatus', this.selectedStatus)
      if (this.selectedStatus != null) {
        params.status = encodeURIComponent(this.selectedStatus)
      }

      // Ubah objek params menjadi query string
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')

      // Gabungkan query string dengan URL
      if (queryString) {
        url += `?${queryString}`
      }
      console.log('get list>>', url)

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log('here>>>>', data)
          setTimeout(() => {
            this.items = data
            this.totalData = data.length
            this.loading = false
          }, 200)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          this.loading = false
        })
    },

    fetchMasterJobNames() {
      const url = 'http://localhost:3000/ms/job/dropdown'
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log('master job', data)
          this.dropdown.job = data
        })
        .catch((error) => {
          console.error('Error fetching master job names:', error)
        })
    },
    // filterData(selectedJobName) {
    //   const searchKeyword = this.searchKeyword
    //   this.fetchData(searchKeyword, selectedJobName)
    // },

    resetFilter() {
      this.selectedJobName = null
      this.fetchData()
    },
    closeMenu() {
      this.$refs.menu.isActive = false
    },

    search() {
      // const selectedJobName = this.selectedJobName
      this.getDataFromApi()
    },
    clearSearch() {
      this.setParamVariable()
      this.getDataFromApi()
      this.dropdownJob()
    },
    // Fungsi untuk mendapatkan pendidikan terakhir dari riwayat pendidikan
    getLatestEducation(riwayatPendidikan) {
      if (!riwayatPendidikan || riwayatPendidikan.length === 0) {
        return 'Belum ada riwayat pendidikan'
      }

      // Mengurutkan riwayat pendidikan berdasarkan tingkat pendidikan
      const sortedEducation = riwayatPendidikan.sort((a, b) => {
        const educationOrder = {
          'SD/Sederajat': 0,
          'SMP/Sederajat': 1,
          'SMA/Sederajat': 2,
          D1: 3,
          D2: 4,
          D3: 5,
          S1: 6,
          S2: 7,
          S3: 8,
          Lainnya: 9
        }
        return (
          educationOrder[a.tingkat_pendidikan] -
          educationOrder[b.tingkat_pendidikan]
        )
      })

      // Mengembalikan tingkat pendidikan terakhir
      return sortedEducation[sortedEducation.length - 1].tingkat_pendidikan
    },

    showDetail(item) {
      this.selectedItem = item
      this.dialog = true
    },
    showConfirmDeleteData(item) {
      console.log('delete clicked')
      this.selectedItem = item
      setTimeout(() => {
        this.confirmDeleteData = true
      }, 100)
    },
    async eraseData(confirm, emailId) {
      if (confirm === true) {
        // Menghapus email dari Firestore
        console.log('delete in firebase before>>>', emailId)
        await db
          .collection('Email')
          .doc(emailId)
          .delete()
          .then((res) => {
            console.log('delete in firebase after>>>', res)
            // Menghapus email dari daftar emailList secara lokal
            // this.emailList = this.emailList.filter(
            //   (email) => email.id !== emailId
            // )
            // console.log('delete in firebase after>>>', this.emailList)
            // Tampilkan pesan sukses
            this.showAlert = true
            this.alertMessage = 'Email berhasil dihapus!'
            this.alertType = 'success'
          })
          .catch((error) => {
            console.error('Error deleting email from Firestore: ', error)
            // Tampilkan pesan kesalahan
            this.showAlert = true
            this.alertMessage = 'Terjadi kesalahan saat menghapus email.'
            this.alertType = 'error'
          })
          .finally((res) => {
            console.log('delete in firebase finally>>>', res)
            this.confirmDeleteData = false
          })

        // Menghapus data dari server
        const url = `http://localhost:3000/formData/${this.selectedItem.id}`
        await fetch(url, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            console.log('Data berhasil dihapus')
            this.loading = true
            this.dialog = false
            this.fetchData()
          })
          .catch((error) => {
            console.error('There was an error deleting the data:', error)
          })
      } else {
        this.confirmDeleteData = false
      }
    },
    refreshTable() {
      // this.loading = true
      this.clearSearch()
    },
    rowClick(item) {
      this.showDetail(item)
    },
    // updatePage(page) {
    //   this.fetchData()
    //   console.log('Updated page:', page)
    // },
    // async updateItemPerPage(newPerPage) {
    //   this.options.itemsPerPage = newPerPage
    //   this.fetchData()
    //   console.log('Updated items per page:', newPerPage)
    // },

    // formatDate(dateString) {
    //   const date = new Date(dateString)

    //   const year = date.getFullYear()
    //   const month = ('0' + (date.getMonth() + 1)).slice(-2)
    //   const day = ('0' + date.getDate()).slice(-2)

    //   const formattedDate = `${day}/${month}/${year}`
    //   return formattedDate
    // },
    handleButtonClick() {
      if (this.selectedItem) {
        switch (this.selectedItem.status) {
          case 2:
            this.updateStatus(this.selectedItem.id, 3)
            break
          case 3:
            this.updateStatus(this.selectedItem.id, 4)
            break
          case 4:
            this.updateStatus(this.selectedItem.id, 6)
            break
          case 5:
            this.updateStatus(this.selectedItem.id, 6)
            break
          case 6:
            this.updateStatus(this.selectedItem.id, 7)
            break
          case 7:
            this.updateStatus(this.selectedItem.id, 8)
            break
          case 8:
            this.updateStatus(this.selectedItem.id, 9)
            break
          case 11:
            this.updateStatus(this.selectedItem.id, 5)
            break
          default:
            break
        }
      }
    },
    handleRejectButtonClick() {
      if (
        this.selectedItem &&
        this.selectedItem.status >= 4 &&
        this.selectedItem.status <= 8 &&
        this.selectedItem.status == 11
      ) {
        this.updateStatus(this.selectedItem.id, 10)
        console.log('Candidate rejected')
      } else if (this.selectedItem && this.selectedItem.status == 3) {
        this.updateStatus(this.selectedItem.id, 11)
      }
    },
    updateStatus(id, newStatus) {
      this.loading = true
      fetch(`http://localhost:3000/formData/${id}/status/${newStatus}`, {
        method: 'PUT'
      })
        .then((response) => {
          if (response.ok) {
            this.loading = false
            this.dialog = false
            this.fetchData()
            this.selectedItem.status = newStatus
            console.log('Status updated successfully')
          } else {
            throw new Error('Failed to update status')
          }
        })
        .catch((error) => {
          this.loading = false
          console.error('Error updating status:', error)
        })
    },
    statusColor(val) {
      if (val != null) {
        const color = ['red', 'green', 'green', 'black']
        return color[val]
      }
      return 'black'
    },
    formattedStatus(val) {
      if (val != null) {
        const statusText = ['Inactive', 'Active', 'Joined', 'Blacklist']
        return statusText[val]
      }
      return '-'
    },
    formattedDate(val) {
      let date = ''
      if (val !== null) {
        const a = new Date(val)
        const b = a.toLocaleDateString('id')
        date = b
      }

      return date
    },
    setParamVariable() {
      this.paramAPI = {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: null,
        orderBy: 'id',
        orderType: 'asc',
        itemsPerPage: 10,
        job_id: null,
        is_archived: 0
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    }
  }
}
</script>
<style lang="scss">
#recruit {
  position: relative;
  .recruit-list-dev {
    margin: 10px 20px;
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .recruit-toolbar-1 {
      margin-left: auto;
    }
  }
}

.dropdown-filter {
  width: 250px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.reset-filter {
  width: 50%;
  font-weight: 700;
}
.close-menu-btn {
  width: 50%;
}
.search-bar {
  border: 1px solid #3b39399f;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.search-bar input {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}

.search-bar i {
  color: #999;
  cursor: pointer;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid rgba(255, 255, 255, 0.2);
  border-top: 16px solid #2e2be2;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dialog-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.buttonText {
  width: 100%;
}
.table-bordered {
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
}
.table-cell {
  border: 1px solid #e0e0e0;
}
.subtitle {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}
.list-item-content {
  padding: 8px;
}
</style>
