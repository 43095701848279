<template>
  <v-toolbar elevation="0" class="nav-toolbar" height="60">
    <div class="d-flex align-center nav-toolbar-div">
      <v-tooltip bottom color="#e0e0e0">
        <template v-slot:activator="{ on, attrs }">
          <router-link style="color: black" to="/">
            <v-btn
              @click="home"
              v-bind="attrs"
              v-on="on"
              elevation="5"
              fab
              small
              color="white"
              ><v-img
                alt="sanqua multi international"
                class="rounded-circle"
                contain
                src="../../../public/sanquamulti.png"
                transition="scale-transition"
                style="height: 35px; width: 35px"
            /></v-btn>
          </router-link>
        </template>
        <span style="font-size: 12px; color: black">Home</span>
      </v-tooltip>

      <h5 class="esanqua-title" style="line-height: 20px; margin-top: 20px">
        <h5 style="font-size: 20px">
          <h4 class="indigo--text font-weight-bold">eSanqua</h4>
        </h5>
      </h5>
    </div>

    <v-tabs hide-slider class="nav-toolbar-tabs" align-with-title>
      <v-menu
        v-for="(item, index) in getTabs"
        :key="index"
        bottom
        offset-y
        :rounded="'0'"
        :close-on-content-click="isClose"
        v-model="item.isShown"
      >
        <template v-slot:activator="{ attr }">
          <v-tab
            v-show="!hidden(item.tab_name, getPermission.level_id, index)"
            v-bind="attr"
            @click="closeMenu(index)"
          >
            <router-link
              class="indigo--text"
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
              "
              :to="item.path"
            >
              {{ item.tab_name }}
            </router-link>
          </v-tab>
        </template>
        <v-list v-if="item.sub_tabs !== undefined">
          <v-list-item
            v-for="(tabs, i) in item.sub_tabs"
            :key="i"
            style="height: 40px; min-width: 220px"
          >
            <v-tabs hide-slider>
              <v-menu right offset-x :rounded="'0'" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-tab
                    v-bind="attrs"
                    v-on="on"
                    style="width: 100%"
                    @click="closeMenu(index)"
                  >
                    <router-link
                      class="indigo--text"
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        font-size: 12px;
                      "
                      :to="tabs.path"
                    >
                      {{ tabs.sub_title }}
                    </router-link>
                  </v-tab>
                </template>

                <v-list v-if="tabs.sub_tabs !== undefined">
                  <v-list-item
                    v-for="(subTabs, ind) in tabs.sub_tabs"
                    :key="ind"
                    style="height: 40px; min-width: 220px"
                  >
                    <v-tabs hide-slider>
                      <v-tab
                        @click="closeMenu(index)"
                        style="
                          font-size: 12px;
                          display: flex;
                          justify-content: flex-start;
                          width: 100%;
                          height: 100%;
                        "
                      >
                        <router-link
                          :to="subTabs.path"
                          style="
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            text-align: left;
                          "
                        >
                          {{ subTabs.sub_title }}
                        </router-link>
                      </v-tab>
                    </v-tabs>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tabs>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>

    <v-tabs class="nav-toolbar-tabs-mobile">
      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-tab class="indigo--text" v-bind="attrs" v-on="on"> Menu </v-tab>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in getTabs"
            :key="index"
            :hidden="hidden(item.tab_name, getPermission.level_id, index)"
          >
            <v-tabs hide-slider>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-tab @click="closeMenu(index)">
                    <router-link
                      class="indigo--text"
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        font-size: 12px;
                      "
                      :to="item.path"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.tab_name }}
                    </router-link>
                  </v-tab>
                </template>
                <v-list v-if="item.sub_tabs !== undefined">
                  <v-list-item v-for="(tabs, i) in item.sub_tabs" :key="i">
                    <v-tabs hide-slider>
                      <v-menu right offset-x :rounded="'0'" open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                          <v-tab v-bind="attrs" v-on="on">
                            <router-link
                              class="indigo--text"
                              style="
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: start;
                                align-items: center;
                                font-size: 12px;
                              "
                              :to="tabs.path"
                            >
                              {{ tabs.tab_name }}
                            </router-link>
                          </v-tab>
                        </template>
                        <v-list v-if="tabs.sub_tabs !== undefined">
                          <v-list-item
                            v-for="(subTabs, ind) in tabs.sub_tabs"
                            :key="ind"
                          >
                            <v-tabs hide-slider>
                              <v-tab
                                @click="closeMenu(index)"
                                style="
                                  font-size: 12px;
                                  display: flex;
                                  justify-content: flex-start;
                                  width: 100%;
                                  height: 100%;
                                "
                              >
                                <router-link
                                  :to="subTabs.path"
                                  style="
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    text-align: left;
                                  "
                                >
                                  {{ subTabs.sub_title }}
                                </router-link>
                              </v-tab>
                            </v-tabs>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-tabs>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tabs>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
    <v-spacer></v-spacer>
    <router-link class="indigo--text" to="/notifications">
      <v-badge
        @click.native="readNotification"
        overlap
        :content="getTotalMessage < 100 ? getTotalMessage : '+99'"
        :value="getTotalMessage"
        color="red"
        style="margin-right: 25px; cursor: pointer"
      >
        <v-btn icon small>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
    </router-link>
    <div>
      <div
        v-if="this.$route.path.includes('/e-catalogue')"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <!-- :content="
            $store.state.cart.savedData.length > 0
              ? $store.state.cart.savedData.reduce((a, b) => ({
                  qty: a.qty + b.qty
                })).qty
              : 0
          " -->
        <v-badge
          @click.native="cart"
          overlap
          :value="$store.state.cart.savedData.length !== 0"
          :content="
            $store.state.cart.savedData.length > 0
              ? $store.state.cart.savedData.length
              : 0
          "
          color="red"
          :class="
            $route.name === 'catalogueCart' ? 'notif-icon-v2' : 'notif-icon'
          "
        >
          <div style="padding: 2.5px">
            <v-menu
              open-on-hover
              transition="slide-y-transition"
              left
              offset-x
              link
              nudge-left="2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-cart</v-icon>
              </template>

              <v-list style="width: 400px">
                <div
                  v-if="$store.state.cart.savedData.length === 0"
                  style="text-align: center"
                >
                  <v-list-item>
                    <v-list-item-title style="color: #9fa8da"
                      >Tidak ada barang di keranjang</v-list-item-title
                    >
                  </v-list-item>
                </div>
                <div
                  v-else
                  style="max-height: 320px; padding: 0; background: white"
                >
                  <v-list-item
                    v-for="(item, index) in $store.state.cart.savedData"
                    :key="index"
                    link
                    @click="cart"
                    style="padding: 4px 10px; background: white"
                  >
                    <v-list-item-title
                      style="
                        color: rgba(0, 0, 0, 0.4);
                        max-height: 100px;
                        font-size: 12px;
                        color: black;
                        display: flex;
                      "
                    >
                      <div style="width: 90%; overflow: hidden">
                        <p style="margin: 0">{{ item.vendor.name }}</p>
                        <p style="margin: 0">
                          {{ item.product.name }}
                        </p>
                        <p style="margin: 0">
                          {{ '(' + item.merk + ')' }}
                        </p>
                        <p style="margin: 0">
                          {{ item.currency.symbol + '.' + item.last_price }}
                        </p>
                      </div>
                      <div
                        style="
                          width: 10%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <p style="margin: 0 5px 0 0">x</p>
                        <h5 style="margin: 0">{{ item.qty }}</h5>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </div>
        </v-badge>

        <p
          :class="$route.name === 'catalogueCart' ? 'cart-p-v2' : 'cart-p'"
          style="font-size: 8px; margin: 0"
        >
          keranjang
        </p>
      </div>
    </div>

    <v-divider vertical></v-divider>
    <v-toolbar-items class="nav-toolbar-items" @click="drawer">
      <v-tab class="nav-items">
        <v-toolbar-title style="font-size: 12px">
          {{ getUserProfile !== null ? getUserProfile.username : 'Admin' }}
          <br />
          {{
            getUserProfile !== null
              ? '(' + getUserProfile.employee.company.alias + ')'
              : '-'
          }}
        </v-toolbar-title>
      </v-tab>
      <v-tab class="nav-items-other">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-tab>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Navigation',
  data: () => ({
    env: process.env.VUE_APP_BUILD_TYPE,
    isClose: false,
    prevtabs: null,
    totalMessage: 0
  }),
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getTabs',
      'getPermission',
      'getTotalMessage'
    ])
  },
  mounted() {
    console.log()
  },
  methods: {
    readNotification() {
      // this.$store.commit('removeNotification')
      // this.$router.push('/notifications')
    },
    onHover() {
      console.log('dasd')
    },
    onEnter(index, val) {
      this.isClose = !this.isClose
      this.closeMenu(index)
    },
    onLeave(index, val) {
      this.prevtabs = index
      this.closeMenu(this.prevtabs)
    },
    closeMenu(index) {
      this.getTabs[index].isShown = !this.getTabs[index].isShown
    },
    ...mapMutations(['setTabs']),
    drawer() {
      this.$emit('setDrawer', true)
    },

    home() {
      this.setTabs([])
    },
    hidden(tab, level, index) {
      if (tab === 'Vendor List') {
        return false
      }
      // if (tab === 'FPB' && this.env !== 'development') {
      //   return true
      // }
      if (level === '1' || level === '20' || level === '32') {
        if (tab === 'Arsip' || tab === 'Pengeluaran' || tab === 'Kategori') {
          return false
        }
      } else {
        if (tab === 'Arsip' || tab === 'Pengeluaran' || tab === 'Kategori') {
          return true
        }
      }
      // ---------------new
      if (index === 1 && tab !== 'Arsip') {
        if (level === '1' || level === '17') {
          return false
        } else {
          if (tab === 'Laporan Absensi') {
            return false
          }
          if (tab === 'Aset & Kepemilikan') {
            return false
          }
          if (tab === 'Pengajuan') {
            return false
          }
          if (tab === 'Transaksi') {
            return false
          }

          if (tab === 'MASTER DATA') {
            return false
          }

          if (tab === 'OEE') {
            return false
          }

          return true
        }
      }

      if (
        tab === 'Kategori' &&
        (level === (this.env === 'development' ? '21' : '22') ||
          level === (this.env === 'development' ? '19' : '20'))
      ) {
        return true
      }

      if (
        tab === 'Pengeluaran' &&
        (level === (this.env === 'development' ? '21' : '22') ||
          level === (this.env === 'development' ? '19' : '20'))
      ) {
        return true
      }

      if (
        tab === 'Arsip' &&
        level === (this.env === 'development' ? '21' : '22')
      ) {
        return true
      }

      // HRS
      if (
        this.getUserProfile.level.find(({ id }) => id === '37') !== undefined
      ) {
        if (tab === 'Master' || tab === 'Approval' || tab === 'Karyawan') {
          return true
        }
      }

      // ecatalogue
      if (
        this.getUserProfile.level.find(({ id }) => id === '1' || id === '3') ===
        undefined
      ) {
        if (tab === 'Project') {
          return true
        }
      }
    },
    cart() {
      if (this.$route.name !== 'catalogueCart') {
        if (this.$store.state.cart.savedData.length !== 0) {
          this.$router.push('/e-catalogue/cart')
        }
      }
    },
    findDuplicate(arr) {
      const distinct = new Set(arr)

      const filtered = arr.filter((item) => {
        if (!distinct.has(item)) {
          distinct.delete(item)
        } else {
          return item
        }
      })
      return [...new Set(filtered)]
    }
  }
}
</script>
<style lang="scss">
.nav-toolbar {
  .nav-toolbar-div {
    // border: 1px solid black;
    button {
      height: 45px;
      width: 45px;
      margin-right: 10px;
    }
    h4 {
      font-size: 18px;
    }
  }
  .nav-toolbar-tabs {
    width: 80%;
    overflow: hidden;
  }
  .nav-toolbar-tabs-mobile {
    display: none;
  }
  .nav-toolbar-items {
    .nav-items-other {
      display: none;
    }
  }
}

.wishlist-icon {
  border-radius: 5px;
  padding: 2.5px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 5px rgb(44, 194, 253);
  }
  &:active {
    box-shadow: inset 0px 0px 5px rgb(44, 194, 253);
  }
}

.notif-icon {
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  &:hover {
    box-shadow: 0px 0px 5px rgb(44, 194, 253);
  }
  &:active {
    box-shadow: inset 0px 0px 5px rgb(44, 194, 253);
  }
}

.notif-icon:active + .cart-p {
  color: rgb(44, 194, 253);
}

.notif-icon-v2 {
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: inset 0px 0px 5px rgb(44, 194, 253);
}
.cart-p-v2 {
  color: rgb(44, 194, 253);
}

@media (max-width: 576px) {
  .nav-toolbar {
    .nav-toolbar-div {
      margin-right: 10px;
    }
    .nav-toolbar-tabs-mobile {
      display: flex;
    }
    .nav-toolbar-tabs {
      display: none;
    }
    .nav-toolbar-items {
      width: 100px;
      .nav-items {
        display: none;
      }
      .nav-items-other {
        display: flex;
      }
    }
  }
}
@media (max-width: 450px) {
  .nav-toolbar {
    .nav-toolbar-div {
      .esanqua-title {
        display: none;
      }
    }
  }
}
</style>
