<template>
  <v-container
    style="padding: 0; margin: auto; border-radius: 5px; background: white"
  >
    <v-form ref="entryForm" @submit.prevent="submit">
      <v-card
        flat
        style="
          border-bottom: 1px solid black;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
          "
        >
          <v-toolbar-title class="overline"
            ><p style="font-size: 20px; margin: 0; font-weight: bold">
              Formulir Pemindahan Aset
            </p>
          </v-toolbar-title>
        </div>
        <div>
          <v-btn
            type="submit"
            rounded
            elevation="0"
            color="indigo"
            class="white--text font-weight-bold ma-2"
            style="font-size: 12px"
          >
            Kirim
          </v-btn>
        </div>
      </v-card>

      <v-row style="padding: 15px; margin-top: 10px">
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                PIC
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="getUserProfile.employee.name"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Plant
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="getUserProfile.employee.company.name"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Department
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="getUserProfile.employee.department.name"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Email
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="getUserProfile.employee.email"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>

      <v-row style="padding: 15px; margin-top: 10px">
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tgl Pemindahan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-model="form.schedule_from"
                clearable
                outlined
                dense
                type="date"
                name="date"
                step="1"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="3" style="padding: 0">
          <div v-if="!form.is_sell" style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tgl. Pengembalian
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-model="form.schedule_to"
                clearable
                outlined
                dense
                type="date"
                name="date"
                step="1"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Destinasi
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-autocomplete
                v-model="form.destination"
                :items="getDropdownCompany"
                item-text="name"
                item-value="id"
                return-object
                outlined
                dense
                style="margin: 0"
              ></v-autocomplete>
            </v-col>
          </div>
        </v-col>
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-radio-group>
              <v-col
                cols="12"
                style="
                  padding: 0;
                  justify-content: flex-start;
                  align-items: center;
                  display: flex;
                "
              >
                <p
                  style="
                    margin: 0 10px 0 0;
                    font-size: 14px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Jual
                </p>
                <v-checkbox
                  @change="isSellWatcher"
                  v-model="form.is_sell"
                  :true-value="1"
                  :false-value="0"
                  style="margin: 0; height: 25px; padding: 0"
                ></v-checkbox>
              </v-col>
            </v-radio-group>
          </div>
        </v-col>

        <!-- <v-col md="2" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Total Item
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field
                v-model="form.total_item"
                type="number"
                dense
                outlined
                disabled
                style="margin: 0; "
              />
            </v-col>
          </div>
        </v-col> -->
      </v-row>

      <v-row style="padding: 15px; margin-top: 10px">
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Upload File
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-file-input
                ref="assetDocUpload"
                @change="uploadFile"
                :label="
                  form.sales_document !== ''
                    ? form.sales_document
                    : '- browse file -'
                "
                prepend-icon=""
                outlined
                dense
                :disabled="isUpload"
              ></v-file-input>
            </v-col>
          </div>
        </v-col>
        <v-col md="3" style="padding: 0 0 10px 0; margin: auto; margin-left: 0">
          <v-progress-circular
            v-if="isUpload"
            color="primary"
            indeterminate
            :width="3"
            :size="25"
          ></v-progress-circular>
          <!-- <div> -->
          <p
            v-if="uploadMessage.length > 0"
            :style="`font-size:10px; margin:auto; color:${uploadMessage[2]}; font-weight:bolder;`"
          >
            {{ uploadMessage[1] }}
            <v-icon v-if="uploadMessage[0] === 'suuccess'" color="green"
              >mdi-check</v-icon
            >
          </p>
          <!-- </div> -->
        </v-col>
      </v-row>

      <v-row style="padding: 15px">
        <v-col md="12" style="padding: 0 10px">
          <v-card
            flat
            style="
              top: 0;
              background: white;
              margin-top: 20px;
              margin-bottom: 40px;
            "
          >
            <v-btn
              elevation="2"
              small
              @click="addItem"
              rounded
              outlined
              color="indigo"
              class="indigo--text font-weight-bold ma-2"
              style="font-size: 12px; margin-bottom: 5px"
            >
              add item</v-btn
            >
            <v-data-table
              :headers="headers"
              :items="form.items"
              class="elevation-1 mx-auto"
              style="
                width: 100%;
                margin-bottom: 10px;
                border: 1px solid #e0e0e0;
              "
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.company_name`]="{ item }">
                <div style="font-size: 12px; padding: 0; display: flex">
                  {{ item.company_name }}
                  <!-- / {{ item.location_name }} -->
                </div>
              </template>
              <template v-slot:[`item.previous_location_name`]="{ item }">
                <div style="font-size: 12px; padding: 0; display: flex">
                  {{ item.current_location_name }}
                </div>
              </template>
              <template v-slot:[`item.new_lot_no`]="{ item }">
                <div style="padding: 0; display: flex; flex-direction: row">
                  <div style="height: 40px; width: 150px" v-if="form.is_sell">
                    <v-text-field v-model="item.new_lot_no" dense outlined />
                  </div>
                </div>
              </template>
              <template v-slot:[`item.action`]="{ item, index }">
                <div style="font-size: 12px; padding: 0; display: flex">
                  <v-btn
                    text
                    icon
                    color="red lighten-2"
                    @click="remove(item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row style="padding: 15px">
        <v-col md="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tujuan Pemindahan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-textarea v-model="form.description" dense auto-grow outlined />
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <add-item @getLot="initLot" />
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  components: {
    AddItem: () => import('./components/AddItem')
  },
  data() {
    return {
      upload: buildType.apiURL('upload2'),
      asset: buildType.apiURL('asset'),
      form: {
        destination: null,
        schedule_from: '',
        schedule_to: '',
        description: '',
        items: [],
        is_sell: 1,
        total_item: 0,
        sales_document: ''
      },
      headers: [
        { text: 'Nama Aset', value: 'name', align: 'left', sortable: false },
        // {
        //   text: 'Kode Aset',
        //   value: 'asset_code',
        //   align: 'left',
        //   sortable: false
        // },
        { text: 'Kode Lot', value: 'lot_no', align: 'left', sortable: false },
        {
          text: 'Kepemilikan',
          value: 'company_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lokasi saat ini',
          value: 'current_location_name',
          align: 'left',
          sortable: false
        },
        // {
        //   text: 'Stock Terakhir',
        //   value: 'current_stock',
        //   align: 'left',
        //   sortable: false
        // },
        // {
        //   text: 'Jml. Dipinjamkan',
        //   value: 'total_loanned',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Kode Baru',
          value: 'new_lot_no',
          align: 'left',
          sortable: false
        },
        {
          text: '',
          value: 'action',
          align: 'left',
          sortable: false
        }
      ],

      isUpload: false,
      uploadMessage: []
    }
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownCompany'])
  },
  created() {
    this.dropdownCompany()
  },
  methods: {
    ...mapActions(['dropdownCompany', 'dropdownAsset']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    isSellWatcher(val) {
      if (!this.form.is_sell) {
        for (let i = 0; i < this.form.items.length; i++) {
          this.form.items[i].new_lot_no = ''
        }
        this.form.sales_document = ''
        // console.log(this.$refs.assetDocUpload)
        this.$refs.assetDocUpload.reset()
      }
    },
    rowClick(pItem) {
      console.log(pItem)
    },
    initLot(pItem) {
      console.log('init lot', pItem)

      for (let i = 0; i < pItem.length; i++) {
        console.log(pItem[i])
        const a = {
          company_name: pItem[i].company_name,
          current_location_name: pItem[i].current_location_name,
          lot_id: Number(pItem[i].id),
          lot_no: pItem[i].name,
          name: pItem[i].asset_name,
          new_lot_no: ''
        }
        this.form.items.push(a)
      }
    },
    addItem() {
      setTimeout(() => {
        document.getElementById('addItemMovement').click()
      }, 500)
    },
    submit() {
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const newForm = {
        act: 'add',
        destination_company_id: this.form.destination.id,
        destination_company_name: this.form.destination.name,
        schedule_from: this.form.schedule_from,
        schedule_to: this.form.schedule_to,
        description: this.form.description,
        is_sell: this.form.is_sell,
        sales_document: this.form.sales_document,
        item: this.form.items
      }
      this.save(newForm)
    },
    save(form) {
      axios
        .post(`${this.asset}movement/save`, form)
        .then((res) => {
          if (res.data.status_code == '00') {
            this.clear()
            this.showMsgDialog('success', res.data.status_msg, false)
            this.$router.push(
              `/asset-management/detail-penerimaan/${res.data.created_id}`
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.$emit('loadPage')
        })
        .catch((err) => {
          console.error('error>>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    remove(pItem, index) {
      this.form.items.splice(index, 1)
    },
    clear() {
      this.form = {
        destination: null,
        schedule_from: '',
        schedule_to: '',
        description: '',
        items: [],
        is_sell: 1,

        total_item: 0
      }
      console.log('assetDocUpload>>>>', this.$refs.assetDocUpload)
      this.$refs.assetDocUpload.reset()
    },

    uploadFile(event) {
      if (event !== null) {
        this.isUpload = true
        const data = new FormData()
        data.append('file', event)
        axios
          .post(`${this.upload}esanqua/ams/sale_document`, data)
          .then((res) => {
            if (res.data.status === true) {
              // this.showMsgDialog('success', res.data.message, false)
              this.uploadMessage = ['success', res.data.message, 'green']
              this.form.sales_document = res.data.data.name
            } else if (res.data.status === false) {
              // this.showMsgDialog('warning', res.data.message, false)
              this.uploadMessage = ['warning', res.data.message, 'orange']
              this.$refs.assetDocUpload.value = null
            } else {
              // this.showMsgDialog('warning', res.data.message, false)
              this.uploadMessage = ['warning', res.data.message, 'orange']
              this.$refs.assetDocUpload.value = null
            }
            this.isUpload = false
            setTimeout(() => {
              this.uploadMessage = []
            }, 10000)
          })
          .catch((err) => {
            console.log(err)
            // this.showMsgDialog(
            //   'error',
            //   err
            //     ? 'Something went wrong, Please contact an admin!'
            //     : 'Something went wrong, Please contact an admin!',
            //   false
            // )
            this.uploadMessage = [
              'error',
              'Something went wrong, Please contact an admin!',
              'red'
            ]
            this.isUpload = false
            this.$refs.assetDocUpload.value = null
            setTimeout(() => {
              this.uploadMessage = []
            }, 10000)
          })
      } else {
        this.form.sales_document = ''
      }
    }
  }
}
</script>
